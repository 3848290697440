import { Box, Text } from "@chakra-ui/react";

function setFinalPriceForCartBatch(cartBatch) {
  let pixPrice = cartBatch.amount
  let creditCardPrice = cartBatch.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  const installments = 4;
  let installmentValue = (cartBatch.amount / installments).toFixed(2);
  let installmentsValue = parseFloat(installmentValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  return (
    // <Box my={2} display='flex' width='100%' justifyContent='space-between' alignItems='center'>
    //   <Text fontWeight='600' marginBottom='0px'>Total:</Text>
    //   <Box>
    //     <Box display='flex' gap='3px' justifyContent='end'>
    //       <Text color='green' fontWeight='500' fontSize='15px'>À vista:</Text>
    //       <Text color='green' fontWeight='500' fontSize='15px'>{pixPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
    //     </Box>
    //     {window.screen.width > 762 ?
    //       <Box display='flex' justifyContent='end' gap='3px'>
    //         <Text fontSize='15px' fontWeight='500'>À prazo:</Text>
    //         <Text fontSize='15px'>{creditCardPrice}</Text>
    //         <Text fontSize='15px'>(até 4 x {installmentsValue} sem juros)</Text>
    //       </Box>
    //     :
    //       <Box display='block' justifyContent='end' gap='3px'>
    //         <Box display='flex' justifyContent='end'>
    //           <Text fontSize='15px' fontWeight='500'>À prazo:</Text>
    //           <Text fontSize='15px'>{creditCardPrice}</Text>
    //         </Box>
    //         <Text fontSize='15px'>(até 4 x {installmentsValue} sem juros)</Text>
    //       </Box>
    //     }
    //   </Box>
    // </Box>
    <Box>
      <Text color='green' fontWeight='500' fontSize='15px'>{pixPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
    </Box>
  )
}

export { setFinalPriceForCartBatch };

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export function DeliveryStatusChart({ orders }) {
  const currentYear = new Date().getFullYear();

  const optionsOrders = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Status de Entrega',
      },
    },
  };

  const labels = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio',
    'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro',
    'Novembro', 'Dezembro'
  ];

  const deliveryStatuses = ['pending', 'out_for_delivery', 'delivered'];
  const colors = {
    pending: '#D9D9D9',         // Red
    out_for_delivery: '#ff7e15', // Blue
    delivered: '#49b82d',       // Green
  };

  const paidOrders = orders.filter(order => {
    const orderYear = new Date(order.updated_at).getFullYear();
    return order.paid && orderYear === currentYear;
  });


  const countsByStatusAndMonth = {};
  deliveryStatuses.forEach(status => {
    countsByStatusAndMonth[status] = labels.map(() => 0);
  });

  paidOrders.forEach(order => {
    const updatedAtDate = new Date(order.updated_at);
    const monthName = updatedAtDate.toLocaleString('pt-BR', { month: 'long' });
    const monthCapitalize = monthName.charAt(0).toUpperCase() + monthName.slice(1);
    const monthIndex = labels.findIndex(month => month === monthCapitalize);

    if (monthIndex !== -1 && deliveryStatuses.includes(order.delivery_status)) {
      countsByStatusAndMonth[order.delivery_status][monthIndex]++;
    }
  });

  const translateDeliveryStatus = (status) => {
    const languageMap = {
      pending: 'Pendente',
      out_for_delivery: 'Em Entrega',
      delivered: 'Entregue',
    };
    return languageMap[status];
  };

  const datasets = deliveryStatuses.map(status => ({
    label: `Pedidos ${translateDeliveryStatus(status)}`,
    data: countsByStatusAndMonth[status],
    borderColor: colors[status],
    backgroundColor: colors[status],
  }));

  const dataOrders = {
    labels,
    datasets,
  };

  return (
    <div
      style={{
        width: '100%',
        height: '30rem',
        borderRadius: '15px',
        padding: '20px',
        margin: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
      }}
    >
      <Bar
        options={optionsOrders}
        data={dataOrders}
        style={{ margin: 'auto', width: '100%', height: '100%' }}
      />
    </div>
  );
}
// export function DeliveryStatusChart({orders}) {


//   const optionsOrders = {
//     responsive: true,
//     maintainAspectRatio: true, // Ensures aspect ratio is maintained
//     aspectRatio: 2,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//       title: {
//         display: true,
//         text: 'Status de Entrega',
//       },
//     },
//   };

//   const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

//   const paidOrders = orders.filter(order => order.paid);
//   // const notPaidOrders = allOrders.filter(order => !order.paid);

//   const deliveryStatuses = ['pending', 'out_for_delivery', 'delivered'];
//   const countsByStatusAndMonth = {};

//   deliveryStatuses.forEach(status => {
//     countsByStatusAndMonth[status] = labels.map(month => 0);
//   });

//   paidOrders.forEach(order => {
//     const updatedAtDate = new Date(order.updated_at);
//     const monthName = updatedAtDate.toLocaleString('pt-BR', { month: 'long' });
//     const monthCapitalize = monthName.charAt(0).toUpperCase() + monthName.slice(1);
//     const monthIndex = labels.findIndex(month => month === monthCapitalize);
//     if (monthIndex !== -1 && deliveryStatuses.includes(order.delivery_status)) {
//       countsByStatusAndMonth[order.delivery_status][monthIndex]++;
//     }
//   });


//   const translateDeliveryStatus = (status) => {
//     const languageMap = {
//       "pending": "Pendente",
//       "out_for_delivery": "Em Entrega",
//       "delivered" : "Entregue",

//     };
//     return languageMap[status]
//   }

//   const datasets = deliveryStatuses.map((status, index) => ({
//     label: `Pedidos ${translateDeliveryStatus(status)}`,
//     data: countsByStatusAndMonth[status],
//     borderColor: `rgb(${index * 80}, 100, 100)`,
//     backgroundColor: `rgb(${index * 80}, 100, 100)`,
//   }));

//   const dataOrders = {
//     labels,
//     datasets,
//   };


//   return (<>
//     <div
//       style={{
//         width: '100%',
//         height: '30rem',
//         borderRadius: '15px',
//         padding: '20px',
//         margin: 'auto',
//         boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
//       }}
//     >
//       <Bar options={optionsOrders} data={dataOrders} style={{ margin: 'auto', width: "100%", height: "100%" }}/>
//     </div>
//   </>)
// }

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function PaymentMethodChart({payments}) {
  const options = {
    responsive: true,
    maintainAspectRatio: true, // Ensures aspect ratio is maintained
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Métodos de pagamento',
      },
    },
  };

  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const currentYear = new Date().getFullYear();

  const pixPayments = payments.filter(payment =>
    payment.status === 'paid' &&
    payment.payment_method === 'pix' &&
    new Date(payment.created_at).getFullYear() === currentYear
  );

  const creditCardPayments = payments.filter(payment =>
    payment.status === 'paid' &&
    payment.payment_method === 'credit_card' &&
    new Date(payment.created_at).getFullYear() === currentYear
  );

  function convertMonthToLabelFormat(date) {
    const monthNames = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio',
      'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro',
      'Novembro', 'Dezembro'
    ];
    const updatedAtDate = new Date(date);
    const monthIndex = updatedAtDate.getMonth();
    return monthNames[monthIndex];
  }

  const getMonthlySum = (month) => {
    return (month.reduce((sum, order) => sum + order.price_in_cents, 0) / 100);
  };

  const dataset1Data = labels.map(labelMonth => {
    const monthlyOrders = pixPayments.filter(order => convertMonthToLabelFormat(order.created_at) === labelMonth);
    return getMonthlySum(monthlyOrders);
  });

  const dataset2Data = labels.map(labelMonth => {
    const monthlyOrders = creditCardPayments.filter(order => convertMonthToLabelFormat(order.created_at) === labelMonth);
    return getMonthlySum(monthlyOrders);
  });


  const data = {
    labels,
    datasets: [
      {
        label: 'Pix',
        data: dataset1Data,
        borderColor: 'rgb(60,255,150)',
        backgroundColor: 'rgba(60,255,150, 0.5)',
      },
      {
        label: 'Cartão',
        data: dataset2Data,
        borderColor: 'rgb(100, 149, 237)',
        backgroundColor: 'rgba(100, 149, 237, 0.5)',
      },
    ],
  };

  return (<>
    <div
      style={{
        width: '100%',
        height: '30rem',
        borderRadius: '15px',
        padding: '20px',
        margin: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
      }}
    >
      <Line options={options} data={data} style={{ margin: 'auto', width: "100%", height: "100%"}}/>
    </div>
  </>)
}

import {
  Box,
  Button,
  Input,
  SimpleGrid,
  Text,
  Progress,
  Stat,
  StatHelpText,
  StatArrow
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { ProductPageCard } from "../../components/ProductPageCard";
import { api } from "../../services/api";
import { theme } from "../../styles/theme";
import { BsDash, BsPlus } from 'react-icons/bs'
import Navbar from "../../components/Navbar";
import { CartContext } from "../../Context/CartContext";
import { AddToCartPopUp } from "../../components/AddToCartPopUp";
import { calculateDeliveryPeriod } from "../../services/deliveryPeriodCalculation";
export function Product() {
  const [productId, setProductId] = useState("")
  const { product_type, product_name } = useParams();
  const [product, setProduct] = useState({})
  const [productType, setProductType] = useState({})
  const [batch, setBatch] = useState({})
  const [prices, setPrices] = useState([])
  const [quantity, setQuantity] = useState(1)
  const [amountAlreadyPurchased, setAmountAlreadyPurchased] = useState(null)
  const [productPhotoUrl, setProductPhotoUrl] = useState("")
  const [productFlyerUrl, setProductFlyerUrl] = useState("")
  const { showCartPopUp, setShowCartPopUp, handleSetSelectedProduct } = useContext(CartContext)
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('trackCustom', 'Product');
      }
    }
  }, []);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      if (remainingTime) {
        setTimeRemaining(remainingTime);
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  function calculateTimeRemaining() {
    const now = new Date();
    const expirationTime = new Date(batch.expiration_date);
    const timeDifference = expirationTime - now;
    if (timeDifference <= 0) {
      return null;
    }
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  }

  function handleIncrement(e) {
    e.preventDefault()
    setQuantity(quantity + 1)
  }

  function handleDecrement(e) {
    e.preventDefault()
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  function finalPrice() {
    if (!product || prices?.length === 0) {
      return 0;
    }
    const sortedPrices = prices?.sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    const calculatedPrice = selectedPrice.price_per_unity * quantity * product?.quantity_per_box;
    return calculatedPrice.toFixed(2);
  }

  function unityPrice() {
    if (!product || !prices || prices.length === 0) {
      return 0;
    }

    const sortedPrices = prices.sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];

    for (let i = 0; i < sortedPrices.length; i++) {
      if (quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }

    return selectedPrice.price_per_unity;
  }

  function totalDiscount() {
    if (!product || prices.length === 0) {
      return 0;
    }
    let selectedPrice;
    for (let i = 0; i < prices.length; i++) {
      if (quantity <= prices[i].unity) {
        selectedPrice = prices[i];
        break;
      }
    }
    if (!selectedPrice) {
      selectedPrice = prices[prices.length - 1];
    }
    const calculatedPrice = selectedPrice.price_per_unity * quantity * product?.quantity_per_box;
    const originalPrice = product?.market_price * quantity * product?.quantity_per_box;
    return (originalPrice - calculatedPrice).toFixed(2);
  }

  useEffect(() => {
    async function fetchProductId() {
      const productId = await api.get(`/api/v1/products/get_product_id?product_name=${product_name}`);
      setProductId(productId.data.product_id);
      const response = await api.get(`/api/v1/products/${productId.data.product_id}`)
      setProduct(response.data.product)
      setBatch(response.data.batch)
      setPrices(response.data.prices)
      setAmountAlreadyPurchased(response.data.amount_already_purchased)
      setProductPhotoUrl(response.data.product_photo_url)
      setProductFlyerUrl(response.data.product_flyer_url)
      setProductType(response.data.product_type)
      console.log(response.data)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setShowCartPopUp(false);
    fetchProductId();
  }, [product_type, product_name]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('pt-BR', options);
  };

  const completionPercentage = ((quantity + amountAlreadyPurchased) * 100) / batch?.stock;
  let colorScheme;

  if (completionPercentage < 25) {
    colorScheme = 'red';
  } else if (completionPercentage < 75) {
    colorScheme = 'yellow';
  } else {
    colorScheme = 'green';
  }

  const now = new Date();
  const dayOfWeek = now.getDay();
  const currentHour = now.getHours();
  const tomorrow = new Date(now);
  tomorrow.setDate(now.getDate() + 1);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return (
    <>
      <Navbar/>
      <Box
        padding={{base: '5% 0%', md: '0% 0% 2% 0%'}}
        marginTop={{ base: "10rem", md: "6rem" }}
      >
        <Box
          padding={{base: '5% 2%', md: '1% 7%'}}
          background="transparent"
        >
          <Text textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}>{product?.name} - {product?.brand}</Text>
          <Text textAlign='start' fontWeight='500' color={theme.colors.secondaryGreen} fontSize={{base: '15px', md: '20px'}}>
            {calculateDeliveryPeriod(batch.id, product, "delivery")}
          </Text>
          <SimpleGrid columns={{base: 1, md: 3}} spacing={0} gap={5} margin='1% auto' alignItems="flex-start">
            <Box
              width={{base: '100%', md: '100%'}}
              order={{base: 1, md: 1}}
            >
              <ProductPageCard
                key={productId}
                id={productId}
                name={product?.name}
                prices={prices}
                brand={product?.brand}
                description={product?.description}
                quantityPerBox={product?.quantity_per_box}
                size={product?.size}
                marketPrice={product?.market_price}
                productType={productType}
                batch={batch}
                productPhotoUrl={productPhotoUrl}
                productFlyerUrl={productFlyerUrl}
                suggestedPriceForSale={product?.suggested_price_for_sale}
              />
            </Box>
            <Box
              // background={theme.colors.white500}
              borderRadius="15px"
              width={{base: '100%', md: '100%'}}
              margin={"0 auto"}
              boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
              order={{base: 2, md: 2}}
              // height='100%'
              paddingBottom='20px'
            >
              <Box
                background='transparent'
                borderRadius="15px 15px 0px 0px"
                borderBottom='1px solid #ff7e15'
              >
                <Text
                  fontSize={"30px"}
                  color={theme.colors.logoOrange}
                  fontWeight="bold"
                  padding="10px 30px"
                >
                  Preços:
                </Text>
              </Box>
              <Box>
                <Text padding="0px 30px" textAlign='start' fontWeight='bold' marginTop='5px'>Unidades por {batch?.unity_of_measurement}: {product?.quantity_per_box}</Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  padding="15px 0 0 0"
                >
                  {prices?.map((price, index) => {
                    return (
                      <Box key={index} border="1px solid #ef9207" borderRadius="10px" padding="2%" width="90%" marginBottom="10px" >
                        <Box display="flex" justifyContent="space-between">
                          {index === prices.length - 1 ? (
                            <Text
                              fontSize={{base: '16px', md: '16px'}}
                              fontWeight="bold"
                              key={index}
                            >
                              {price.unity} {batch?.unity_of_measurement}{price.unity > 1 ? <span>s</span> : <span></span>} ou mais: {(price.price_per_unity / 1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}/Unidade
                            </Text>

                          ) : (
                            <Text
                              fontSize={{base: '16px', md: '16px'}}
                              fontWeight="bold"
                              key={index}
                            >
                              {price.unity} {batch?.unity_of_measurement}{price.unity > 1 ? <span>s</span> : <span></span>}: {(price.price_per_unity / 1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}/Unidade
                            </Text>
                          )}
                          {index === 0 ? (
                            <Box padding='5px' background="transparent" display="flex" justifyContent="start" alignItems="start">
                              <Stat marginBottom='0px'>
                                <StatHelpText  marginBottom='0px' display="flex" alignItems='center' fontSize='16px' fontWeight='bold' color='green'>
                                  <StatArrow color="green" type='decrease' />
                                  <span>{Math.round((100 - ((price.price_per_unity * 100) / product?.market_price)))}%</span>
                                </StatHelpText>
                              </Stat>
                            </Box>

                          ) : (
                            <Box padding='5px' background="transparent" display="flex" justifyContent="center" alignItems="center" borderRadius="10px">
                              <Stat marginBottom='0px'>
                                <StatHelpText  marginBottom='0px' display="flex" alignItems='center' fontSize='16px' fontWeight='bold' color='green'>
                                  <StatArrow color="green" type='decrease' />
                                  <span>{Math.round((100 - ((price.price_per_unity * 100) / product?.market_price)))}%</span>
                                </StatHelpText>
                              </Stat>
                            </Box>
                          )}
                        </Box>
                        {index === 0 ? (
                          <Box>
                            <Box display="flex">
                              <Text fontSize={{base: '14px', md: '15px'}} marginBottom="0" fontWeight="bold" color="green">
                                Economize: {((product?.market_price) - (price.price_per_unity)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} na unidade
                              </Text>
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box display="flex">
                              <Text fontSize={{base: '14px', md: '15px'}} marginBottom="0" fontWeight="bold" color="green">
                                Economize:{((product?.market_price) - (price.price_per_unity)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} na unidade
                              </Text>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Box>
            <Box order={{base: 0, md: 2}} height="">
              <Box
                // background={theme.colors.white500}
                borderRadius="15px"
                width={{base: '100%', md: '100%'}}
                margin={{base: "0px auto", md: "0px 0px 20px 0px"}}
                height={{base: 'auto', md: 'auto'}}
                boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
                padding='10px'
              >
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  gap='5px'
                >
                  <Text
                    fontSize="26px"
                    fontWeight="bold"
                    color={theme.colors.secondaryGreen}
                  >
                    R${finalPrice()} à vista
                  </Text>
                </Box>
                <Text
                  fontSize="15px"
                >
                  até 4 x R${((finalPrice() * 1.03) / 4 ).toFixed(2)} sem juros
                </Text>
                <Text
                  fontSize="15px"
                >
                  Total a prazo R${(finalPrice() * 1.03).toFixed(2)}
                </Text>
                <Text
                  fontSize="15px"
                  fontWeight="500"
                  color={theme.colors.secondaryGreen}
                >
                  unidade: R${unityPrice().toFixed(2)}
                </Text>
                <Stat marginBottom='0px'>
                  <StatHelpText  marginBottom='0px' display="flex" alignItems='center' fontSize="18px" color='green' fontWeight='bold'>
                    <StatArrow color="green" type='decrease' />
                      R${totalDiscount()} desconto na <Text marginLeft="5px" color={theme.colors.primary} fontWeight='bold'>BOO</Text> <Text color={theme.colors.primaryGreen} fontWeight='bold'>ZEN</Text>
                  </StatHelpText>
                </Stat>
                <Box
                  display="block"
                  justifyContent="center"
                  alignItems="center"
                >
                  {batch.credits_distributed_at !== null ? (
                    <Text
                      textAlign="start"
                      marginBottom="5px"
                    >
                      Créditos distribuídos em {formatDate(batch.credits_distributed_at)}
                    </Text>
                  ) : (<>
                    <Text
                      textAlign="start"
                      marginBottom="5px"
                    >
                      Chance de ganhar + 1% de desconto!
                    </Text>
                    <Progress
                      margin="auto"
                      borderRadius="3px"
                      colorScheme={colorScheme}
                      width="100%"
                      size="sm"
                      value={completionPercentage}
                    />
                  </>)}
                </Box>
                <Box marginTop='30px' display={{base: 'flex', md: 'flex'}} flexDirection={{base: 'column', md: 'row'}} alignItems="center" justifyContent="space-between">
                  <Text
                    margin="10px 0"
                    fontSize={{base: '14px',md: "18px"}}
                    fontWeight="bold"
                    textAlign="start"
                  >
                    Quantidade:
                  </Text>
                  <Box
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <BsDash
                      size={32}
                      weight="fill"
                      cursor="pointer"
                      onClick={(e) => handleDecrement(e)}
                    />
                    <Input
                      margin="0 20px"
                      type="number"
                      value={quantity}
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                      width="100px"
                      background={theme.colors.white}
                      textAlign="center"
                    />
                    <BsPlus
                      id={"button-plus"}
                      size={32}
                      weight="fill"
                      cursor="pointer"
                      onClick={(e) => handleIncrement(e)}
                    />
                  </Box>
                </Box>
                <Text textAlign={{base: 'center', md: 'start'}}>{quantity} {batch?.unity_of_measurement}{quantity > 1 ? <span>s</span> : <span></span>} / {quantity * product?.quantity_per_box} {product?.size ? <span>{product?.size}</span> : <span></span>}</Text>
                {product.suggested_price_for_sale && (
                  <Box display="flex" gap="5px">
                    <Text textAlign={{base: 'center', md: 'start'}} fontWeight="500">Sugestão de preço de revenda: </Text>
                    <Text textAlign={{base: 'center', md: 'start'}} fontWeight="500">R${product?.suggested_price_for_sale.toFixed(2)}</Text>
                  </Box>
                )}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    width="100%"
                    margin=" 30px 0 15px 0px"
                    bg={theme.colors.secondaryGreen}
                  color="white"
                  borderRadius="20px"
                  border={`1px solid ${theme.colors.secondaryGreen}`}
                    _hover={{
                      background: "transparent",
                      color: `${theme.colors.secondaryGreen}`,
                      borderCorlor: `${theme.colors.secondaryGreen}`
                    }}
                    onClick={(e) => handleSetSelectedProduct(e, product, batch, finalPrice(), quantity)}
                  >
                    ADICIONAR
                  </Button>
                </Box>
              </Box>
              {product.customer_review && !product.customer_review === null && !isMobile && (
                <Box textAlign='center' marginTop={{ base: "20px", md: "0px" }}>
                  <div dangerouslySetInnerHTML={{ __html: product.customer_review }} />
                </Box>
              )}
            </Box>
          </SimpleGrid>
          {showCartPopUp && <AddToCartPopUp />}
          {product.customer_review && !product.customer_review === null  && isMobile  && (
            <Box textAlign='center' marginTop={{base: "20px", md: "0px"}}>
              <div dangerouslySetInnerHTML={{ __html: product?.customer_review }} />
            </Box>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  )
}

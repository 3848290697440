import {
  Button,
  Text,
  Grid,
  GridItem
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { api } from "../../services/api";
import { CityContext } from '../../Context/CityContext';
import { theme } from "../../styles/theme";
import { useLocation } from 'react-router-dom';

export function KitFilters(props) {
  const location = useLocation();
  const [wineTypes, setWineTypes] = useState([]);
  const { cityParam } = useContext(CityContext);

  const kitTypeOptions = ["Kit Empresa", "Kit Verão"]
  async function handleGetProductTypes(e, productType) {
    e.preventDefault();
    const updatedWineTypes = wineTypes.includes(productType)
      ? wineTypes.filter((type) => type !== productType)
      : [...wineTypes, productType];

    setWineTypes(updatedWineTypes);
    filterProducts(updatedWineTypes);
  }

  async function filterProducts(types) {
    let url = `/api/v1/products?city_id=${cityParam}`;

    if (types.length > 0) {
      url = `${url}&kit_types=${types.join(',')}`;
    }
    if (types.length === 0 ) {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      url = `${url}&filter=${filter}`;
    }
    const response = await api.get(url);
    props.setProducts(response.data.products);
  }

  return (
    <>
    <Text fontWeight="bold" color={theme.colors.primaryGreen} mb="20px">Tipos:</Text>
    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
      gap={4}
      margin='auto'
    >
      {kitTypeOptions.map((wineType, index) => {
        return(
          <GridItem key={index}>
            <Button
              width={"100%"}
              onClick={(e) => handleGetProductTypes(e, wineType)}
              border={`1px solid ${theme.colors.secondaryGreen}`}
              color={theme.colors.secondaryGreen}
              borderRadius="20px"
              _hover={{
                transform: "scale(1.02)",
                color: "white",
                bg: `${theme.colors.secondaryGreen}`,
              }}
              style={{
                backgroundColor: wineTypes.includes(wineType) ? `${theme.colors.secondaryGreen}` : "transparent",
                color: wineTypes.includes(wineType) ? "white" : `${theme.colors.secondaryGreen}`,
              }}
            >
              {wineType}
            </Button>
          </GridItem>
        )
      })}
    </Grid>
    </>
  );
}

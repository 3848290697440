import {
  Box,
  Input,
  Text,
  Image,
  Button,
  Checkbox,
  Spinner,
  Divider,
  Stack,
  RadioGroup,
  Radio,
  Progress
} from "@chakra-ui/react";
import { useEffect, useState, useRef, useContext } from "react";
import { Footer } from "../../components/Footer";
import { api } from "../../services/api";
import { setFinalPriceForCartBatch } from "../../services/setFinalPriceForCartBatch";
import { setFinalPriceForOrder } from "../../services/setFinalPriceForOrder";
import { calculateDeliveryPeriod } from "../../services/deliveryPeriodCalculation";
import { theme } from "../../styles/theme";
import { useAuth } from "../../Hooks/useAuth";
import { BsDash, BsPlus } from 'react-icons/bs';
import { debounce } from 'lodash'
import Navbar from "../../components/Navbar";
import { FaRegTimesCircle, FaThumbsUp, FaThumbsDown, FaRedoAlt  } from 'react-icons/fa';
import swal  from 'sweetalert';
import { finishCustomerOrder } from "../../services/finishCustomerOrder";
import { InputMask } from '../../components/InputMask'
import { CartContext } from "../../Context/CartContext";


export function Cart() {
  const [cartBatches, setCartBatches] = useState([]);
  const { user, signInUser, getUserInfo, signUpUser } = useAuth();
  const { errors } = useAuth();
  const [batches, setBatches] = useState([]);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [userCredits, setUserCredits] = useState([]);
  const [amountAlreadyPurchased, setAmountAlreadyPurchased] = useState([]);
  const [useCredit, setUseCredit] = useState(false);
  const [CEP, setCEP] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [deliveryAddressNumber, setDeliveryAddressNumber] = useState("");
  const [deliveryAddressComplement, setDeliveryAddressComplement] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("delivery");
  const [collectionAddress, setCollectionAddress] = useState({});
  const [collectionAddressCity, setCollectionAddressCity] = useState({});
  const [collectionAddressState, setCollectionAddressState] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("not_selected");
  const [paymentObservations, setPaymentObservations] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [validCoupon, setValidCoupon] = useState(undefined);
  const [statusCoupon, setStatusCoupon] = useState("Não aplicado");
  const [couponKind, setCouponKind] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [useAddressFromUser, setUseAddressFromUser] = useState(false);
  const [guestCartBatches, setGuestCartBatches] = useState([]);
  const [authType, setAuthType] = useState('signUp');
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userDocument, setUserDocument] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const [deliveryStep, setDeliveryStep] = useState(true);
  const [paymentStep, setPaymentStep] = useState(false);
  const [userUpdateErrors, setUserUpdateErrors] = useState({});
  const [userAddress, setUserAddress] = useState({
    city: "",
    complement: "",
    neighborhood: "",
    number: "",
    postal_code: "",
    state: "",
    street: ""
  });
  const { decrementCartCounter } = useContext(CartContext);
  const allowedCities = [
    'Belo Horizonte',
    'Nova Lima',
    'Contagem',
    'Betim',
    'Lagoa Santa'
  ];
  const isDeliveryStepCompleted =
  !(
    (!!deliveryAddress &&
      !!deliveryAddress.logradouro &&
      !!deliveryAddressNumber) ||
    deliveryMethod === "collect"
  ) ||
  (!user &&
    authType === "signUp" &&
    (userEmail === "" ||
     userName === "" ||
     userDocument === "" ||
     userPhoneNumber === ""));


  async function fetchCartBatches() {
    setLoadingPage(true);
    if (user) {
      try {
        const response = await api.get(`/api/v1/cart_batches?user_id=${user.id}`);

        // const { cart_batches, batches } = response.data;

        // if (cart_batches.length !== batches.length) {
        //   console.warn("Some cart batches are invalid and have been removed.");
        // }
        setCartBatches(response.data.cart_batches)
        setBatches(response.data.batches)
        setProducts(response.data.products)
        setPrices(response.data.prices)
        setPhotos(response.data.photos)
        setAmountAlreadyPurchased(response.data.amount_already_purchased)
        setUserCredits(response.data.user_credits)
        setCollectionAddress(response.data.collection_address)
        setCollectionAddressCity(response.data.collection_address_city)
        setCollectionAddressState(response.data.collection_address_state)
        setUserAddress(response.data.user_address)
      } catch (error) {
        console.error("Error fetching user cart:", error);
      }
    } else {
      // const cartData = localStorage.getItem('guestCart');
      // const guestCart = cartData ? JSON.parse(cartData) : [];
      // const guestBatches = guestCart.map(item => ({
      //   product_id: item.product_id,
      //   batch_id: item.batch_id,
      //   quantity: item.quantity,
      //   amount: parseFloat(item.amount),
      // }));

      // setCartBatches(guestBatches);

      // // const batchIds = guestBatches.map(item => item.batch_id);
      // const batchIds = guestBatches
      //   .map(item => item.batch_id)
      //   .filter(batchId => batchId && batchId.toString().trim() !== "");

      // if (batchIds.length > 0) {
      //   try {
      //     const response = await api.get(`/api/v1/cart_batches/get_guest_cart?batch_ids=${batchIds}`);
      //     setBatches(response.data.batches);
      //     setProducts(response.data.products);
      //     setPrices(response.data.prices);
      //     setPhotos(response.data.photos);
      //     setAmountAlreadyPurchased(response.data.amount_already_purchased);
      //     setUserCredits([]);
      //     setCollectionAddress(response.data.collection_address);
      //     setCollectionAddressCity(response.data.collection_address_city);
      //     setCollectionAddressState(response.data.collection_address_state);


      //   } catch (error) {
      //     console.error("Error fetching batch/product details:", error);
      //   }
      // }
      const cartData = localStorage.getItem("guestCart");
      const guestCart = cartData ? JSON.parse(cartData) : [];
      const guestBatches = guestCart.map((item) => ({
        product_id: item.product_id,
        batch_id: item.batch_id,
        quantity: item.quantity,
        amount: parseFloat(item.amount),
      }));

      setCartBatches(guestBatches);

      const batchIds = guestBatches
        .map((item) => item.batch_id)
        .filter((batchId) => batchId && batchId.toString().trim() !== "");

      if (batchIds.length > 0) {
        try {
          const response = await api.get(
            `/api/v1/cart_batches/get_guest_cart?batch_ids=${batchIds}`
          );

          // Check for missing batches
          const { missing_batch_ids, batches, products, prices, photos } =
            response.data;

          if (missing_batch_ids && missing_batch_ids.length > 0) {
            // Filter out missing batches from the local guestCart
            const updatedGuestCart = guestCart.filter(
              (item) => !missing_batch_ids.includes(item.batch_id.toString())
            );
            alert("Some items in your cart are no longer available and have been removed.");
            localStorage.setItem("guestCart", JSON.stringify(updatedGuestCart));
            setCartBatches(updatedGuestCart);
          }

          setBatches(batches);
          setProducts(products);
          setPrices(prices);
          setPhotos(photos);
          setAmountAlreadyPurchased(response.data.amount_already_purchased);
          setUserCredits([]);
        } catch (error) {
          console.error("Error fetching batch/product details:", error);
        }
      }
    }
    setLoadingPage(false);
  }

  useEffect(() => {
    fetchCartBatches();
  }, [user])

  function handleUseCreditChange() {
    setUseCredit(!useCredit);
  }

  const delayedQuery = useRef(
    debounce(async cartBatch => {
      const dataObject = new FormData();
      dataObject.append("cart_batch[amount]", cartBatch.amount);
      dataObject.append("cart_batch[quantity]", cartBatch.quantity);
      const response = await api.put(`/api/v1/cart_batches/${cartBatch.id}`, dataObject);
    }, 1000)
  ).current;

  function handleIncrement(cartBatch) {
    let updatedCartBatch;
    setAmountAlreadyPurchased(amountAlreadyPurchased + 1)
    const currentCartBatches = [...cartBatches]
    updatedCartBatch = currentCartBatches.find(e => e.batch_id === cartBatch.batch_id)
    ++updatedCartBatch.quantity
    const productId = batches.find(element => element.id === updatedCartBatch.batch_id).product_id
    const quantityPerBox = products.find(e => e.id === productId).quantity_per_box
    const sortedPrices = prices.filter(e => e.batch_id === updatedCartBatch.batch_id).sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (updatedCartBatch.quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    updatedCartBatch.amount = quantityPerBox * updatedCartBatch.quantity * selectedPrice.price_per_unity
    setCartBatches(currentCartBatches);
    if (user) {
      delayedQuery(cartBatch)
    } else {
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      const updatedGuestCart = guestCart.map(item =>
        item.batch_id === cartBatch.batch_id
          ? {
              ...item,
              quantity: updatedCartBatch.quantity,
              amount: updatedCartBatch.amount
          }
          : item
      );
      localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
    }
  }

  const paymentStepDisplay = (e) => {
    e.preventDefault()
    setDeliveryStep(false)
    setPaymentStep(true)
  }

  const deliveryStepDisplay = (e) => {
    e.preventDefault()
    setDeliveryStep(true)
    setPaymentStep(false)
  }


  function handleDecrement(cartBatch) {
    if (cartBatch.quantity <= 1 ) {
      document.getElementById("button-decrement").disabled = true
      return
    }
    let updatedCartBatch;
    setAmountAlreadyPurchased(amountAlreadyPurchased - 1)
    const currentCartBatches = [...cartBatches]
    updatedCartBatch = currentCartBatches.find(e => e.batch_id === cartBatch.batch_id)
    --updatedCartBatch.quantity
    const productId = batches.find(element => element.id === updatedCartBatch.batch_id).product_id
    const quantityPerBox = products.find(e => e.id === productId).quantity_per_box
    const sortedPrices = prices.filter(e => e.batch_id === updatedCartBatch.batch_id).sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (updatedCartBatch.quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    updatedCartBatch.amount = quantityPerBox * updatedCartBatch.quantity * selectedPrice.price_per_unity
    setCartBatches(currentCartBatches)
    if (user) {
      delayedQuery(cartBatch)
    } else {
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      const updatedGuestCart = guestCart.map(item =>
        item.batch_id === cartBatch.batch_id
          ? {
              ...item,
              quantity: updatedCartBatch.quantity,
              amount: updatedCartBatch.amount
          }
          : item
      );
      localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
    }
  }

  useEffect(() => {
    if (authType === 'signIn') {
      window.location.href = "/sign_in";
    }
  }, [authType]);

  const updateUser = async (userName, userDocument, userPhoneNumber) => {
    let cleanedDocumentNumber =  userDocument.replace(/[.-\s]/g, '');
    let cleanedPhoneNumber =  userPhoneNumber.replace(/\D/g, '');
    const formData = new FormData();
    if (userName !== "") {
      formData.append("user[full_name]", userName);
    }
    if (userDocument !== "") {
      formData.append("user[document_number]", cleanedDocumentNumber);
    }
    if (userPhoneNumber !== "") {
      formData.append("user[phone_number]", cleanedPhoneNumber);
    }

    const response = await api.put(`/api/v1/users/${user.id}`, formData, {
      headers: {
        'Content-Type': "multipart/form-data",
      },
    });
    if (response.data.message === "Usuário atualizado com sucesso") {
      swal({
        title: "Ohhh Yeahhh",
        text: "Dados atualizados com sucesso!",
        icon: "success",
      });
    } else {
      swal({
        title: "Ohhh Noo",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
  };


  function generateRandomUserPassword(length = 12) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  }

  const handleFinishOrder = async (e) => {
    e.preventDefault();

    if (user && (user.full_name === "" || user.document_number === "" || user.phone_number === "")) {
      try {
        await updateUser(userName, userDocument, userPhoneNumber);
      } catch (error) {
        if (error.response && error.response.data.errors) {
          setUserUpdateErrors(error.response.data.errors);
        } else {
          swal({
            title: "Erro",
            text: "Ocorreu um erro ao atualizar dados do usuário. Tente novamente.",
            icon: "error",
          });
        }
        setDeliveryStep(true);
        setPaymentStep(false);
        return
      }
    }

    if (deliveryMethod === "") {
      swal({
        title: "Quase lá!",
        text: "Escolha um método de recebimento para continuar.",
        icon: "info",
      });
      return;
    }

    if (paymentMethod === "not_selected") {
      swal({
        title: "Quase lá!",
        text: "Escolha um método de pagamento para continuar.",
        icon: "info",
      });
      return;
    }

    if (deliveryMethod === "delivery" && (deliveryAddress.logradouro === "" || deliveryAddressNumber === "" || deliveryAddress.bairro === "" || deliveryAddress.localidade === "" || deliveryAddress.uf === "" || deliveryAddress.cep === "")) {
      swal({
        title: "Quase lá!",
        text: "Preencha o endereço de entrega completo antes de continuar.",
        icon: "info",
      });
      return;
    }

    if (!user && authType === 'signUp') {
      if (userEmail === "" || userName === "" || userDocument === "" || userPhoneNumber === "") {
        swal({
          title: "Quase lá!",
          text: "Preencha todos os campos obrigatórios antes de continuar.",
          icon: "info",
        });
        return;
      }

      try {
        const randomPassword = generateRandomUserPassword();
        await signUpUser(userEmail, randomPassword, userName, userDocument, userPhoneNumber);
      } catch (error) {
        swal({
          title: "Erro",
          text: "Ocorreu um erro ao atualizar dados do usuário. Tente novamente.",
          icon: "error",
        });

        return
      }
    }

    setIsOrderProcessing(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setDeliveryStep(true);
      setPaymentStep(false);
    }
  }, [errors]);


  useEffect(() => {
    const processOrder = async () => {
      if (user && isOrderProcessing && !isProcessing) {
        setIsProcessing(true);
        try {
          const result = await finishCustomerOrder(
            user,
            cartBatches,
            useCredit,
            userCredits,
            deliveryMethod,
            paymentMethod,
            paymentObservations,
            deliveryAddress,
            deliveryAddressNumber,
            deliveryAddressComplement,
            couponDiscount,
            couponKind,
            validCoupon,
            products,
            batches
          );
          if (result && result.success) {
            window.location = `/pagamentos/${result.paymentId}`;
            localStorage.removeItem('guestCart');
            const items = cartBatches.map((cartItem) => ({
              item_id: cartItem.product_id,
              price: cartItem.amount,
              quantity: cartItem.quantity
            }));
            if (window && window.gtag) {
              window.gtag("event", "begin_checkout", {
                value: result.totalPrice.toFixed(2),
                currency: "BRL",
                items: items
              });
            }
          } else if (result) {
            console.error("Order processing failed:", result.message);
          }
        } catch (error) {
          console.error("Error processing order:", error);
        } finally {
          setIsProcessing(false);
          setIsOrderProcessing(false);
        }
      }
    };

    if (isOrderProcessing) {
      processOrder();
    }
  }, [user, isOrderProcessing]);


  async function hendleRemoveCartBatch(e, cartBatch) {
    e.preventDefault()
    const userId = localStorage.getItem('userId');
    if (window.confirm("Tem certeza de que deseja continuar?")) {
      const matchingBatch = batches.find(batch => batch.id === cartBatch.batch_id)
      decrementCartCounter();
      if (matchingBatch) {
        const matchingProduct = products.find(product => product.id === matchingBatch.product_id);
        if (matchingProduct) {
          window.gtag('event', 'remove_from_cart', {
            currency: "BRL",
            value: cartBatch.amount,
            'items': [{
              'item_name': matchingProduct.name,
              'item_id': matchingProduct.id,
              'price': cartBatch.amount,
              'quantity': cartBatch.quantity
            }]
          });
        } else {
          console.error('Matching product not found.');
        }
      } else {
        console.error('Matching batch not found.');
      }
      if (user) {
        try {
          const response = await api.delete(`/api/v1/cart_batches/${cartBatch.id}`);
          if (response.status === 204) {
            const updatedBatches = cartBatches.filter(batch => batch.id !== cartBatch.id);
            setCartBatches(updatedBatches);
            swal({
              title: "Ok",
              text: "Produto removido",
              icon: "success",
            });
          } else {
            swal({
              title: "Opss",
              text: "Algo deu errado!!",
              icon: "error",
            });
          }
        } catch (error) {
          console.error('Error removing cart batch:', error);
          return false;
        }
      } else {
        try {
          const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
          const updatedGuestCart = guestCart.filter(item => item.batch_id !== cartBatch.batch_id);
          localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
          updateCartState(cartBatch);
          swal({
            title: "Ok",
            text: "Produto removido do carrinho",
            icon: "success",
          });
        } catch (error) {
          console.error('Error removing cart batch:', error);
          return false;
        }
      }
    }
  }

  function updateCartState(cartBatch) {
    const updatedCartBatches = cartBatches.filter(item => item.batch_id !== cartBatch.batch_id);
    console.log(updatedCartBatches)
    setCartBatches(updatedCartBatches);
    const removedProductBatch = batches.find(batch => batch.id === cartBatch.batch_id);
    if (removedProductBatch) {
      const updatedProducts = products.filter(product => product.id !== removedProductBatch.product_id);
      setProducts(updatedProducts);
      const updatedBatches = batches.filter(batch => batch.id !== cartBatch.batch_id);
      setBatches(updatedBatches);
    }
    swal({
      title: "Ok",
      text: "Produto removido do carrinho",
      icon: "success",
    });
  }

  async function fetchCEP(e) {
    e.preventDefault();
    try {
      const response = await fetch(`https://viacep.com.br/ws/${CEP}/json/`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      console.log(data);
      if (
        data.localidade !== 'Belo Horizonte' &&
        data.localidade !== 'Nova Lima' &&
        data.localidade !== 'Contagem' &&
        data.localidade !== 'Betim' &&
        data.localidade !== 'Lagoa Santa'
      ) {
        swal({
          title: 'Opss',
          text: 'Entregas somente para BH e região',
          icon: 'error',
        });
        setDeliveryAddress({});
        return;
      }
      setDeliveryAddress(data);
    } catch (error) {
      swal({
        title: 'Opss',
        text: 'Erro ao buscar o CEP. Formato inválido ou CEP não encontrado.',
        icon: 'error',
      });
      setDeliveryAddress({});
    }
  }

  function handleDeliveryMethod(value) {
    if (cartBatches.length === 0) {
      alert("Nenhum produto adicionado.");
      return
    }
    setDeliveryMethod(value)
  }

  function handlePaymentMethod(value) {
    setPaymentMethod(value)
  }

  function handleUseUserAddressForDelivery(event) {
    const isChecked = event.target.checked;
    setUseAddressFromUser(isChecked);
    if (isChecked) {
      if (
        userAddress.city !== "" &&
        userAddress.complement !== "" &&
        userAddress.neighborhood !== "" &&
        userAddress.number !== "" &&
        userAddress.postal_code !== "" &&
        userAddress.state !== "" &&
        userAddress.street !== ""
      ) {
        setDeliveryAddress({
          localidade: userAddress.city,
          bairro: userAddress.neighborhood,
          cep: userAddress.postal_code,
          uf: userAddress.state,
          logradouro: userAddress.street
        });
        setDeliveryAddressNumber(userAddress.number);
        setDeliveryAddressComplement(userAddress.complement);
      }
    } else {
      setDeliveryAddress({});
      setDeliveryAddressNumber('');
      setDeliveryAddressComplement('');
    }
  }


  function areAllFieldsFilled(userAddress) {
    return Object.entries(userAddress).every(([key, value]) => {
      if (key === 'complement') {
        return true;
      }
      if (key === 'city') {
        return allowedCities.includes(value.trim());
      }
      return value.trim() !== '';
    });
  }

  const isAllUserAddressFieldsFilled = areAllFieldsFilled(userAddress);


  console.log(isAllUserAddressFieldsFilled);


  useEffect(() => {
    async function validateCoupon() {
      if (couponCode !== "") {
        const response = await api.get(`/validate_coupon?coupon_code=${couponCode}`);
        setValidCoupon(response.data);
        const { result, error, discount, message, discount_kind } = response.data;
        if (result) {
          setStatusCoupon(message);
          setCouponKind(discount_kind)
          setCouponDiscount(discount);
        } else {
          setStatusCoupon(error);
        }
      } else {
        setValidCoupon(undefined);
        setStatusCoupon("Não aplicado");
        setCouponDiscount(0);
      }
    }
    validateCoupon();
  }, [couponCode]);

  const now = new Date();
  const dayOfWeek = now.getDay();
  const currentHour = now.getHours();


  const totalCartAmount = cartBatches.reduce((acc, batch) => acc + batch.amount, 0);
  const percentageOf120 = (totalCartAmount * 100) / 120;
  const amountLeft = 120 - totalCartAmount;
  let colorScheme;

  if (percentageOf120 < 25) {
    colorScheme = 'red';
  } else if (percentageOf120 < 75) {
    colorScheme = 'yellow';
  } else {
    colorScheme = 'green';
  }

  return (
    <>
      <Navbar />
      <Box
        padding={{base: '5% 0%', md: '1% 0% 2% 0%'}}
        marginTop={{ base: "10rem", md: "6rem" }}
      >
        <Box
          background="transparent"
          padding={{base: '2%', md: '30px 90px 50px 90px'}}
        >
          <Box
            display={"flex"}
            flexDirection={{base: 'column', md: 'row'}}
            justifyContent={"space-between"}
            width={{base: '100%', md: '100%'}}
            margin={{base: 'auto', md: ''}}
            minHeight={{base: '90vh', md: ''}}
          >
            <Box width={{base: '100%', md: '70%'}} order={{base: '2', md: '1'}}>
              <Box
                borderRadius={"15px"}
                Shadow="rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px"
                marginBottom={{base: '30px', md: '0px'}}
              >
                {deliveryStep && (<>

                  <Text
                    fontSize={"30px"}
                    color={theme.colors.logoOrange}
                    fontWeight="bold"
                    paddingLeft="2%"
                  >
                    Entrega
                  </Text>

                  {!user && (<Box padding="0% 3% 0% 3%">
                    <Box border="" boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" padding="10px 30px 25px 30px" my="15px" borderRadius="10px">
                      <Text fontSize="18px" fontWeight="500" mb="20px">Dados pessoais</Text>
                      <Box my="5px">
                        <RadioGroup onChange={setAuthType} value={authType}>
                          <Stack direction="flex" justifyContent="space-between">
                            <Radio value="signIn">Já tenho uma conta</Radio>
                            <Radio value="signUp">Cadastrar</Radio>
                          </Stack>
                        </RadioGroup>
                      </Box>
                      { authType === "signIn" ?
                        <>

                        </>
                      :
                        <>
                          <Box
                            margin="10px 0"
                            display={{base: "block", md: "flex"}}
                            gap={5}
                          >
                            <Box width="100%">
                              <Input
                                placeholder="E-mail"
                                margin="10px 0 0 0"
                                onChange={(e) => setUserEmail(e.target.value)}
                                borderColor={theme.colors.lightGray}
                                _focus={{
                                  outline: 'none',
                                  boxShadow: 'none',
                                  borderColor: theme.colors.lightGray,
                                }}
                                autoComplete="current-email"
                              />
                              {errors.email && <Text color="red.500" fontSize="sm">{errors.email[0] === "is not an email" ? "Email Inválido" : errors.email[0]}</Text>}
                            </Box>
                            <Box width="100%">
                              <Input
                                id="name-input"
                                placeholder="Nome Completo"
                                onChange={(e) => setUserName(e.target.value)}
                                borderColor={theme.colors.lightGray}
                                marginBottom="10px"
                                marginTop="10px"
                                _focus={{
                                  outline: 'none',
                                  boxShadow: 'none',
                                  borderColor: theme.colors.lightGray,
                                }}
                                autoComplete="current-name"
                              />
                              {errors.full_name && <Text color="red.500" fontSize="sm">{errors.full_name[0]}</Text>}
                            </Box>
                          </Box>

                          <Box
                            margin="10px 0"
                            display={{base: "block", md: "flex"}}
                            gap={5}
                          >
                            <Box width="100%">
                              <InputMask maskType="cpf" placeholder="CPF" setUserDocument={setUserDocument} marginTop="10px"/>
                              {errors.document_number && <Text color="red.500" fontSize="sm">{errors.document_number[0]}</Text>}
                            </Box>
                            <Box width="100%" marginTop={{base: "10px", md: "0px"}}>
                              <InputMask maskType="phone" placeholder="Celular" setUserPhoneNumber={setUserPhoneNumber}/>
                              {errors.phone_number && <Text color="red.500" fontSize="sm">{errors.phone_number[0]}</Text>}
                            </Box>

                            {/* <Input
                              id="password-input"
                              placeholder="Escolha uma senha"
                              type="password"
                              onChange={(e) => setUserPassword(e.target.value)}
                              borderColor={theme.colors.lightGray}
                              marginTop=""
                              _focus={{
                                outline: 'none',
                                boxShadow: 'none',
                                borderColor: theme.colors.lightGray,
                              }}
                              autoComplete="current-password"
                            />
                            {errors.password && <Text color="red.500" fontSize="sm">{errors.password[0]}</Text>} */}
                          </Box>
                        </>
                      }
                    </Box>
                  </Box>)}
                  {user && (user.document_number === "" || user.phone_number === "" || user.full_name === "" ) && (<Box padding="3% 3% 0% 3%">
                    <Box border="" boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" padding="10px 30px 25px 30px" my="15px" borderRadius="10px">

                      <Text fontSize="18px" fontWeight="500" mb="20px">Dados pessoais</Text>
                      <Box>
                        <Box
                          margin="10px 0"
                          display="flex"
                          gap={5}
                        >
                          {user.full_name === "" && (<>
                            <Box width="100%">
                              <Input
                                id="name-input"
                                placeholder="Nome Completo"
                                onChange={(e) => setUserName(e.target.value)}
                                borderColor={theme.colors.lightGray}
                                marginBottom="10px"
                                _focus={{
                                  outline: 'none',
                                  boxShadow: 'none',
                                  borderColor: theme.colors.lightGray,
                                }}
                                autoComplete="current-name"
                              />
                              {errors.full_name && <Text color="red.500" fontSize="sm">{errors.full_name[0]}</Text>}
                            </Box>
                          </>)}
                          {user.document_number === "" && (<>
                            <Box width="100%">
                              <InputMask maskType="cpf" placeholder="CPF" setUserDocument={setUserDocument} marginTop="10px"/>
                              {errors.document_number && <Text color="red.500" fontSize="sm">{errors.document_number[0]}</Text>}
                            </Box>
                          </>)}
                          {user.phone_number === "" && (<>
                            <Box width="100%">
                              <InputMask maskType="phone" placeholder="Celular" setUserPhoneNumber={setUserPhoneNumber}/>
                              {errors.phone_number && <Text color="red.500" fontSize="sm">{errors.phone_number[0]}</Text>}
                            </Box>
                          </>)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>)}
                  <Box padding=".3% 3% 3% 3%">
                    <Box border="" boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" padding="10px 30px 25px 30px" my="15px" borderRadius="10px">
                      <Text
                        fontSize="18px" fontWeight="500" mb="5px"
                      >
                        Logística
                      </Text>
                      <Box>
                        <Box display={{base: "block", md: "flex"}} justifyContent="space-between" gap="30px">
                          <Box>
                            <Checkbox
                              colorScheme='green'
                              value="delivery"
                              marginBottom='10px'
                              borderColor={theme.colors.lightGray}
                              onChange={() => handleDeliveryMethod('delivery')}
                              isChecked={deliveryMethod === 'delivery'}
                            >
                              Entrega - (BH e Região) - 2 a 8 dias úteis.

                            </Checkbox>
                          </Box>
                          <Box>
                            <Checkbox
                              colorScheme='green'
                              value="collect"
                              borderColor={theme.colors.lightGray}
                              onChange={() => handleDeliveryMethod('collect')}
                              isChecked={deliveryMethod === 'collect'}
                            >
                              Coleta - a partir de 8 dias úteis
                            </Checkbox>
                          </Box>
                        </Box>
                        {(deliveryMethod === "delivery") && (
                          <Box marginTop=''>
                            {isAllUserAddressFieldsFilled && (
                              <Checkbox
                                colorScheme='green'
                                value="useAdressForDelivery"
                                borderColor={theme.colors.lightGray}
                                onChange={(e) => handleUseUserAddressForDelivery(e)}
                              >
                                Usar meu endereço
                              </Checkbox>
                            )}
                            <Text
                              fontSize="18px" fontWeight="500" mb="5px"
                            >
                              Endereço
                            </Text>
                            <Box display='flex' gap='10px'>
                              <Input
                                placeholder="Digite o CEP"
                                borderColor={theme.colors.lightGray}
                                type="text"
                                id="cep-input"
                                onChange={(e) => setCEP(e.target.value.replace(/[.-]/g, ''))}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    fetchCEP(e);
                                  }
                                }}
                              />
                              <Button
                                onClick={(e) => fetchCEP(e)}
                                background='transparent'
                                border='1px solid red'
                                borderColor={theme.colors.lightGray}
                                _hover={{
                                  background: theme.colors.primary,
                                  color: 'white'
                                }}
                              >Buscar</Button>
                            </Box>
                            <Box
                              margin="10px 0"
                              display="flex"
                              gap={5}
                            >
                              <Input
                                placeholder="Rua"
                                value={deliveryAddress.logradouro || ''}
                                disabled
                                borderColor={theme.colors.lightGray}
                              />
                              <Input
                                placeholder="Número"
                                type="number"
                                borderColor={theme.colors.lightGray}
                                onChange={(e) => setDeliveryAddressNumber(e.target.value)}
                              />
                            </Box>
                            <Box
                              margin="10px 0"
                              display="flex"
                              gap={5}
                            >
                              <Input
                                placeholder="Complemento"
                                onChange={(e) => setDeliveryAddressComplement(e.target.value)}
                                borderColor={theme.colors.lightGray}
                              />
                              <Input
                                placeholder="Bairro"
                                value={deliveryAddress.bairro || ''}
                                disabled
                                borderColor={theme.colors.lightGray}
                              />
                            </Box>
                            <Box
                              margin="10px 0"
                              display="flex"
                              gap={5}
                            >
                              <Input
                                placeholder="Cidade"
                                value={deliveryAddress.localidade || ''}
                                disabled
                                borderColor={theme.colors.lightGray}
                              />
                              <Input
                                placeholder="Estado"
                                value={deliveryAddress.uf || ''}
                                disabled
                                borderColor={theme.colors.lightGray}
                              />
                            </Box>
                          </Box>
                        )}

                        {deliveryMethod === "collect" && (<>
                          <Text fontWeight="500">Endereço:</Text>
                          <Text textAlign='start'>
                            {collectionAddress.address_street}, {collectionAddress.address_number}, {collectionAddress.address_complement}{collectionAddress.address_neighborhood ? <span>, {collectionAddress.address_neighborhood}</span> : <span></span>} - {collectionAddressCity.name} - {collectionAddressState.acronym} <br />
                            CEP: {collectionAddress.address_postal_code}
                          </Text>
                        </>)}

                        {(
                            deliveryAddress.logradouro !== "" &&
                            deliveryAddress.bairro !== "" &&
                            deliveryAddress.localidade !== "" &&
                            deliveryAddress.uf !== "" &&
                            deliveryAddress.cep !== "" && deliveryAddressNumber !== "") &&
                          (<>
                          <Text fontWeight="500">Endereço para entrega:</Text>
                          <Text textAlign='start'>
                            {deliveryAddress.logradouro}, {deliveryAddressNumber}{deliveryAddressComplement ?  <span>, {deliveryAddressComplement}</span> : <span></span> }, {deliveryAddress.bairro} -
                            {deliveryAddress.localidade} -
                            {deliveryAddress.uf} <br />
                            CEP: {deliveryAddress.cep}
                          </Text>
                        </>)}
                      </Box>

                      <Button
                        isDisabled={isDeliveryStepCompleted}
                        background='green'
                        width="100%"
                        mt="30px"
                        onClick={(e) => paymentStepDisplay(e)}
                        bg={theme.colors.secondaryGreen}
                        color="white"
                        borderRadius="20px"
                        border={`1px solid ${theme.colors.secondaryGreen}`}
                        _hover={{
                          background: "transparent",
                          color: `${theme.colors.secondaryGreen}`,
                          borderCorlor: `${theme.colors.secondaryGreen}`
                        }}
                      >
                        Ir para pagamento
                      </Button>
                    </Box>
                  </Box>
                </>)}
                {paymentStep && (<>
                  <Box
                    background='transparent'
                    borderRadius="15px 15px 0px 0px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="0px 20px"
                  >
                    <Text
                      fontSize={"30px"}
                      color={theme.colors.logoOrange}
                      fontWeight="bold"
                    >
                      Pagamento
                    </Text>
                    <Button
                      background='transparent'
                      border='1px solid transparent'
                      borderRadius="50px"
                      padding="0px"
                      onClick={(e) => deliveryStepDisplay(e)}
                      transform="scaleX(-1)"
                      _hover={{
                        background: "transparent",
                        color: `${theme.colors.secondaryGreen}`,
                        borderCorlor: `${theme.colors.secondaryGreen}`,
                        transform: "scaleX(-1) rotate(180deg)",
                      }}
                    >
                      <FaRedoAlt/>
                    </Button>
                  </Box>

                </>)}

                {paymentStep && (<Box padding="0% 1.5%">
                  <Box border="" boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" padding="20px 30px 25px 30px" my="15px" borderRadius="10px">
                    <Text
                      fontSize="16px" fontWeight="500" mb="5px"
                    >
                      Creditos disponíveis: {userCredits.length > 0 && (userCredits.reduce((n, { amount }) => n + amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})) || `R$0,00`}
                    </Text>
                    <Checkbox colorScheme='green' borderColor={theme.colors.lightGray} onChange={handleUseCreditChange}>
                      Usar créditos na compra
                    </Checkbox>
                    <Text
                      fontSize="16px" fontWeight="500" mb="5px" marginTop="20px"
                    >
                      Selecione o método de pagamento
                    </Text>
                    <Box display='inline-grid'>
                      <Checkbox
                        colorScheme='green'
                        value="collect"
                        borderColor={theme.colors.lightGray}
                        onChange={() => handlePaymentMethod('pix')}
                        isChecked={paymentMethod === 'pix'}
                      >
                        PIX
                      </Checkbox>
                      <Checkbox
                        colorScheme='green'
                        value="collect"
                        borderColor={theme.colors.lightGray}
                        onChange={() => handlePaymentMethod('credit_card')}
                        isChecked={paymentMethod === 'credit_card'}
                      >
                        Cartão de Crédito (até 4x sem juros)
                      </Checkbox>
                    </Box>
                    <Box display={{ base: "block", md: "flex"}} alignContent="center" justifyContent="space-between" gap="10px" marginTop="20px">
                      <Box width="100%">
                        <Text fontSize="16px" fontWeight="500" mb="3px">Observações:</Text>
                        <Input
                          placeholder='Escolha o sabor ou
                          acrescente alguma info'
                          onChange={(e) => setPaymentObservations(e.target.value)}
                          border='1px solid #D9D9D9'
                          padding='5px'
                          fontSize='16px'
                          background='transparent'
                          width={{base: '100%', md: '100%'}}
                          height='40px'
                          _focus={{
                            outline: 'none',
                            boxShadow: 'none',
                          }}
                        />
                      </Box>
                      <Box width="100%">
                        <Box marginTop='' display={{base: 'flex', md: 'flex'}} justifyContent='start' gap='3px' alignItems='center'>
                          <Text fontSize="16px" fontWeight="500" mb="3px">Cupom:</Text>
                          {validCoupon !== undefined && statusCoupon === "Ok" && (<>
                            <Text display='flex' alignItems='center' gap='3px' mb="3px" color='green' fontWeight='bolder'>{statusCoupon} <FaThumbsUp></FaThumbsUp></Text>
                          </>)}

                          {validCoupon !== undefined && statusCoupon !== "Ok"  && statusCoupon !== "Não Aplicado" && (<>
                            <Text display='flex' alignItems='center' gap='3px' mb="3px" color='red' fontWeight='bolder'>{statusCoupon} <FaThumbsDown></FaThumbsDown></Text>
                          </>)}
                          {statusCoupon === "Não aplicado" && (<>
                            <Text color='black' mb="3px" >{statusCoupon}</Text>
                          </>)}
                        </Box>
                        <Input
                          placeholder='Cupom de desconto'
                          onChange={(e) => setCouponCode(e.target.value)}
                          border='1px solid #D9D9D9'
                          padding='5px'
                          fontSize='16px'
                          background='transparent'
                          width={{base: '100%', md: '100%'}}
                          height='40px'
                          _focus={{
                            outline: 'none',
                            boxShadow: 'none',
                          }}
                        />
                      </Box>
                    </Box>

                    <Text
                      fontSize="18px" fontWeight="500" mb="5px" marginTop="30px"
                    >
                      Resumo:
                    </Text>
                    <Box>
                      {setFinalPriceForOrder(cartBatches, deliveryMethod, useCredit, userCredits, paymentMethod, couponDiscount, couponKind, products)}
                    </Box>

                    <Button
                      width="100%"
                      margin={{base: '5% 0%', md: '40px 0px 0px 0px'}}
                      borderRadius="20px"
                      bg={theme.colors.secondaryGreen}
                      color="white"
                      border={`1px solid ${theme.colors.secondaryGreen}`}
                      _hover={{
                        background: "transparent",
                        color: `${theme.colors.secondaryGreen}`,
                        borderCorlor: `${theme.colors.secondaryGreen}`
                      }}
                      onClick={(e) => handleFinishOrder(e)}
                    >
                      FINALIZAR PEDIDO
                    </Button>
                  </Box>
                </Box>)}
              </Box>
            </Box>
            <Box gap='10px' width={{base: '100%', md: '25%'}} order={{base: 1, md: 2}} marginBottom={{base: '5%', md: '0%'}}>
              {(cartBatches.length <= 0 && loadingPage) && (
                <Box display='flex' justifyContent='center' padding='50px'>
                  <Spinner color={theme.colors.primary} margin='auto'/>
                </Box>
              )}
              {(cartBatches.length === 0 && !loadingPage) && (
                <Text
                  textAlign={"center"}
                  fontSize={"24px"}
                  color={theme.colors.primaryGreen}
                  fontWeight={"bold"}
                  py='30px'
                >
                  Nenhum item adicionado ao carrinho.
                </Text>
              )}
              {cartBatches.length > 0 && (
                <Text
                  fontSize={"26px"}
                  color={theme.colors.logoOrange}
                  fontWeight="bold"
                  paddingLeft="2%"
                  mb="5%"

                >Itens:</Text>
              )}

              {cartBatches.length > 0 && cartBatches.map((cartBatch, index) => {
                return (<>
                  <Box display="flex" justifyContent="space-between" gap="5px" alignItems="center">
                    <Image
                      objectFit='cover'
                      maxW={{ base: '100%', sm: '200px' }}
                      width="50px"
                      height="50px"
                      borderRadius="50%"
                      src={photos.find(element => element.product === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.photo || require('../../assets/images/home-cover.webp')}
                      alt='Produto'
                      maxHeight='16.5rem'
                    />
                    <Text pb='2'>{products.find(element => element.id === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.name}</Text>

                    <Button
                      onClick={(e) => hendleRemoveCartBatch(e, cartBatch)}
                      colorScheme="red"
                      size="sm"
                      background='transparent'
                      color='red'
                      fontSize='lg'
                      _hover={{
                        background: 'transparent',
                        color: 'red'
                      }}
                    >
                      <FaRegTimesCircle></FaRegTimesCircle>
                    </Button>
                  </Box>

                  <Box my={2} display={{base: 'flex', md: 'flex'}} width='100%' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' marginTop={{base: '10px', md: '0'}}  width='50%' marginBottom='0' alignItems='center' padding='5px' border={{base: '1px solid rgba(212,65,32, .3)', md: '1px solid rgba(212,65,32, .3)'}} borderRadius='50px' height="30px">
                      <BsDash
                        size={24}
                        weight="fill"
                        cursor="pointer"
                        id="button-decrement"
                        onClick={(e) => handleDecrement(cartBatch)}
                      />
                      <Input
                        id={`cart${cartBatch.id}-quantity`}
                        margin={"0px"}
                        type="number"
                        width={{base: '', md: ''}}
                        padding='0%'
                        background={"transparent"}
                        fontWeight={"bold"}
                        textAlign="center"
                        border="none"
                        value={cartBatch.quantity}
                        readOnly={true}
                      />
                      <BsPlus
                        size={24}
                        weight="fill"
                        cursor="pointer"
                        margin={"auto"}
                        id="button-increment"
                        onClick={(e) => handleIncrement(cartBatch)}
                      />
                    </Box>
                    {setFinalPriceForCartBatch(cartBatch)}
                  </Box>
                  {console.log(cartBatch)}
                  <Text mt={2} textAlign={{base: 'start', md: 'start'}} fontSize="12px">{cartBatch.quantity} {batches?.find(element => element.id === cartBatch.batch_id)?.unity_of_measurement}{cartBatch.quantity > 1 ? 's' : ''} / {cartBatch.quantity * products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.quantity_per_box} {products.find(element => element.id === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.size}</Text>
                  <Text mb={2} textAlign={{base: 'start', md: 'start'}} fontSize="12px">{calculateDeliveryPeriod(batches?.find(element => element.id === cartBatch.batch_id), products.find(element => element.id === (batches.find(element => element.id === cartBatch.batch_id).product_id)), deliveryMethod, cartBatch)}</Text>
                  <Divider orientation='horizontal' borderColor="gray" borderBottomWidth='2px' margin='10px auto'></Divider>
                </>);
              })}
              {totalCartAmount < 120 && (<>
                <Progress
                  margin="30px auto 5px auto"
                  borderRadius="3px"
                  colorScheme={colorScheme}
                  width="100%"
                  size="sm"
                  value={percentageOf120}
                />
                <Text textAlign="center">+ {amountLeft.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} para Frete grátis</Text>
              </>)}
              {totalCartAmount >= 120 && (
                <Text textAlign="center" color="green">Frete grátis</Text>
              )}
              <Box display='flex' justifyContent='space-between' my='30px' mx="20px" alignItems="center">
                <Text fontSize="14px" fontWeight="light">Subtotal(itens):</Text>
                <Text fontWeight="bold">{totalCartAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}

import {
  Box,
  Input,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  Select,
  Divider,
  Checkbox
} from "@chakra-ui/react";
import { theme } from "../../../styles/theme";
import { InputMask } from '../../../components/InputMask'

export function Form({isOpen, onClose, user, handleProfileChange, handleEditUser, states, cities, handleLocality, mapedCitiesForState, profile, handleFileChange, userPhoto, setUserDocument, userDocument, setUserPhoneNumber, userPhoneNumber, errors}) {
  function handleTogglePasswordVisibility(e) {
    e.preventDefault();
    const passwordInput = document.getElementById('password')
    if (e.target.checked) {
      passwordInput.type = "text"
    } else {
      passwordInput.type = "password"
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} overlayStyle={{ backgroundColor: `${theme.colors.whiteSmoke}` }}>
      <ModalOverlay />
      <ModalContent bg="#f5f5f5">
        <ModalHeader color={theme.colors.logoOrange}>Editar Infos</ModalHeader>
        <ModalCloseButton _hover={{background: theme.colors.primary}}/>
        <Divider py="5px"/>
        <ModalBody>
          <FormControl encType="multipart/form-data">
            <Text>Email</Text>
            <Input borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} name="email" value={profile?.email} onChange={handleProfileChange}></Input>
            <Text marginTop="10px">Senha</Text>
            <Input id="password" borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} type="password" name="password" value={profile?.password} onChange={handleProfileChange}></Input>
            <Checkbox onChange={(e) => handleTogglePasswordVisibility(e)} textAlign={"left"} marginTop={"5px"}>Mostrar senha?</Checkbox>

            <Text marginTop="10px">Nome</Text>
            <Input borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} name="full_name" value={profile?.full_name} onChange={handleProfileChange}></Input>
            {errors.full_name && <Text color="red.500" fontSize="sm">{errors.full_name[0]}</Text>}
            <Box
              display={"flex"}
              gap={"10px"}
              marginTop="10px"
            >
              <Box>
                <Text marginTop="10px">CPF</Text>
                <InputMask maskType="cpf" placeholder="CPF" setUserDocument={setUserDocument} value={userDocument} onChange={(e) => setUserDocument(e.target.value)}/>
                {errors.document_number && <Text color="red.500" fontSize="sm">{errors.document_number[0]}</Text>}
              </Box>
              <Box>
                <Text marginTop="10px">Celular</Text>
                <InputMask maskType="phone" placeholder="(99) 99999-9999" name="phone_number" value={userPhoneNumber} setUserPhoneNumber={setUserPhoneNumber} onChange={(e) => setUserPhoneNumber(e.target.value)}/>
              </Box>
            </Box>
            <Box>
              <Text marginTop="10px">Logradouro</Text>
              <Input borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} name="address_street" value={profile?.address_street} onChange={handleProfileChange}></Input>
            </Box>
            <Box
              display={"flex"}
              gap={"10px"}
              marginTop="10px"
            >
              <Box>
                <Text>Nº</Text>
                <Input borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} name="address_number" value={profile?.address_number} onChange={handleProfileChange}></Input>
              </Box>
              <Box>
                <Text>Complemento</Text>
                <Input borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} name="address_complement" value={profile?.address_complement} onChange={handleProfileChange}></Input>
              </Box>
            </Box>
            <Box
              display={"flex"}
              gap={"10px"}
              marginTop="10px"
            >
              <Box>
                <Text>Bairro</Text>
                <Input borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} name="address_neighborhood" value={profile?.address_neighborhood} onChange={handleProfileChange}></Input>
              </Box>
              <Box>
                <Text>CEP</Text>
                <Input borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}} name="address_postal_code" value={profile?.address_postal_code} onChange={handleProfileChange}></Input>
              </Box>
            </Box>
            <Box
              display={"flex"}
              gap={"10px"}
              marginTop="10px"
            >
              <Box>
                <Text >Estado</Text>
                <Select
                  name="state_id"
                  id="state_input"
                  width="100%"
                  value={profile?.state_id || ""}
                  onChange={(e) => {
                    handleProfileChange(e);
                    handleLocality(e);
                  }}
                  borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}}
                >
                  <option value=""></option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.acronym}
                    </option>
                  ))}
                </Select>
              </Box>
              {(mapedCitiesForState.length > 0) && (
                <Box>
                  <Text>Cidade</Text>
                  <Select
                    name="city_id"
                    value={profile?.city_id || ""}
                    width="100%"

                    onChange={handleProfileChange}
                    borderColor={theme.colors.lightGray} _hover={{borderColor: theme.colors.lightGray}}
                  >
                    {mapedCitiesForState.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              )}
            </Box>
            <Input type="file" id="userPhoto" onChange={handleFileChange} name="photo" multiple={false} marginTop='20px' border='none' display="none"/>
            <label htmlFor="userPhoto">
              <Box
                as="span"
                display="inline-block"
                border="1px solid"
                borderColor={theme.colors.secondaryGreen}
                color={theme.colors.secondaryGreen}
                borderRadius="20px"
                padding="5px 10px"
                cursor="pointer"
                backgroundColor="transparent"
                marginTop="10px"
                marginRight="10px"
                _hover={{background: theme.colors.secondaryGreen, borderColor: theme.colors.secondaryGreen, color: "white"}}
              >
                Escolher Foto
              </Box>
              {userPhoto && (
                <span>{userPhoto.name}</span>
              )}
            </label>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={(e) => handleEditUser(e)}
            bg={theme.colors.secondaryGreen}
            color="white"
            size="md"
            borderRadius="20px"
            border={`1px solid ${theme.colors.secondaryGreen}`}
            _hover={{
              background: "transparent",
              color: `${theme.colors.secondaryGreen}`,
              borderCorlor: `${theme.colors.secondaryGreen}`
            }}
          >
            Editar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { createContext, useEffect, useState } from "react";
import { api } from "../services/api";
import swal  from 'sweetalert';

export const userToken = () => window.localStorage.getItem("@app/token");
export const AuthContext = createContext({});
export function AuthContextProvider(props) {
  const [user, setUser] = useState();
  const isAuthenticated = () => userToken() !== null;
  const [errors, setErrors] = useState({});

  async function getUserInfo(requestBody) {
    if (isAuthenticated()) {
      try {
        const response = await api.get("/api/v1/users/me", {
          headers: {
            Authorization: `Bearer ${userToken()}`,
            uid: window.localStorage.getItem("@app/uid"),
            client: window.localStorage.getItem("@app/client"),
            "access-token": window.localStorage.getItem("@app/token"),
            "Cross-Origin-Opener-Policy": "same-origin-allow-popups",
          }
        });
        const user = response.data;
        setUser(user);
        localStorage.setItem("userPhoto", response.data.user_photo)
        localStorage.setItem("userId", response.data.id)

      } catch {
        signOutUser();
      }
    }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  async function signInUser(email, password) {
    const requestBody = {
      email,
      password
    };
    try {
      const response = await api.post("/api/v1/auth/sign_in", requestBody);
      if (response) {
        window.localStorage.setItem("@app/token", response.headers["access-token"]);
        window.localStorage.setItem("@app/uid", response.headers["uid"]);
        window.localStorage.setItem("@app/client", response.headers["client"]);
        window.localStorage.setItem("@app/expiry", response.headers["expiry"]);
        await getUserInfo(requestBody);
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];

        if (guestCart.length > 0) {
          await transferGuestCartToUser(guestCart);
          localStorage.setItem('guestCart', JSON.stringify([]));
        }
        window.location.href = "/carrinho";
      } else {
        swal({
          title: "Ops",
          text: "Ocorreu um erro no seu login, confira seus dados",
          icon: "error",
        });
      }
    } catch {
      swal({
        title: "Ops",
        text: "Ocorreu um erro no seu login, confira seus dados",
        icon: "error",
      });

    }
  }

  async function signUpUser(email, password, full_name, document_number, phone_number) {
    const requestBody = { email, password, full_name, document_number, phone_number };
    try {
      const response = await api.post("/api/v1/auth", requestBody);

      if (response) {
        window.localStorage.setItem(
          "@app/token",
          response.headers["access-token"]
        );
        window.localStorage.setItem("@app/uid", response.headers["uid"]);
        window.localStorage.setItem("@app/client", response.headers["client"]);
        window.localStorage.setItem("@app/expiry", response.headers["expiry"]);

        await getUserInfo();
        if (typeof window !== "undefined") {
          if (window.fbq != null) {
            window.fbq('trackCustom', 'signUpUser');
          }
        }
      } else {
        alert("Ocorreu um erro no seu cadastro");
      }
    } catch (e) {
      if (e.response && e.response.data.errors) {
        // Capture the attribute-specific errors
        setErrors(e.response.data.errors);
      } else {
        // General error handling (if errors aren't in the expected format)
        swal({
          title: "Erro",
          text: "Ocorreu um erro ao cadastrar. Tente novamente.",
          icon: "error",
        });
      }

    }
  }

  async function transferGuestCartToUser(guestCart) {
    console.log(guestCart);
    const promises = guestCart.map(async (cartBatch, index) => {
      const dataObject = new FormData();
      dataObject.append('cart_batch[amount]', cartBatch.amount);
      dataObject.append('cart_batch[quantity]', cartBatch.quantity);
      dataObject.append('cart_batch[user_id]', user.id);
      dataObject.append('cart_batch[batch_id]', cartBatch.batch_id);
      const response = await api.post(`/api/v1/cart_batches`, dataObject);
      return response;
    });

    return Promise.all(promises).then((responses) => {
      if (responses.every((response) => response.data.status === 'created')) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: 'Algo deu errado',
        };
      }
    });
  }


  async function signOutUser() {
    setUser(null);
    window.localStorage.removeItem("@app/token");
    window.localStorage.removeItem("@app/uid");
    window.localStorage.removeItem("@app/client");
    window.localStorage.removeItem("@app/expiry");
    localStorage.removeItem('userId');
    window.location = "/"
  }

  return (
    <AuthContext.Provider
      value={{ user, signInUser, isAuthenticated, signOutUser, signUpUser, setUser, getUserInfo, errors, setErrors }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

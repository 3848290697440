import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export function UsersChart({users}) {
  const options = {
    responsive: true,
    maintainAspectRatio: true, // Ensures aspect ratio is maintained
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Usuários cadastrados',
      },
    },
  };

  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  function convertMonthToLabelFormat(date) {
    const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const updatedAtDate = new Date(date);
    const monthIndex = updatedAtDate.getMonth();
    return monthNames[monthIndex];
  }

  const getMonthlySum = (month) => {
    return month.length;
  };

  const datasetData = labels.map(labelMonth => {
    const monthlyUsers = users.filter(user => convertMonthToLabelFormat(user.created_at) === labelMonth);
    return getMonthlySum(monthlyUsers);
  });


  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Usuários cadastrados',
        data: datasetData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };



  return (<>
    <div
      style={{
        width: '100%',
        height: '30rem',
        borderRadius: '15px',
        padding: '20px',
        margin: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
      }}
    >
      <Line options={options} data={data} style={{ margin: 'auto', width: "100%", height: "100%" }}/>
    </div>
  </>)
}

import { Box, Text, Image, Divider  } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { useAuth } from "../../Hooks/useAuth";
import { useContext, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { api } from "../../services/api";
import { FaInstagram, FaMapMarkerAlt, FaHome} from "react-icons/fa";
import { CityContext } from '../../Context/CityContext';
import { RiQuestionLine, RiWhatsappLine, RiMailLine, RiTimeLine, RiHeart3Line    } from "react-icons/ri";
import visaImage from '../../assets/images/visa.png';
import cardImage from '../../assets/images/card.png';
import pixImage from '../../assets/images/pix.png';
import eloImage from '../../assets/images/elo.png';

export function Footer() {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { cityParam, cityName, cities, handleSetCity } = useContext(CityContext);

  useEffect(() => {
    isAuthenticated();
  }, [])

  const handleBestSellings = async () => {
    try {
      let url = `/api/v1/products?city_id=${cityParam}&produtos=best_selling`;
      const response = await api.get(url);
      const productsData = response.data.products;
      navigate('/produtos', { state: { products: productsData } });
    } catch (error) {
      console.error(error);
    }
  };

  return (<>
    <Box
      height={{ base: 'auto', md: '30vh' }}

      display={window.screen.width < 768 ? "block" : "block"}
      justifyContent={"space-around"}
      position={"relative"}
      bottom="0px"
      width="100%"
      alignItems={"end"}
      paddingTop={"30px"}
      textAlign={{ base: 'center', md: '' }}
    >
      <Box display={{base: "block", md: "flex"}} justifyContent="space-around">
        <Box textAlign={{base: "center", md: "start"}}>
          <Text>Ajuda e Suporte</Text>
          <a href="/quem_somos" target="_blank" rel="noopener noreferrer" style={{color: "gray",}}>
            <Box display="flex" justifyContent={{base: "center", md: "start"}} alignItems="center" gap="5px">
              <RiQuestionLine color={"gray"} weight="fill"/> Quem Somos
            </Box>
          </a>


          <a href='https://instagram.com/boozenoficial?igshid=NzZlODBkYWE4Ng==' target='_blank' rel="noopener noreferrer" style={{color: "gray",}}>
            <Box display="flex" justifyContent={{base: "center", md: "start"}} alignItems="center" gap="5px">
              <FaInstagram color='gray' width='30px'></FaInstagram> Insta
            </Box>
          </a>

        </Box>
        <Box textAlign={{base: "center", md: "start"}} marginTop={{base: "30px", md: "0px"}}>
          <Text>Contato</Text>
          <a href="https://wa.me/5531971301228" target="_blank" rel="noopener noreferrer" style={{color: "gray",}}>
            <Box display="flex" justifyContent={{base: "center", md: "start"}} alignItems="center" gap="5px">
              <RiWhatsappLine  color="gray" weight="fill"/> (31) 97130-1228
            </Box>
          </a>
          <Text color="gray" display="flex" justifyContent={{base: "center", md: "start"}} alignItems="center" gap="5px"><RiMailLine  color="gray" weight="fill"/>contato@boozen.com.br</Text>
          <Text color="gray" display="flex" justifyContent={{base: "center", md: "start"}} alignItems="center" gap="5px"><RiTimeLine   color="gray" weight="fill"/> Seg à Sex - 13:00 às 19:00 | Sáb - 11:00 às 15:00</Text>

        </Box>
      </Box>

      <Box display={{base: "block", md: "flex"}} justifyContent={{base: "center", md: "space-around"}} alignItems="center" marginTop="30px">
        <Box textAlign={{base: "center", md: "start"}}>
          <Text>Formas de pagamento</Text>
          <Box display="flex" justifyContent={{base: "center", md: "start"}} alignItems="center" gap="5px" flexWrap="wrap">
            <Image
              objectFit='cover'
              width={{ base: '60px', sm: '60px' }}
              height={{ base: '60px', sm: '60px' }}
              src={visaImage}
              alt='Produto'
            />
            <Image
              objectFit='cover'
              width={{ base: '60px', sm: '60px' }}
              height={{ base: '60px', sm: '60px' }}
              src={cardImage}
              alt='Produto'

            />
            <Image
              objectFit='cover'
              width={{ base: '100px', sm: '100px' }}
              height={{ base: '50px', sm: '50px' }}
              src={pixImage}
              alt='Produto'

            />
            <Image
              objectFit='cover'
              width={{ base: '60px', sm: '60px' }}
              height={{ base: '50px', sm: '50px' }}
              src={eloImage}
              alt='Produto'
            />
          </Box>
        </Box>
        <Box textAlign={{base: "center", md: "start"}}>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Link to={'/'}>
              <FaHome color="gray"/>
            </Link>
            <FaMapMarkerAlt color={theme.colors.orange}></FaMapMarkerAlt>
            <Text color={theme.colors.orange}>Belo Horizonte e Região</Text>
          </Box>
        </Box>
      </Box>
    </Box>
    <Divider my="20px"/>
    <Box  display={{base: "block", md: "flex"}} justifyContent={{base: "center", md: "space-around"}} alignItems="center">
      <Text
        fontSize={{ base: '12px', md: '12px' }}
        fontWeight={"lighter"}
        paddingBottom="20px"
        color={"black"}
        textAlign="center"
      >
        Copyright 2022 Todos os direitos reservado a Boozen | INNOVATION . CNPJ: 49.997.548/0001-47
      </Text>
      <Text
        fontSize={{ base: '12px', md: '12px' }}
        fontWeight={"lighter"}
        paddingBottom="20px"
        color={"black"}
        display="flex"
        alignItems="center"
        gap="3px"
        justifyContent="center"
      >
        Desenvolvido com <RiHeart3Line color="red"/> por LufeCodev
      </Text>
    </Box>
  </>);
}

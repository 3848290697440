import {
  Box,
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useState, useReducer } from "react";
import { api } from "../../../services/api";
import { Select } from '@chakra-ui/react';
import { theme } from "../../../styles/theme";
import { useAuth } from "../../../Hooks/useAuth";
import { BatchCard } from "../BatchCard";
import { EditProduct } from "../EditProduct";
import swal  from 'sweetalert';
import { FaPlus } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function ProductCard({allProducts, allProductTypes, allBatches, allPrices}) {
  const { user } = useAuth();
  const [products, setProducts] = useState(allProducts);
  const [productTypes, setProductTypes] = useState(allProductTypes);
  const [product, setProduct] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      description: "",
      brand: "",
      size: "",
      quantity_per_box: "",
      market_price: "",
      product_type_id: "",
      suggested_price_for_sale: "",
      customer_review: ""
    }
  );
  const [productPhotos, setProductPhotos] = useState([]);
  const [productFlyer, setProductFlyer] = useState(null);
  const [toggleProductCreation, setToggleProductCreation] = useState(false);


  const handleProductChange = (name, value) => {
    console.log("name:", name, "value:", value);
    setProduct({ [name]: value });
  }

  async function handleSubmitNewProduct(e) {
    e.preventDefault();
    if (product.name === "" || product.brand === "" || product.description === "" || product.product_type_id === "" || product.quantity_per_box === "") {
      swal({
        title: "Ops",
        text: "Nome, marca, descrição, tipo e quantidade por caixa não podem ficar em branco.",
        icon: "info",
      });
      return
    }
    const formData = new FormData()
    formData.append("product[name]", product.name);
    formData.append("product[product_type_id]", product.product_type_id);
    formData.append("product[brand]", product.brand);
    formData.append("product[size]", product.size);
    formData.append("product[description]", product.description);
    formData.append("product[market_price]", product.market_price);
    formData.append("product[quantity_per_box]", product.quantity_per_box);
    formData.append("product[suggested_price_for_sale]", product.suggested_price_for_sale);
    formData.append("product[customer_review]", product.customer_review);

    if (productFlyer) {
      formData.append("product[flyer]", productFlyer);
    }
    if (productPhotos) {
      for (let i = 0; i < productPhotos.length; i++) {
        formData.append("product[photos][]", productPhotos[i]);
      }
    }
    try {
      const response = await api.post(`/api/v1/products`, formData, {
        headers: {
          "access-token": user.token,
          client: user.client,
          uid: user.uid,
          accept: "application/json",
          contentType: "multipart/form-data"
        },
      });
      if (response.status === 200) {
        products.push(response.data)
        swal({
          title: "Ok",
          text: "Produto criado",
          icon: "success",
        });
        handleToggleShowCreationOfProduct();
      } else {
        swal({
          title: "Oh No",
          text: "Algo deu errado!",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
  }

  async function handleRemoveProduct(e, productId) {
    e.preventDefault();
    try {
      const response = await api.delete(`/api/v1/products/${productId}`);
      if (response.status === 204) {
        const indexToRemove = products.findIndex((product) => product.id === productId);
        if (indexToRemove !== -1) {
          const updatedProducts = [...products];
          updatedProducts.splice(indexToRemove, 1);
          setProducts(updatedProducts);
          swal({
            title: "Ok",
            text: "Produto removido",
            icon: "success",
          });
        } else {
          swal({
            title: "Opss",
            text: "Produto não encontrado na lista",
            icon: "error",
          });
        }
      } else {
        swal({
          title: "Opss",
          text: "Algo deu errado!!",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error creating product:", error);
      swal({
        title: "Opa",
        text: "Algum pedido foi feito com esse produto, não é possivel remove-lo!!",
        icon: "error",
      });
    }
  }

  const updateProduct = (updatedProduct) => {
    const updatedProducts = products.map((product) =>
      product.id === updatedProduct.id ? updatedProduct : product
    );
    setProducts(updatedProducts);
  };

  const handleToggleShowCreationOfProduct = () => {
    setToggleProductCreation(!toggleProductCreation);
  }

  let modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{
        'color': ['black', 'red', 'blue', 'white', 'green', 'yellow', 'pink', 'gray', '#90a7bf', 'color-picker', '#D44120', '#F6B93D', '#216a0f', '#49b82d', '#ef9207', '#ef5f0e', '#ff6600']
      }],
      [{ 'background': ['transparent', 'black', 'red', 'blue', 'white', 'green', 'yellow', 'pink', 'gray', '#90a7bf', '#D44120', '#F6B93D', '#216a0f', '#49b82d', '#ef9207', '#ef5f0e', '#ff6600'] }],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }


  return (
    <>
      <Button
        type="button"
        onClick={handleToggleShowCreationOfProduct}
        background="transprent"
        _hover={{ bg: "transparent", color: `${theme.colors.primary}` }}

      >
        <FaPlus></FaPlus> Cadastrar novo produto
      </Button>
      {toggleProductCreation && (
        <Box>
          <Box
            margin="20px 0"
          >
            <Input
              placeholder="Nome"
              // onChange={(e) => handleProductChange(e)}
              onChange={(e) => handleProductChange("name", e.target.value)}

              name="name"
            />
          </Box>
          <Box
            margin="20px 0"
          >
            {/* <Input
              placeholder="Descrição"
              onChange={(e) => handleProductChange(e)}
              name="description"
            /> */}
            <ReactQuill
              theme="snow"
              modules={modules}
              name="description"
              placeholder="Descrição"
              // onChange={(e) => handleProductChange(e)}
              onChange={(value) => handleProductChange("description", value)}
            />

          </Box>
          <Box
            display="flex"
            gap={8}
            margin="20px 0"
          >
            <Input
              placeholder="Marca"
              // onChange={(e) => handleProductChange(e)}
              onChange={(e) => handleProductChange("brand", e.target.value)}

              name="brand"
            />
            <Input
              placeholder="Quantidade por caixa"
              type={"number"}
              // onChange={(e) => handleProductChange(e)}
              onChange={(e) => handleProductChange("quantity_per_box", e.target.value)}
              name="quantity_per_box"
            />
          </Box>
          <Input
            placeholder="Tamanho"
            // onChange={(e) => handleProductChange(e)}
            onChange={(e) => handleProductChange("size", e.target.value)}
            name="size"
            marginBottom="20px"
          />
          <ReactQuill
            theme="snow"
            modules={modules}
            name="customer_review"
            placeholder="Review do cliente"
            // onChange={(e) => handleProductChange(e)}
            onChange={(value) => handleProductChange("customer_review", value)}
          />
          <Box display="flex" gap={8} margin="20px 0">
            <Select
              placeholder='Selecione o tipo de bebida'
              // onChange={(e) => handleProductChange(e)}
              onChange={(e) => handleProductChange("product_type_id", e.target.value)}
              name="product_type_id"
            >
              {productTypes.map(productType => (
                <option
                  key={productType.id}
                  value={productType.id}
                >
                  {productType.name}
                </option>
              ))}
            </Select>
            <Input
              placeholder="Preço no mercado"
              type={"number"}
              // onChange={(e) => handleProductChange(e)}
              onChange={(e) => handleProductChange("market_price", e.target.value)}
              name="market_price"
            />
            <Input
              placeholder="Preço sugerido"
              type={"number"}
              // onChange={(e) => handleProductChange(e)}
              onChange={(e) => handleProductChange("suggested_price_for_sale", e.target.value)}
              name="suggested_price_for_sale"
            />
          </Box>
          <Box>
            <Input type="file" id="productPhotos" multiple name="photos" display="none" onChange={(e) => setProductPhotos(e.target.files)}>
            </Input>
            <label htmlFor="productPhotos">
              <Box
                as="span"
                display="inline-block"
                border="1px solid"
                borderColor={theme.colors.primary}
                borderRadius="md"
                padding="5px"
                cursor="pointer"
                backgroundColor="transparent"
                marginTop="20px"
                marginRight="10px"
                color={theme.colors.primary}
                _hover={{background: theme.colors.primary, color: theme.colors.orange}}
              >
                Escolher Fotos
              </Box>
              {productPhotos && (
                productPhotos.length
              )} fotos selecionadas
            </label>
          </Box>

          <Box>
            <Input type="file" id="productFlyers" display="none" multiple={false} name="flyers" onChange={(e) => setProductFlyer(e.target.files[0])}>
            </Input>

            <label htmlFor="productFlyers">
              <Box
                as="span"
                display="inline-block"
                border="1px solid"
                borderColor={theme.colors.primary}
                borderRadius="md"
                padding="5px"
                cursor="pointer"
                backgroundColor="transparent"
                marginTop="20px"
                marginRight="10px"
                color={theme.colors.primary}
                _hover={{background: theme.colors.primary, color: theme.colors.orange}}
              >
                Escolher Flyer
              </Box>
              {productFlyer && (
                productFlyer.name
              )}
            </label>
          </Box>
          <Button
            background={theme.colors.green500}
            color={theme.colors.white500}
            onClick={(e) => handleSubmitNewProduct(e)}
            marginTop="30px"
            _hover={{
              opacity: 0.8,
            }}
          >
            Cadastrar produto
          </Button>
        </Box>
      )}


      <Box marginTop="30px">
        <Text textAlign="center" marginBottom="20px" fontSize="28px" fontWeight="bolder">Produtos</Text>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>#Id</Th>
                <Th>Nome</Th>
                <Th>Marca</Th>
                <Th>Descrição</Th>
                <Th>Tamanho</Th>
                <Th>Qnt por caixa</Th>
                <Th>Lote</Th>
                <Th>Editar produto</Th>
                <Th>Remover</Th>
              </Tr>
            </Thead>
            <Tbody>
            {products?.map((product) => {
              return (
                <Tr
                  key={product.id}
                >
                  <Td>{product.id}</Td>
                  <Td>{product.name.substring(0, 40)} (...)</Td>
                  <Td>{product.brand.substring(0, 10)}</Td>
                  <Td>{product.description.substring(0, 10)} (...)</Td>
                  <Td>{product.size}</Td>

                  <Td>{product.quantity_per_box}</Td>
                  <Td>
                    <BatchCard
                      productId={product.id}
                      productName={product.name}
                      allBatches={allBatches}
                      // allPrices={allPrices}
                      // allPrices={allPrices?.filter((price) => price.batch_id === allBatches?.find(batch => batch.product_id === product.id).id)}
                      allPrices={allPrices?.filter((price) => {
                        const productBatch = allBatches?.find((batch) => batch.product_id === product.id);
                        return productBatch && price.batch_id === productBatch.id;
                      })}
                      productBatch={allBatches?.find(batch => batch.product_id === product.id)}
                    />
                  </Td>
                  <Td>
                    <EditProduct
                      productId={product.id}
                      allProductTypes={productTypes}
                      onUpdateProduct={updateProduct}
                      thisProduct={product}
                    />
                  </Td>
                  <Td>
                    <Button
                      onClick={(e) => handleRemoveProduct(e, product.id)}
                    >
                      Remover
                    </Button>
                  </Td>
                </Tr>
              )
            })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

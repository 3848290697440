import {
  Button,
  Text,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useContext, useState } from "react";
import { api } from "../../services/api";
import { CityContext } from '../../Context/CityContext';
import { useLocation } from 'react-router-dom';

export function BeerFilters(props) {
  const location = useLocation();
  const [beerTypes, setBeerTypes] = useState([]);
  const [beerBrands, setBeerBrands] = useState([]);
  const { cityParam } = useContext(CityContext);


  const brandOptions = ["HOOG BIER",
    "verace",
    "CAPITÃO SENRA",
    "BACKER",
    "VERACE",
    "Albanos",
    "Évora",
    "LAUT",
    "ORIGINAL",
    "ALBANOS",
    "BOHEMIA",
    "Armadillo",
    "Backer",
    "Heineken",
    "Cervejaria Mutuca",
    "MillsBrewery",
    "Sapatona cervejaria",
    "Laut"
  ]

  const beerTypeOptions =  ["PILSEN",
    "IPA",
    "APA",
    "German Pils",
    "Low Carb",
    "Hop Lager",
    "Pale Ale",
    "Witbier",
    "Session Ipa",
    "LowCarb",
    "Laut Pilsen",
    "Double IPA",
    "BROWN ALE",
    "HOP LAGER",
    "LAGER",
    "BLOND ALE",
    "Microcervejarias"
  ]

  async function handleGetProductBrand(e, brand) {
    e.preventDefault();
    const updatedBeerBrands = beerBrands.includes(brand)
      ? beerBrands.filter((b) => b !== brand)
      : [...beerBrands, brand];
    setBeerBrands(updatedBeerBrands);
    filterProducts(updatedBeerBrands, beerTypes);
  }

  async function handleGetProductTypes(e, productType) {
    e.preventDefault();

    // Toggle the selected product type
    const updatedBeerTypes = beerTypes.includes(productType)
      ? beerTypes.filter((type) => type !== productType)
      : [...beerTypes, productType];

    setBeerTypes(updatedBeerTypes);

    // Filter products based on selected brands and beer types
    filterProducts(beerBrands, updatedBeerTypes);
  }

  async function filterProducts(brands, types) {
    let url = `/api/v1/products?city_id=${cityParam}`;

    // If there are selected brands, append them to the URL
    if (brands.length > 0) {
      url = `${url}&beer_brands=${brands.join(',')}`;
    }

    // If there are selected beer types, append them to the URL
    if (types.length > 0) {
      url = `${url}&beer_types=${types.join(',')}`;
    }

    // If no filters are selected, retain the current filter from the URL
    if (types.length === 0 && brands.length === 0) {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      url = `${url}&filter=${filter}`;
    }

    // Make the API request with the constructed URL
    const response = await api.get(url);
    props.setProducts(response.data.products);
  }


  return (
    <>
    <Text fontWeight="bold" color={theme.colors.primaryGreen} mb="20px">Tipos:</Text>
    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
      gap={4}
    >
      {beerTypeOptions.map((beerTypeOption, index) => {
        return(
          <GridItem key={index}>
            <Button
              key={index}
              width={"100%"}
              onClick={(e) => handleGetProductTypes(e, beerTypeOption)}
              color={theme.colors.secondaryGreen}
              border={`1px solid ${theme.colors.secondaryGreen}`}
              borderRadius="20px"
              size="sm"
              _hover={{
                transform: "scale(1.02)",
                color: "white",
                bg: `${theme.colors.secondaryGreen}`,
              }}
              style={{
                backgroundColor: beerTypes.includes(beerTypeOption) ? `${theme.colors.secondaryGreen}` : "transparent",
                color: beerTypes.includes(beerTypeOption) ? "white" : `${theme.colors.secondaryGreen}`,
              }}
            >
              {beerTypeOption}
            </Button>
          </GridItem>
        )
      })}
    </Grid>
    <Text pt={4} fontWeight="bold" color={theme.colors.primaryGreen} mb="20px">Marcas:</Text>
    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
      gap={4}

    >
      {brandOptions.map((brandOption, index) => {
        return(
          <Button
            key={index}
            width={"100%"}
            onClick={(e) => handleGetProductBrand(e, brandOption)}
            color={theme.colors.secondaryGreen}
            border={`1px solid ${theme.colors.secondaryGreen}`}
            size="sm"
            borderRadius="20px"
            _hover={{
              transform: "scale(1.02)",
              color: "white",
              bg: `${theme.colors.secondaryGreen}`,
            }}
            style={{
              backgroundColor: beerBrands.includes(brandOption) ? `${theme.colors.secondaryGreen}` : "transparent",
              color: beerBrands.includes(brandOption) ? "white" : `${theme.colors.secondaryGreen}`,
            }}beerBrands
          >
            {brandOption}
          </Button>
        )
      })}
    </Grid>
    </>
  );
}

import {
  Box,
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useState, useReducer, useEffect } from "react";
import { api } from "../../../services/api";
import { Select } from '@chakra-ui/react';
import { theme } from "../../../styles/theme";
import { useAuth } from "../../../Hooks/useAuth";
import { EditCoupon } from "../EditCoupon";
import swal  from 'sweetalert';
import { FaPlus } from "react-icons/fa";

export function CouponCard() {
  const { user } = useAuth();
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      redemption_limit: "",
      valid_until: "",
      kind: "",
      code: "",
      discount: "",

    }
  );
  const [toggleCouponCreation, setToggleProductCreation] = useState(false);

  async function handleFetchCoupons() {
    const apiUrl = `/api/v1/admin/coupons`;
    try {
      const response = await api.get(apiUrl, {
        headers: {
          "access-token": user?.token,
          client: user?.client,
          uid: user?.uid,
          accept: "application/json",
          contentType: "multipart/form-data"
        },
      });

      setCoupons(response.data.coupons);

    } catch (error) {
      console.error("Error fetching batch orders:", error);
    }
  }

  const handleCouponChange = (e) => {
    const { name, value } = e.target;
    setCoupon({ [name]: value });
  }

  async function handleCreateCoupon(e) {
    e.preventDefault();
    if (coupon.redemption_limit === "" || coupon.valid_until === "" || coupon.kind === "" || coupon.code === "" || coupon.discount === "") {
      swal({
        title: "Ops",
        text: "Preencha todos os dados obrigatórios.",
        icon: "info",
      });
      return
    }
    const formData = new FormData()
    formData.append("coupon[redemption_limit]", coupon.redemption_limit);
    formData.append("coupon[valid_until]", coupon.valid_until);
    formData.append("coupon[kind]", coupon.kind);
    formData.append("coupon[code]", coupon.code);
    formData.append("coupon[discount]", coupon.discount);
    try {
      const response = await api.post(`/api/v1/admin/coupons`, formData, {
        headers: {
          "access-token": user.token,
          client: user.client,
          uid: user.uid,
          accept: "application/json",
          contentType: "multipart/form-data"
        },
      });
      if (response.status === 200) {
        swal({
          title: "Ok",
          text: "Cupom criado",
          icon: "success",
        });
        handleFetchCoupons()
        handleToggleShowCreationOfProduct();
      } else {
        swal({
          title: "Oh No",
          text: "Algo deu errado!",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error creating product:", error);
    }
  }



  async function toggleCouponActivitty(e, couponId) {
    e.preventDefault();
    try {
      const response = await api.delete(`/api/v1/admin/coupons/${couponId}`);

      if (response.success) {
        swal({
          title: "DESATIVADO",
          text: `${response.data.message}`,
          icon: "success",
        });

      } else {
        swal({
          title: "ATIVADO",
          text: `${response.data.message}`,
          icon: "success",
        });
      }
      handleFetchCoupons()
    } catch (error) {
      console.error("Error creating product:", error);
      swal({
        title: "Opa",
        text: "Algo deu errado!",
        icon: "error",
      });
    }
  }



  const updateCoupons = (updatedCoupon) => {
    const updatedCoupons = coupons.map((coupon) =>
      coupon.id === updatedCoupon.id ? updatedCoupon : coupon
    );
    setCoupons(updatedCoupons);
  };

  const handleToggleShowCreationOfProduct = () => {
    setToggleProductCreation(!toggleCouponCreation);
  }

  useEffect(() => {
    handleFetchCoupons();
  }, []);



  return (
    <>
      <Button
        type="button"
        onClick={handleToggleShowCreationOfProduct}
        background="transprent"
        _hover={{ bg: "transparent", color: `${theme.colors.primary}` }}
      >
        <FaPlus></FaPlus> Criar cupom
      </Button>
      {toggleCouponCreation && (
        <Box>
          <Box
            margin="20px 0"
          >
            <Input
              placeholder="Código do cupom"
              onChange={(e) => handleCouponChange(e)}
              name="code"
            />
          </Box>

          <Box
            display="flex"
            gap={8}
            margin="20px 0"
          >
            <Input
              placeholder="Válido até"
              onChange={(e) => handleCouponChange(e)}
              name="valid_until"
              type='datetime-local'
            />
            <Input
              placeholder="Quantidade de vezes que pode ser usado"
              type={"number"}
              onChange={(e) => handleCouponChange(e)}
              name="redemption_limit"
            />
          </Box>
          <Box display="flex" gap={8} margin="20px 0">
            <Select
              placeholder='Selecione o tipo do desconto'
              onChange={(e) => handleCouponChange(e)}
              name="kind"
            >
              <option value=''>
              </option>
              <option value='fixed'>
                Fixo
              </option>
              <option value='percentage'>
                Porcentagem
              </option>
            </Select>
            <Input
              placeholder="Valor do desconto"
              onChange={(e) => handleCouponChange(e)}
              name="discount"
              type={"number"}
            />
          </Box>
          <Button
            background={theme.colors.green500}
            color={theme.colors.white500}
            onClick={(e) => handleCreateCoupon(e)}
            marginTop="30px"
            _hover={{
              opacity: 0.8,
            }}
          >
            Cadastrar cupom
          </Button>
        </Box>
      )}


      <Box marginTop="30px">
        <Text textAlign="center" marginBottom="20px" fontSize="28px" fontWeight="bolder">Cupons</Text>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>#Id</Th>
                <Th>Código</Th>
                <Th>Válido até</Th>
                <Th>Limite de uso</Th>
                <Th>Restantes</Th>
                <Th>Tipo</Th>
                <Th>Valor</Th>
                <Th>Editar cupom</Th>
                <Th>Desativar</Th>
              </Tr>
            </Thead>
            <Tbody>
            {coupons?.map((coupon) => {
              return (
                <Tr
                  key={coupon.id}
                  style={{ backgroundColor: coupon.deactivated_at ? 'rgba(255, 110, 102, .5)' : '#FFF6EF' }}
                >
                  <Td>{coupon?.id}</Td>
                  <Td>{coupon?.code}</Td>
                  <Td>
                    {new Date(coupon.valid_until).toLocaleString('pt-BR', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour12: false,
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric'
                    })}
                  </Td>
                  <Td>{coupon.redemption_limit}</Td>
                  <Td>{coupon.redemption_limit - coupon.usage_count}</Td>

                  {coupon.kind === 'fixed' ? <Td>Fixo</Td> : <Td>Porcentagem</Td>}
                  {coupon.kind === 'fixed' ? <Td>{coupon.discount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Td> : <Td>{coupon.discount}%</Td>}
                  <Td>
                    <EditCoupon
                      couponId={coupon.id}
                      onUpdateCoupon={updateCoupons}
                      thisCoupon={coupon}
                      handleFetchCoupons={handleFetchCoupons}
                    />
                  </Td>
                  <Td>
                    {coupon.deactivated_at ? (
                      <Button
                        onClick={(e) => toggleCouponActivitty(e, coupon.id)}
                        bg='green'
                      >
                        Reativar
                      </Button>
                    ) : (
                      <Button
                        onClick={(e) => toggleCouponActivitty(e, coupon.id)}
                        bg='red'
                      >
                        Desativar
                      </Button>
                    )}
                  </Td>
                </Tr>
              )
            })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

import React, { useState,useEffect } from 'react';
import {
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";


export function AgeVerification() {
  const [showAgeVerification, setShowAgeVerification] = useState(true);

  useEffect(() => {
    const showPopupFunction = () => {
      setShowAgeVerification(true);
      localStorage.setItem('lastVerifiedAge', new Date().getTime());
      const hasConfirmedAge = localStorage.getItem('hasConfirmedAge');

      if (hasConfirmedAge === 'true') {
        setShowAgeVerification(false); // Don't show the popup if the user has already confirmed their age
      }
    };

    showPopupFunction();

    const popupInterval = setInterval(() => {
      showPopupFunction();
      localStorage.setItem('hasConfirmedAge', 'false');
    }, 60 * 60 * 1000);

    return () => {
      clearInterval(popupInterval);
    };
  }, []);



  const handleYesClick = () => {
    localStorage.setItem('hasConfirmedAge', 'true');
    setShowAgeVerification(false);
  }

  const handleNoClick = () => {
    window.location.href = "https://www.itambe.com.br/";
  }


  return (
    <>
      {showAgeVerification && (
        <Box zIndex='10000'>
          <Box
            className='bg-static'
            position={"fixed"}
            top={"0"}
            left={"0"}
            zIndex={"9998"}
            width={"100vw"}
            height={"100%"}
            background={"rgba(0, 0, 0, 0.7)"}
            over='true'
          ></Box>
          <Box
            className='age-verification-popup'
            width={{ base: '90%', md: '30%' }}
            position={"fixed"}
            top={{ base: '40%', md: '45%' }}
            left={{ base: '5%', md: '35%' }}
            zIndex={"9999"}
            background={theme.colors.gray}
            borderRadius="20px"
            height={"13rem"}
            padding={"10px"}
          >
            <Text
              textAlign={"center"}
              fontSize={"28px"}
              margin={"5%"}
              fontWeight={"bolder"}
            >
              Você tem mais de 18 anos?
            </Text>
            <Box
              display={"flex"}
              gap={"10px"}
              justifyContent={"center"}
            >
              <Button
                background={theme.colors.secondaryGreen}
                padding={"15px"}
                height={"25px"}
                borderColor={theme.colors.secondaryGreen}
                width={"25%"}
                borderRadius="20px"
                border={`1px solid ${theme.colors.secondaryGreen}`}
                color={theme.colors.gray}
                _hover={{
                  background: "transparent",
                  color: `${theme.colors.secondaryGreen}`
                }}
                onClick={handleYesClick}
              >
                SIM
              </Button>
              <Button
                background={"red"}
                padding={"15px"}
                height={"25px"}
                width={"25%"}
                borderRadius="20px"
                border={"1px solid red"}
                color={theme.colors.gray}
                _hover={{
                  background: "transparent",
                  color: "red",
                }}
                onClick={handleNoClick}
              >
                NÃO
              </Button>
            </Box>
          </Box>
        </Box>
      )}

    </>
  );
}

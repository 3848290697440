import { createContext, useState } from "react";
export const CityContext = createContext({});

export function CityContextProvider({children}) {
  const [cityName, setCityName] = useState(() => localStorage.getItem('cityName') || null);
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [cep, setCep] = useState(() => localStorage.getItem('CEP') || '');

  const handleSetCity = (city) => {
    setCityName(city);
    localStorage.setItem('cityName', city);
  };

  const fetchCEP = async (CEP) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${CEP}/json/`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      if (
        data.localidade !== 'Belo Horizonte' &&
        data.localidade !== 'Nova Lima' &&
        data.localidade !== 'Contagem' &&
        data.localidade !== 'Betim' &&
        data.localidade !== 'Lagoa Santa'
      ) {
        alert('Entregas somente para BH e região');
        console.log("FORA DA ZONA", data)
        setDeliveryAddress({});
        return;
      }
      setDeliveryAddress(data);
      setCityName(data.localidade);  // Update cityName based on fetched data
      setCep(CEP);
      localStorage.setItem('cityName', data.localidade);
      localStorage.setItem('CEP', CEP);
      console.log(data);

    } catch (error) {
      console.log(error);

      alert(error)
      setDeliveryAddress({});
    }
  };



  return (
    <CityContext.Provider value={{ cityName, handleSetCity, fetchCEP, deliveryAddress, cep  }}>
      {children}
    </CityContext.Provider>
  );
}

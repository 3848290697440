import { Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, Checkbox } from "@chakra-ui/react";
import { useState  } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { BatchCardInfo } from "../BatchCardInfo";
import { EditBatch } from "../EditBatch";
import swal  from 'sweetalert';
import { format } from "date-fns";


export function BatchCard({ productId, productName, allBatches, allPrices, productBatch}) {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openBatches, setOpenBatches] = useState(allBatches);
  const [userIsInsertingAddress, setUserIsInsertingAddress] = useState(false);
  const [CEP, setCEP] = useState("");
  const [batchAmount, setBatchAmount] = useState(0);
  const [batchDiscount, setBatchDiscount] = useState(0);
  const [batchStartDate, setBatchStartDate] = useState("");
  const [batchExpirationDate, setBatchExpirationDate] = useState("");
  const [batchUnityOfMeasurement, setBatchUnityOfMeasurement] = useState("");
  const [address, setAddress] = useState({});
  const [addressNumber, setAddressNumber] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [batch, setBatch] = useState(productBatch);
  const [prices, setPrices] = useState(allPrices);

  async function fetchCEP(e) {
    e.preventDefault();
    const response = await fetch(`https://viacep.com.br/ws/${CEP}/json/`);
    const data = await response.json();
    setAddress(data);
  }

  async function handleBatchCreation(e) {
    e.preventDefault();
    const data = {
      batch: {
        stock: batchAmount,
        discount_per_centage: batchDiscount,
        start_date: batchStartDate,
        expiration_date: batchExpirationDate,
        product_id: productId,
        collection_address_id: null,
        unity_of_measurement: batchUnityOfMeasurement
      },
      collection_address: {
        address_street: address.logradouro,
        address_number: addressNumber,
        address_complement: addressComplement,
        address_neighborhood: address.bairro,
        city_id: address.localidade,
        state_id: address.uf,
        address_postal_code: address.cep
      }
    }

    const response = await api.post(`/api/v1/batches/create_batch_and_collection_address`, data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
      },
    })
    if (response.status === 200) {
      openBatches.push(response.data)
      swal({
        title: "Ok",
        text: "Batch criado",
        icon: "success",
      });
    } else {
      swal({
        title: "Opss",
        text: "Algo deu errado!!",
        icon: "error",
      });
    }
    onClose()
  }

  async function handleGetPreviousBatchInfos(e) {
    e.preventDefault();
    if (e.target.checked) {
      const response = await api.get(`/api/v1/admin/panels/get_previous_batch_infos?product_id=${productId}`, {
        headers: {
          "access-token": user.token,
          client: user.client,
          uid: user.uid,
          accept: "application/json",
        },
      })
      if (response.data.message) {
        alert(response.data.message)
        const checkbox = document.getElementById('previousBatchInfo');
        checkbox.checked = false;
        return
      }
      setCEP(response.data.address.address_postal_code.replace(/-/g, ''))
      const cepResponse = await fetch(`https://viacep.com.br/ws/${response.data.address.address_postal_code.replace(/-/g, '')}/json/`);
      const data = await cepResponse.json();
      setAddress(data);
      setUserIsInsertingAddress(true)
      setBatchAmount(response.data.batch.stock)
      setBatchDiscount(response.data.batch.discount_per_centage)
      setBatchUnityOfMeasurement(response.data.batch.unity_of_measurement)
      document.getElementById('batchStock').value = response.data.batch.stock
      document.getElementById('batchDiscount').value = response.data.batch.discount_per_centage
      document.getElementById('batchUnity').value = response.data.batch.unity_of_measurement
    } else {
      setUserIsInsertingAddress(false)
      setBatchAmount("")
      setBatchDiscount("")
      setBatchUnityOfMeasurement("")
      document.getElementById('batchStock').value = ""
      document.getElementById('batchDiscount').value = ""
      document.getElementById('batchUnity').value = ""
      document.getElementById('addressCep').value = ""
      setCEP("")
      setAddress({})
      setAddressNumber("")
      setAddressComplement("")
    }
  }

  const updateBatch = (updatedBatch) => {
    const updatedBatches = openBatches.map((batch) =>
      batch.id === updatedBatch.id ? updatedBatch : batch
    );
    setOpenBatches(updatedBatches);
  };

  const today = new Date();

  const foundBatch = openBatches?.find(batch => {
    return batch.product_id === productId &&
      // new Date(batch.start_date) <= today &&
      new Date(batch.expiration_date) >= today;
  });

  return (
    <>
      {foundBatch ? (<>
        {new Date(foundBatch.start_date) > today ? (<Button onClick={onOpen} bg='#7adcef'>Abrirá {format(new Date(foundBatch.start_date), 'dd-MM-yyyy HH:mm:ss')}</Button>) : (<Button onClick={onOpen} bg='#18a24d'>Ver lote aberto</Button>)}
      </>) : (
        <Button onClick={onOpen} bg='#ee4436'>Gerar lote</Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lote {productName} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {foundBatch ? (
              <>
                <BatchCardInfo
                  batch={foundBatch}
                  productId={productId}
                  prices={prices}
                />
              </>
            ) : (
              <>
                <Checkbox
                  colorScheme='green'
                  value="collect"
                  onChange={(e) => handleGetPreviousBatchInfos(e)}
                  id="previousBatchInfo"
                >
                  Usar infos do lote anterior
                </Checkbox>
                <Box
                  display="flex"
                  gap={8}
                >
                  <Input
                    margin="10px 0"
                    placeholder="Estoque"
                    type="number"
                    id="batchStock"
                    onChange={(e) => setBatchAmount(e.target.value)}
                  />
                  <Input
                    margin="10px 0"
                    placeholder="Desconto"
                    type="number"
                    id="batchDiscount"
                    onChange={(e) => setBatchDiscount(e.target.value)}
                  />
                </Box>
                <Box>
                  <Text>Data de início do lote</Text>
                  <Input
                    margin="10px 0"
                    placeholder="Início de validade do lote"
                    type="date"
                    onChange={(e) => {setBatchStartDate(e.target.value)}}
                  />
                  <Text>Data de expiração do lote</Text>
                  <Input
                    margin="10px 0"
                    placeholder="Fim de validade do lote"
                    type="date"
                    onChange={(e) => {setBatchExpirationDate(e.target.value)}}
                  />
                  <Select
                    margin="10px 0"
                    placeholder="Escolha Unidade"
                    type="date"
                    id="batchUnity"
                    onChange={(e) => {setBatchUnityOfMeasurement(e.target.value)}}
                  >
                    <option value=""></option>
                    <option value="Caixa">Caixa</option>
                    <option value="Fardo">Fardo</option>
                    <option value="Pacote">Pacote</option>
                    <option value="Unidade">Unidade</option>
                  </Select>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                >
                  <Button
                    margin="10px 0"
                    width="100%"
                    onClick={(e) => setUserIsInsertingAddress(!userIsInsertingAddress)}
                  >
                    Inserir endereço de retirada
                  </Button>
                  {userIsInsertingAddress && (
                    <Box
                      margin="10px 0"
                    >
                      <Box
                        display="flex"
                        gap={8}
                      >
                        <Input
                          placeholder="Digite o CEP"
                          type="number"
                          id="addressCep"
                          value={CEP}
                          onChange={(e) => setCEP(e.target.value)}
                        />
                        <Button
                          onClick={(e) => fetchCEP(e)}
                        >Buscar</Button>
                      </Box>
                      <Box
                        margin="10px 0"
                        display="flex"
                        gap={8}
                      >
                        <Input
                          placeholder="Rua"
                          value={address.logradouro}
                          disabled
                        />
                        <Input
                          placeholder="Número"
                          type="number"
                          onChange={(e) => setAddressNumber(e.target.value)}
                        />
                      </Box>
                      <Box
                        margin="10px 0"
                        display="flex"
                        gap={8}
                      >
                        <Input
                          placeholder="Complemento"
                          onChange={(e) => setAddressComplement(e.target.value)}
                        />
                        <Input
                          placeholder="Bairro"
                          value={address.bairro}
                          disabled
                        />
                      </Box>
                      <Box
                        margin="10px 0"
                        display="flex"
                        gap={8}
                      >
                        <Input
                          placeholder="Cidade"
                          value={address.localidade}
                          disabled
                        />
                        <Input
                          placeholder="Estado"
                          value={address.uf}
                          disabled
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Fechar
            </Button>
            {!foundBatch && (
              <Button
                colorScheme="green"
                onClick={(e) => handleBatchCreation(e)}
              >
                Cadastrar lote
              </Button>
            ) }
            {foundBatch && (
              <EditBatch
                productId={productId}
                productBatch={batch}
                onUpdateBatch={updateBatch}
              />
            ) }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

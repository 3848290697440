import {
  Button,
  Image,
  Text,
  Box,
  Stat,
  StatHelpText,
  StatArrow,
  Input,
  Divider
} from "@chakra-ui/react";
import { useParams, useLocation  } from "react-router-dom";
import { useContext, useState } from "react";
import { BsDash, BsPlus } from 'react-icons/bs'
import { theme } from "../../styles/theme";
import swal  from 'sweetalert';
import { CartContext } from "../../Context/CartContext";
import { AddToCartPopUp } from "../AddToCartPopUp";
import { RiShoppingBasket2Line} from "react-icons/ri";

export function ProductCard({
  product,
  id,
  name,
  brand,
  marketPrice,
  prices,
  batch,
  productPhotoUrl,
  suggestedPriceForSale,
  quantityPerBox,
  size,
  // productTypeId,
  // productTypes,
  // carouselType
  description,
  quantity_per_box,
  productType,
  carouselType
}) {

  const { id: productId } = useParams()
  const [quantity, setQuantity] = useState(1)
  const { showCartPopUp, setShowCartPopUp, handleSetSelectedProduct } = useContext(CartContext)
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  const { min, max } = (prices || []).reduce((acc, cur) => {
    if (cur.price_per_unity < acc.min) {
      acc.min = cur.price_per_unity;
    }
    if (cur.price_per_unity > acc.max) {
      acc.max = cur.price_per_unity;
    }
    return acc;
  }, { min: Infinity, max: -Infinity }) || {};


  const shareProductLink = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(e.target.dataset.copyText).then((error) => {
      swal({
        title: "👍",
        text: "Link copiado!",
        icon: "success",
      });
    })
  }

  function handleIncrement(e) {
    e.preventDefault()
    setQuantity(quantity + 1)
  }

  function handleDecrement(e) {
    e.preventDefault()
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  function finalPrice() {
    if (!id || prices?.length === 0) {
      return 0;
    }
    const sortedPrices = prices?.sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    const calculatedPrice = selectedPrice.price_per_unity * quantity * quantityPerBox;
    return calculatedPrice.toFixed(2);
  }

  const minUnity = prices.reduce((min, current) => (current.unity < min ? current.unity : min), prices[0]?.unity);
  return (<>
    <Box
      direction={{ base: 'column', sm: 'column' }}
      bg="white"
      borderRadius="10px"
      border="none"
      height="100%"
      minHeight="450px"
      width={{ base: '100%', md: '100%' }}
      marginBottom={{ base: '20px', md: '0' }}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      position="relative"
      transition="transform 0.2s"
      boxShadow="rgba(0, 0, 0, 0.25) 0px 3px 9px;"
      _hover={{
        transform: "scale(1.02)"
      }}
    >
      <Image
        objectFit='cover'
        width={{ base: 'auto', md: '' }}
        src={productPhotoUrl || require('../../assets/images/placeholder.webp')}
        alt='Boozen'
        borderRadius='10px 10px 0px 0px'
        border={"none"}
        height='11rem'
        loading="lazy"

        position="relative"
      />
      {carouselType === "Descontos" && (
        <Box
          position="absolute"
          top="15px"
          right="15px"
          width="70px"
          height="25px"
          bg="orange"
          borderRadius="15px"
          fontSize="14px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="500"
          color="white"
        >
          {batch.discount_per_centage}% OFF
        </Box>
      )}


      <Box
        padding="0px 15px 5px 15px"
        flex="1"  // Allow this section to take available space
        display="flex"
        flexDirection="column"
        justifyContent="space-between"  // Align children to start
      >
        {/* Product Details */}
        <Text fontWeight='bold' fontSize="13px" marginTop='10px'>{name}</Text>
        <Text fontWeight='350' fontSize="13px">{brand}</Text>

        {/* Price Section */}
        <Box display='flex' gap='5px' alignItems='center'>
          <Text fontSize={{base: '12px', md: '14px'}} fontWeight='350'>
            Preço no mercado:
          </Text>
          <Text fontWeight='350' fontSize={{base: '12px', md: '14px'}} textDecoration='line-through'>
            {marketPrice?.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}
          </Text>
        </Box>

        {/* Discount */}
        <Box display={{ base: '', md: 'flex' }} justifyContent='space-between' alignItems='center'>
          <Stat marginBottom='0px'>
            <StatHelpText marginBottom='0px' display="flex" alignItems='center'>
              <StatArrow color="green" type='decrease' />
              <span>{batch && batch.discount_per_centage}%</span>
              <Text display={{ base: 'none', md: 'block' }} marginLeft='4px'>
                desconto na
              </Text>
              <Text marginLeft="5px" color={theme.colors.primary} fontWeight='bold' translate="no">
                BOO
              </Text>
              <Text translate="no" color={theme.colors.primaryGreen} fontWeight='bold'>
                ZEN
              </Text>
            </StatHelpText>
          </Stat>
        </Box>

        {/* Price Range */}
        <Text color={theme.colors.secondaryGreen} fontWeight='bold' fontSize={{ base: '13px', md: '' }}>
          De {(min).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})} a {max.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}
        </Text>

        {/* Quantity */}
        <Text fontWeight='350' fontSize={{ base: '11px', md: '13px' }}>
          Mínimo: {quantityPerBox * minUnity} {size ? <span>{size}</span> : <span></span>}
          {quantityPerBox > 1 && size ? <span>s</span> : <span></span>}
        </Text>

        {/* Suggested Price */}
        {suggestedPriceForSale && (
          <Box display="flex" gap="5px">
            <Text textAlign={{ base: 'start', md: 'start' }} fontWeight='350' fontSize={{ base: '11px', md: '13px' }}>
              Sugestão de preço de revenda: R${suggestedPriceForSale.toFixed(2)}
            </Text>
          </Box>
        )}
      </Box>

      {/* Footer: Quantity and Total */}
      <Box padding="0px 15px 15px 15px" display={{ base: "block", md: "block" }} alignItems="center" justifyContent="space-between" >
        <Box
          display="flex"
          marginTop={{ base: '10px', md: '10px' }}
          width={{ base: "100%", md: "100%" }}
          marginBottom="0"
          alignItems="center"
          border="2px solid gray"
          borderRadius="50px"
          height="30px"
          // padding="0 10px 0 10px"
        >
          <BsDash size={30} weight="fill" cursor="pointer" id="button-decrement" onClick={(e) => handleDecrement(e)} />
          <Input
            margin="0px"
            type="number"
            width={{ base: '', md: '' }}
            padding="0%"
            background="transparent"
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            fontWeight="bold"
            textAlign="center"
            border="none"
            value={quantity}
            readOnly={true}
          />
          <BsPlus size={30} weight="fill" cursor="pointer" margin="auto" id="button-increment" onClick={(e) => handleIncrement(e)} />
        </Box>

        <Box mb={2} display="flex" justifyContent="space-between" gap="10px" alignItems="center" mt={3}>
          <Box display="flex" justifyContent="start" gap="5px" alignItems="center">
            <Text color={theme.colors.secondaryGreen} fontWeight='bold'>Total:</Text>
            <Text color={theme.colors.secondaryGreen} fontWeight='bold'>{finalPrice()}</Text>
          </Box>
          <Button
            width="30px"
            background="transparent"
            color="green"
            height="30px"
            border={`2px solid ${theme.colors.secondaryGreen}`}
            size="sm"
            padding="0px"
            borderRadius="50%"
            fontWeight="500"
            _hover={{
              background: `${theme.colors.secondaryGreen}`,
              color: "white"
            }}
            onClick={(e) => handleSetSelectedProduct(e, product, batch, finalPrice(), quantity)}
          >
            <RiShoppingBasket2Line fontWeight="bold"/>
          </Button>
        </Box>
      </Box>
    </Box>
  </>)
}

import { Box, Button, Image, Input, Text, Checkbox } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import logo from '../../assets/images/logo-boozen.png';
import { useState, useEffect, useContext } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { Link } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { api } from "../../services/api";
import swal  from 'sweetalert';
import { FaHome } from "react-icons/fa";
import { CartContext } from "../../Context/CartContext";


export const userToken = () => window.localStorage.getItem("@app/token");
export function SignIn() {
  const { signInUser, getUserInfo   } = useAuth();
  const [userId, setUserId] = useState("");
  const isAuthenticated = () => userToken() !== null;
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const { handleCreateCart } = useContext(CartContext)


  function handleUserLogin(e) {
    e.preventDefault();
    signInUser(userEmail, userPassword, userId)
  }

  function handleTogglePasswordVisibility(e) {
    e.preventDefault();
    const passwordInput = document.getElementById('password-input')
    if (e.target.checked) {
      passwordInput.type = "text"
    } else {
      passwordInput.type = "password"
    }
  }

  async function handleCallbackResponse(response) {
    let userObject = jwtDecode(response.credential);
    localStorage.setItem('userPhoto', userObject.picture);
    signInUserWithGoogleEmail(response.credential)
  }

  async function signInUserWithGoogleEmail(token) {
    const response = await api.get(`/api/v1/users/sign_in_with_google?id_token=${token}`);
    if (response.status === 200) {
      window.localStorage.setItem("@app/token", response.data.tokens["access-token"]);
      window.localStorage.setItem("@app/uid", response.data.tokens["uid"]);
      window.localStorage.setItem("@app/client", response.data.tokens["client"]);
      window.localStorage.setItem("@app/expiry", response.data.tokens["expiry"]);
      await getUserInfo(response.data.user);
      window.location.href = "/carrinho";
      if (typeof window !== "undefined") {
        if (window.fbq != null) {
          window.fbq('trackCustom', 'signInUserWithGoogleEmail');
        }
      }
    } else {
      swal({
        title: "Ops",
        text: "Ocorreu um erro no seu login, confira seus dados",
        icon: "error",
      });
    }
  }

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: "480318445952-v2iilvljfmm9jk2l9c1ldi66fkjfh06f.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      {theme: "outline", size: "large"}
    )
  }, []);

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxShadow="rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"
      position='relative'

    >
      <Box
        width={{base: '95%', md: '30%'}}
        padding={{base: '3', md: '70px 0px'}}
        borderRadius={8}
        display="flex"
        flexDirection="column"
        justifyContent='center'
        background="white"
        alignItems="center"
        boxShadow="rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"
        position='relative'
        backgroundImage={require('../../assets/images/background.png')}
        backgroundSize={{base: "cover", md: "cover"}}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
      >
        <Link to={'/'}
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            textDecoration: 'none',
            transition: 'color 0.3s ease',
            fontSize: '20px',
            color: '#49b82d'
          }}
        >
          <FaHome></FaHome>
        </Link>
        <Image
          borderRadius='15px 0px 0px 15px'
          objectFit='contain'
          src={logo}
          width="50%"
          display={{base: 'none', md: 'block'}}
        />
        <Box
          display="flex"
          flexDirection="column"
          padding={{base: '0px', md: ''}}
          width={{base: '100%', md: '70%'}}
        >
          <Link to={'/'}>
            <Image
              objectFit='cover'
              maxW={{ base: '200px', md: '300px' }}
              src={logo}
              margin="20px auto"
              display={{base: 'block', md: 'none'}}
            />
          </Link>
          <Text
            fontSize={{base: '24px', md: '32px'}}
            color={theme.colors.primaryOrange}
            textAlign={"center"}
            fontWeight='bolder'
            marginTop="10%"
          >
            Login
          </Text>
          <form onSubmit={(e) => handleUserLogin(e)}>
            <Input
              placeholder="E-mail"
              margin="20px 0"
              onChange={(e) => setUserEmail(e.target.value)}
              borderColor={theme.colors.lightGray}
              _focus={{
                outline: 'none',
                boxShadow: 'none',
                borderColor: theme.colors.primary,
              }}
              autoComplete="current-email"
            />
            <Input
              id="password-input"
              placeholder="Senha"
              type="password"
              onChange={(e) => setUserPassword(e.target.value)}
              borderColor={theme.colors.lightGray}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUserLogin(e);
                }
              }}
              _focus={{
                outline: 'none',
                boxShadow: 'none',
                borderColor: theme.colors.primary,
              }}
              autoComplete="current-password"

            />
            <Checkbox onChange={(e) => handleTogglePasswordVisibility(e)} textAlign={"left"} marginTop={"5px"}>Mostrar senha?</Checkbox>
            <Box
              marginTop="30px"
              display={"flex"}
              justifyContent={"space-between"}
              gap={10}
            >
              <Button
                background={theme.colors.secondaryGreen}
                color={theme.colors.white}
                border='1px solid #49b82d'
                borderRadius="20px"
                type='submit'
                _hover={{
                  background: `transparent`,
                  opacity: 0.8,
                  color: theme.colors.secondaryGreen,
                  borderColor: theme.colors.secondaryGreen
                }}
                onClick={(e) => handleUserLogin(e)}
              >
                Entrar
              </Button>
            </Box>
          </form>
          <Box margin={{base: '30px 0px', md: '30px 0px'}}>
            <div id="signInDiv"></div>
          </Box>
          <Box
            display={{base: 'flex', md: 'flex'}}
            gap="10px"
          >
            <Link to='/sign_up'
              style={{ textDecoration: 'none' }}
            >
              <Button
                bg={"transparent"}
                color={theme.colors.logoOrange}
                padding={{base: '0px', md: '0px'}}
                marginTop={{base: '0px', md: '0px'}}
                _hover={{
                  color: theme.colors.secondaryGreen,
                }}
                size={'md'}
                type="submit"
              >
                Criar Conta
              </Button>
            </Link>
            <Link to='/recuperar_senha'
              style={{ textDecoration: 'none' }}
            >
              <Button
                bg={"transparent"}
                color={theme.colors.logoOrange}
                _hover={{
                  color: theme.colors.secondaryGreen,
                }}
                padding={{base: '0px', md: '0px'}}
                size={'md'}

              >
                Esqueceu a senha?
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

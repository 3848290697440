import { api } from '../services/api'; // Import your API module
import swal  from 'sweetalert';

async function finishCustomerOrder(
  user,
  cartBatches,
  useCredit,
  userCredits,
  deliveryMethod,
  paymentMethod,
  paymentObservations,
  deliveryAddress,
  deliveryAddressNumber,
  deliveryAddressComplement,
  couponDiscount,
  couponKind,
  validCoupon,
  products,
  batches
) {
  if (window.confirm("Tem certeza de que deseja continuar?")) {
    const dataObjectPayment = new FormData();
    dataObjectPayment.append('payment[created_at]', new Date());
    dataObjectPayment.append('payment[updated_at]', new Date());
    dataObjectPayment.append('payment[user_id]', user.id);
    dataObjectPayment.append('payment[payment_method]', paymentMethod);
    dataObjectPayment.append('payment[observations]', paymentObservations);
    if (couponDiscount > 0) {
      dataObjectPayment.append('payment[coupon_id]', validCoupon.coupon_id);
    }
    const paymentResponse = await api.post('/api/v1/payments', dataObjectPayment);
    const totalAmount = cartBatches.reduce((n, { amount }) => n + amount, 0);
    let firstPurchaseDiscount = 0;
    const firstPayment  = paymentResponse.data.first_payment;
    let creditDiscountAmountPerBatch = 0;
    let couponDiscountAmountPerBatch = 0;
    let firstPurchaseDiscountPerBatch = 0
    let totalCredits = 0;
    let deliveryFeePerBatch = 0;
    // let deliveryFee = 0;
    // let couponDiscountAmount = 0
    // debugger

    if (firstPayment) {
      firstPurchaseDiscount = totalAmount * 0.05;
      firstPurchaseDiscountPerBatch = firstPurchaseDiscount / cartBatches.length; // Apply 5% firstPurchaseDiscount
    }

    if (useCredit) {
      totalCredits = userCredits.reduce((n, { amount }) => n + amount, 0);
      creditDiscountAmountPerBatch = totalCredits / cartBatches.length;
      const dataObjectPayment = new FormData();
      dataObjectPayment.append('credit[payment_id]', paymentResponse.data.payment.id);
      dataObjectPayment.append('credit[amount]', -totalCredits);
      dataObjectPayment.append('credit[user_id]', user.id);
      const creditResponse = await api.post('/api/v1/credits', dataObjectPayment);
    }

    if (totalAmount.toFixed(2) < 120.00 && deliveryMethod === 'delivery') {
      deliveryFeePerBatch = 6.90 / cartBatches.length;
    }

    if (couponDiscount > 0 && couponKind === 'fixed') {
      couponDiscountAmountPerBatch = couponDiscount / cartBatches.length;
    } else if (couponDiscount > 0 && couponKind === 'percentage') {
      couponDiscountAmountPerBatch = ((totalAmount * couponDiscount) / 100) / cartBatches.length;
    }

    const promises = cartBatches.map(async (cartBatch, index) => {
      const cartBatchId = cartBatch.id;
      const matchingBatch = batches.find(batch => batch.id === cartBatchId);
      if (!matchingBatch) {
        console.log(`No batch found with ID: ${cartBatchId}`);
      }
      const dataObject = new FormData();
      const batchAmount = cartBatch.amount + deliveryFeePerBatch - couponDiscountAmountPerBatch - creditDiscountAmountPerBatch - firstPurchaseDiscountPerBatch;
      dataObject.append('batch_order[amount]', batchAmount.toFixed(2));
      dataObject.append('batch_order[quantity]', cartBatch.quantity);
      dataObject.append('batch_order[user_id]', user.id);
      dataObject.append('batch_order[batch_id]', cartBatch.batch_id);
      dataObject.append('batch_order[payment_id]', paymentResponse.data.payment.id);
      dataObject.append('batch_order[delivery_method]', deliveryMethod);
      dataObject.append('batch_order[delivery_status]', 'pending');
      if (cartBatchId) {
        dataObject.append('batch_order[cart_batch_id]', cartBatchId);
      }
      if (deliveryMethod === 'delivery') {
        dataObject.append(
          'batch_order[delivery_address]',
          `${deliveryAddress.logradouro}, ${deliveryAddressNumber}, ${deliveryAddressComplement}, ${deliveryAddress.bairro} - ${deliveryAddress.localidade} - ${deliveryAddress.uf} CEP: ${deliveryAddress.cep}`
        );
      }
      const response = await api.post(`/api/v1/cart_batches/finish_my_order`, dataObject);

      return response;
    });

    return Promise.all(promises).then((responses) => {
      if (responses.every((response) => response.data.status === 'created')) {
        return {
          success: true,
          paymentId: paymentResponse.data.payment.id,
          totalPrice: (paymentResponse.data.price_in_cents / 100),
          fisrtPayment: firstPayment,
          couponDiscount: couponDiscount,
          firstDiscount: firstPurchaseDiscount,
        };
      } else {
        return {
          success: false,
          message: 'Algo deu errado',
        };
      }
    });
  }

}

export { finishCustomerOrder };

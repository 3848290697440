import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function DeliveryChart({ orders }) {

  const deliveryOrders = orders.filter(order => order.paid).filter(order => order.delivery_method === "delivery");
  const collectionOrders = orders.filter(order => order.paid).filter(order => order.delivery_method === "collect");

  const optionsDelivery = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tipo de Entrega',
      },
    },
  };

  const dataDeliveries = {
    labels: ['Entrega', 'Coleta'],
    datasets: [
      {
        label: '#nº pedidos ',
        data: [deliveryOrders.length, collectionOrders.length],
        backgroundColor: [
          'rgb(60,255,150)',
          'rgb(100, 149, 237)',

        ],
        borderColor: [
          'rgb(60,255,150)',
          'rgb(100, 149, 237)',
        ],
        borderWidth: 1,
      },
    ],
  };


  return (<>
    <div
      style={{
        width: '100%',
        height: '30rem',
        borderRadius: '15px',
        padding: '20px',
        margin: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
      }}
    >
      <Pie data={dataDeliveries} options={optionsDelivery}  style={{ margin: 'auto', width: "100%", height: "100%" }}/>
    </div>
  </>)
}

import { Box, Button, Image, Input, Text } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { api } from "../../services/api";
import Logo from "../../assets/images/logo.svg";
import { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import swal  from 'sweetalert';

export function EditPassword() {
  const { user, signInUser } = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  async function handlePasswordResetForUser(e) {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search)
    const resetPasswordToken = params.get("reset_password_token")
    const data = {
      user: {
        reset_password_token: resetPasswordToken,
        password: newPassword,
        password_confirmation: newPasswordConfirmation
      }
    };

    try {
      const response = await api.put(`/users/password`, data);

      if (response.data.success) {
        signInUser(response.data.user.email, newPassword, response.data.user.id)
        swal({
          title: "Ok",
          text: "Senha trocada com sucesso!!",
          icon: "success",
        });
      } else {
        swal({
          title: "Oh nooo",
          text: "Algo deu errado!!",
          icon: "error",
        });
      }
    } catch (error) {
      console.error('Error occurred while making the password reset request:', error);
    }
  }

  return (
    <Box
      height="100vh"
      background={theme.colors.background}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        width="100%"
        maxWidth={360}
        padding="8"
        borderRadius={8}
        background={theme.colors.white}
        boxShadow="lg"
        display="flex"
        flexDirection="column"
      >
        <Image
          src={Logo}
          alt="Boozen logo"
        />
        <Text
          fontSize={"24px"}
          color={theme.colors.secondary}
          textAlign={"center"}
        >
          Escolha nova senha.
        </Text>
        <Input
          id="password-input"
          placeholder="Senha"
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          id="password-input-confirmation"
          placeholder="Confirmar Senha"
          type="password"
          marginTop={"20px"}
          onChange={(e) => setNewPasswordConfirmation(e.target.value)}
        />
        <Button
          bg={theme.colors.orange}
          color={theme.colors.white}
          padding={"0 50px"}
          width={"40%"}
          _hover={{
            bg: theme.colors.secondary,
          }}
          size={'md'}
          margin={"20px auto"}
          onClick={(e) => handlePasswordResetForUser(e)}
        >
          Trocar senha
        </Button>
      </Box>
    </Box>
  )
}

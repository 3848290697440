import { Box, Text } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiStarFill } from "react-icons/ri";


export function ReviewsCarousel() {
  const reviews = [
    {
      comment: "A Boozen se destaca pela qualidade excepcional dos produtos e pela excelente relação custo- benefício. A atenção dos detalhes, desde o atendimento,  até a entrega dos produtos , demonstra o compromisso da Boozen, em oferecer o melhor ao cliente.",
      user: "Haroldo Barcelos"
    },
    {
      comment: "Perfeito. O Chopp  chegou como combinado e super saboroso. Gostei demais do vinho, ótimo custo beneficio. Compraria sempre. Minha festa brilhou com o mix de drinks.",
      user: "Grazziane Leonel"
    },
    {
      comment: "A minha experiência com a Boozen foi ótima! Achei o site extremamente facil, os preços do chope sao os mais baratos de BH e as variedades de bebidas prontas é impressionante.",
      user: "Nina Van Riet"
    },
    {
      comment: "Empresa de confiança, tem o chope mais barato de BH e a maior loja de drinks prontos do Brasil. Gostei mt do preço das bebidas e do atendimento de qualidade da equipe. Recomendo!",
      user: "Daniela Chaves"
    },
    {
      comment: "Para iniciar vou falar do atendimento! EXEMPLAR! Isso por si já ganha o cliente. E para além de atendimento, Boozen tem preços excelentes! Super recomendo!",
      user: "Carolina Reis"
    },
    {
      comment: "Empresa espetacular! Ótimos produtos e preços incríveis! Super indico! Parabéns pessoal!",
      user: "Oscar Tessari"
    },
    {
      comment: "Melhor lugar para comprar chopp e drinks prontos!! Ótimo preço e muitas opções",
      user: "Vitor Vasconcellos Castro"
    },
    {
      comment: "Chopp mais barato e de ótima qualidade em bh. Recomendo,  atendimento de excelência!!!",
      user: "Fabiano Miler"
    },
    {
      comment: "Melhor marketplace de bebidas com o melhor custo benefício. Exelente!",
      user: "Ingridh Avelar"
    },
    {
      comment: "Bebidas de alta qualidade + preço MUITO BOM!!!",
      user: "Lorenna Montolli"
    },
    {
      comment: "Excelente atendimento! Preços competitivos, super compensa!",
      user: "Karinne Montolli"
    },
  ]


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px",
          bottom: '-60px'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (<>

    <Box position="relative" width="100%" height="400px" padding="10px 0px 10px 0px">
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <Box
            key={`review-${index}`}
            border="1px solid transparent"
            borderRadius="5px"
            height="300px"
            margin="15px 7px"
            padding="30px 20px"
            boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 10px;"
            alignContent="center"
            textAlign="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="95% !important"
            style={{
              width: "95% !important"
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" gap="3px" mb="10px">
              <RiStarFill color="#ffcf47" />
              <RiStarFill color="#ffcf47" />
              <RiStarFill color="#ffcf47" />
              <RiStarFill color="#ffcf47" />
              <RiStarFill color="#ffcf47" />
            </Box>
            <Text fontSize="16px">{review.comment}</Text>
            <Text fontSize="14px" mt="10px">{review.user}</Text>
          </Box>
        ))}
      </Slider>
    </Box>
  </>);
}

import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useMediaQuery,
  Divider,
  useDisclosure,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import React, { useContext, useState, useRef } from "react";
import Slider from "react-slick";
import { FaClock, FaCheck, FaTruck, FaCreditCard, FaMoneyBill, FaTimes,  FaPlusCircle} from "react-icons/fa";
import { theme } from "../../styles/theme";
import { calculateDeliveryPeriod } from "../../services/deliveryPeriodCalculation";
import { CartContext } from '../../Context/CartContext';


export function OrdersSlider({ orders, cities, states, type }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [touchStartX, setTouchStartX] = useState(null);
  const [isTouching, setIsTouching] = useState(false);
  const carouselRef = useRef(null);

  const { handleSetSelectedProduct } = useContext(CartContext)

  const [activeModalIndex, setActiveModalIndex] = useState(null);
  const currentDate = new Date();

  const handleOpen = (index) => {
    setActiveModalIndex(index);
  };

  const handleClose = () => {
    setActiveModalIndex(null);
  };;

  const handleTouchStart = (e) => {
    setIsTouching(true);
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isTouching) return;
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX;
    const width = carouselRef.current.offsetWidth;
    if (deltaX > 50) {
      handlePrev();
    } else if (deltaX < -50) {
      handleNext();
    }
    setIsTouching(false);
  };


  const itemsPerPageDesktop = 6;
  const itemsPerPageMobile = 1;

  const handlePrev = () => {
    const newIndex = (currentIndex - (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop) + products.length) % products.length;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + (window.innerWidth < 768 ? itemsPerPageMobile : itemsPerPageDesktop)) % products.length;
    setCurrentIndex(newIndex);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px",
          // bottom: '-15px'
          bottom: orders.length > 6 ? (isMobile ? '-60px' : '-15px') : (isMobile ? '-15px' : '-15px'),
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  const isMobile = useMediaQuery("(max-width: 767px)")[0]; // Adjust the breakpoint as needed
  const handlePayment = (e, paymentId) => {
    window.location = `/pagamentos/${paymentId}`
  }


  function getStatusAndDateRangeCollect(expirationDate, status) {
    const expirationDateBatch = new Date(expirationDate);
    const expirationPlus3Days = new Date(expirationDateBatch);
    expirationPlus3Days.setDate(expirationPlus3Days.getDate() + 3);
    const expirationPlus15Days = new Date(expirationDateBatch);
    expirationPlus15Days.setDate(expirationPlus15Days.getDate() + 15);
    if (currentDate >= expirationPlus3Days && currentDate <= expirationPlus15Days) {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaCheck color='#6495ed'></FaCheck>
          <Text fontWeight='500' color='#6495ed'>Coleta disponível</Text>
        </Box>
      );
    } else if (status === "pending") {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaClock color='red'></FaClock>
          <Text fontWeight='500' color='red'>Aguardando disponibilidade</Text>
        </Box>
      )
    } else if (status === "collected") {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaCheck color='green'></FaCheck>
          <Text fontWeight='500' color='green'>Coletado</Text>
        </Box>
      )
    }
  }

  function getStatusAndDateRangeDelivery(status) {
    if (status === 'pending') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaClock color='red'></FaClock>
          <Text fontWeight='500' color='red'>Aguardando disponibilidade</Text>
        </Box>
      );
    } else if (status === 'out_for_delivery') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaTruck color='#6495ed'></FaTruck>
          <Text fontWeight='500' color='#6495ed'>Saiu para entrega</Text>
        </Box>
      )
    } else if (status === 'delivered') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaCheck color='green'></FaCheck>
          <Text fontWeight='500' color='green'>Entregue</Text>
        </Box>
      )
    } else if (status === 'cancelled') {
      return (
        <Box display='flex' justifyContent='start' gap={2} alignItems='center'>
          <FaTimes color='red'></FaTimes>
          <Text fontWeight='500' color='red'>Cancelado</Text>
        </Box>
      )
    }
  }


  return (
    <Box w={{ base: '100%', md: '100%' }} h={{base: "", md: "" }} position="relative" overflow="hidden" margin={"0% 0% 3% 0%"} borderRadius={"40px"}>
      <Slider {...settings}>
        {orders.map((order, index) => (<>
          <Box key={order.payment.id} height="100%">
            <Card position="relative" minHeight="350px" margin="2% 4% 10% 4%" height="" box-shadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px" display="flex" flexDirection="column" >
              <CardHeader mb="0px">
                <Text size='md' fontWeight="800" > Pedido #{order.payment.id}</Text>
                {type !== "orderAgain" && (
                  <>
                    {order.payment.payment_method === "credit_card" && (
                      <Box display='flex' gap={1} alignItems='center' justifyContent='start'>
                        <FaCreditCard size={12}/>
                        <Text color="black" fontSize="12px">Cartão de crédito</Text>
                      </Box>
                    )}
                    {order.payment.payment_method === "pix" && (
                      <Box display='flex' gap={1} alignItems='center' justifyContent='start'>
                        <FaMoneyBill size={12}/>
                        <Text color="black" fontSize="12px">Pix</Text>
                      </Box>
                    )}
                  </>
                )}
              </CardHeader>
              <Button
                onClick={() => handleOpen(index)}
                position="absolute"
                top="0%"
                right="-5%"
                borderRadius="50%"
                bg="transparent"
                color={theme.colors.secondaryGreen}
                // border={`1px solid ${theme.colors.secondaryGreen}`}
                _hover={{
                  transform: "scale(1.3)",
                  background: "transparent"
                }}
              ><FaPlusCircle size={15} color=""/></Button>
              <CardBody flex="1" display="flex" flexDirection="column" overflow="auto">
                {order.batch_details.slice(0, 2).map((batch_order, index) => (
                  <Box key={batch_order.item.id}>
                    {console.log(order)}
                    <Text
                      fontWeight={"200"}
                      fontSize="13px"
                      display="flex"
                      alignItems="flex-start" // Align the text and the dot properly
                    >

                      <span>
                        {isMobile && batch_order.product.name.length > 15
                          ? <span>🔹{batch_order.product.name.slice(0, 15)}...</span>
                          : <span>🔹{batch_order.product.name}</span>
                        }
                      </span>
                    </Text>
                  </Box>
                ))}
                {order.batch_details.length > 2 && (
                  <Text fontSize="13px" color="gray.500">
                    +{order.batch_details.length - 2} item(s)
                  </Text>
                )}
                 <Divider orientation="horizontal" borderColor={theme.colors.darkGray} borderBottomWidth="1px" margin="10px auto" />
                  <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="auto">
                    <Text fontSize="14px" fontWeight="500" textAlign="end" margin="1rem 0rem">
                      Total:
                    </Text>
                    <Text fontSize="14px" fontWeight="500" textAlign="end" margin="1rem 0rem">
                      {(order.payment.price_in_cents / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                    </Text>
                  </Box>
              </CardBody>
              <CardFooter display="flex" flexDirection="column" justifyContent="flex-end" width="100%">
                {type === "orderAgain" &&

                  <Button
                    bg="transparent"
                    color={theme.colors.secondaryGreen}
                    size="xs"
                    borderRadius="20px"
                    width="100%"
                    border={`1px solid ${theme.colors.secondaryGreen}`}
                    _hover={{
                      background: `${theme.colors.secondaryGreen}`,
                      color: "white",
                      borderCorlor: `${theme.colors.secondaryGreen}`
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      order.batch_details.forEach((batch_order) => {
                        handleSetSelectedProduct(
                          e,
                          batch_order.product,
                          batch_order.batch,
                          batch_order.item.amount,
                          batch_order.item.quantity
                        );
                      });
                      window.location.href = "/carrinho";
                    }}
                  >
                    Adicionar ao carrinho
                  </Button>
                }
                {order.payment.status !== "paid" &&

                  <Button
                    bg={theme.colors.secondaryGreen}
                    color="white"
                    size="xs"
                    borderRadius="20px"
                    width="100%"
                    border={`1px solid ${theme.colors.secondaryGreen}`}
                    _hover={{
                      background: "transparent",
                      color: `${theme.colors.secondaryGreen}`,
                      borderCorlor: `${theme.colors.secondaryGreen}`
                    }}
                    onClick={(e) => handlePayment(e, order.payment.id)}
                  >
                    Pagar
                  </Button>
                }
              </CardFooter>
            </Card>
            <Modal isOpen={activeModalIndex === index} onClose={handleClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader fontWeight="700">Pedido #{order.payment.id}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {order.batch_details.map((batch_order, index) => (
                    <Box key={batch_order.item.id}>
                      {console.log(order)}
                      <Box
                        display={"flex"}
                        justifyContent='space-between'
                        margin={"10px 0"}
                        gap={"3%"}
                      >
                        <Text
                          fontWeight={"500"}
                        >
                          Produto:
                        </Text>
                        <Text
                          fontWeight={"500"}
                        >
                          {isMobile && batch_order.product.name.length > 15
                            ? <span>{batch_order.product.name.slice(0, 15)}...</span>
                            : <span>{batch_order.product.name}</span>
                          }
                        </Text>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent='space-between'
                        margin={"10px 0"}
                        gap={"20%"}
                      >
                        <Text
                          fontWeight={"500"}
                        >
                          Quantidade:
                        </Text>
                        <Text
                          fontWeight={"500"}
                        >
                          {batch_order.item.quantity} {batch_order.batch.unity_of_measurement}{batch_order.item.quantity > 1 ? <span>s</span> : <span></span>}
                        </Text>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent='space-between'
                        margin={"10px 0"}
                        gap={"20%"}
                      >
                        <Text
                          fontWeight={"500"}
                        >
                          Valor:
                        </Text>
                        <Text
                          fontWeight={"500"}
                        >
                          {batch_order.item.amount.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </Text>
                      </Box>
                      {type === "paid" && (
                        <Box
                          display={"flex"}
                          justifyContent='space-between'
                          margin={"10px 0"}
                          gap={"20%"}
                        >
                          <Text
                            fontWeight={"500"}
                          >
                            Pago em:
                          </Text>
                          <Text
                            fontWeight={"500"}
                          >
                            {new Date(order.payment.updated_at).toLocaleDateString('pt-BR')}
                          </Text>
                        </Box>
                      )}
                      {order.payment.observations && (
                        <Box
                          display={"flex"}
                          justifyContent='space-between'
                          margin={"10px 0"}
                          gap={"20%"}
                        >
                          <Text
                            fontWeight={"500"}
                          >
                            Observações:
                          </Text>
                          <Text
                            fontWeight={"400"}
                            textAlign='end'
                          >
                            {order.payment.observations}
                          </Text>
                        </Box>
                      )}

                      <Box>
                        {batch_order.item.delivery_method === "delivery" && (<>
                          <Text fontWeight='500' marginTop='1rem'>Entrega em: </Text>
                          <Text>{batch_order.item.delivery_address}</Text>
                          <Box display={{base: 'block', md: 'flex'}} justifyContent='start' gap={5}>
                            {calculateDeliveryPeriod(batch_order.batch.id, batch_order.product, batch_order.item.delivery_method, batch_order.item, type)}
                            {order.payment.status === "paid" && type === "paid" &&
                              getStatusAndDateRangeCollect(
                                batch_order.batch.expiration_date,
                                batch_order.item.delivery_status
                              )
                            }
                          </Box>
                        </>)}
                        {batch_order.item.delivery_method === "collect" && (<>
                          <Text fontWeight='500' marginTop='1rem'>Coleta em: </Text>
                          <Text>{batch_order.collection_address.address_street}, nº {batch_order.collection_address.address_number}, {batch_order.collection_address.address_complement} - {batch_order.collection_address.address_neighborhood}</Text>
                          <Text>{cities.find(city => city.id === batch_order.collection_address.city_id).name} - {states.find(state => state.id === batch_order.collection_address.state_id).acronym} - CEP: {batch_order.collection_address.address_postal_code}</Text>
                          <Box display={{base: 'block', md: 'block'}} justifyContent='start' gap={5}>
                            {calculateDeliveryPeriod(batch_order.batch.id, batch_order.product, batch_order.item.delivery_method, batch_order.item, type)}
                            {order.payment.status === "paid" && type === "paid" &&
                              getStatusAndDateRangeCollect(
                                batch_order.batch.expiration_date,
                                batch_order.item.delivery_status
                              )
                            }
                          </Box>
                        </>)}
                      </Box>
                      <Divider orientation='horizontal' borderColor={theme.colors.primary} borderBottomWidth='2px' margin='10px auto'></Divider>
                    </Box>
                  ))}
                  {order.payment.payment_method === "credit_card" && (<>
                    <Box display='flex' gap={2} alignItems='center' justifyContent='end'>
                      <FaCreditCard></FaCreditCard>
                      <Text>Cartão de crédito</Text>
                    </Box>
                  </>)}
                  {order.payment.payment_method === "pix" && (
                    <Box display='flex' gap={2} alignItems='center' justifyContent='end'>
                      <FaMoneyBill></FaMoneyBill>
                      <Text>Pix</Text>
                    </Box>
                  )}
                  <Text
                    fontSize={"18px"}
                    fontWeight={"500"}
                    textAlign='end'
                    margin='1rem 0rem'
                  >
                    Total: {(order.payment.price_in_cents / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                  </Text>
                  {order.payment.status !== "paid" &&
                    <Box display="flex" justifyContent="end">
                      <Button
                        bg={theme.colors.secondaryGreen}
                        color="white"
                        borderRadius="20px"
                        border={`1px solid ${theme.colors.secondaryGreen}`}
                        _hover={{
                          background: "transparent",
                          color: `${theme.colors.secondaryGreen}`,
                          borderCorlor: `${theme.colors.secondaryGreen}`
                        }}
                        onClick={(e) => handlePayment(e, order.payment.id)}
                      >
                        PAGAR
                      </Button>
                    </Box>
                  }
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </>))}
      </Slider>

    </Box>
  );
}

import { Box, Image, theme } from "@chakra-ui/react";
import React from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomPrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      display: "block",
      background: "#ff7e15", // Custom color for the previous arrow
      borderRadius: "50%",
      zIndex: 1,
    }}
    onClick={onClick}
  />
);

const CustomNextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      display: "block",
      background: "#ff7e15", // Custom color for the next arrow
      borderRadius: "50%",
      zIndex: 1,
    }}
    onClick={onClick}
  />
);

export function BrandsCarousel() {
  const brandOptions = [
    {
      name: "Laut",
      imageUrl: require("../../assets/images/laut.webp")
    },
    {
      name: "Xeque Mate",
      imageUrl: require("../../assets/images/xeque.webp")
    },
    {
      name: "O'Gin",
      imageUrl: require("../../assets/images/ogin.webp")
    },
    {
      name: "Vanfall",
      imageUrl: require("../../assets/images/vanfall.webp")
    },
    {
      name: "Vinil",
      imageUrl: require("../../assets/images/vinil.webp")
    },
    {
      name: "Equilibrista",
      imageUrl: require("../../assets/images/equilibrista.webp")
    },
    {
      name: "Xa de Cana",
      imageUrl: require("../../assets/images/xa-de-cana.webp")
    },
    {
      name: "Breedom",
      imageUrl: require("../../assets/images/breedom.webp")
    },
    {
      name: "Zuur",
      imageUrl: require("../../assets/images/zuur.webp")
    },
    {
      name: "@adotepitbull",
      imageUrl: require("../../assets/images/adote.webp")
    }
  ]


  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />, // Use custom next arrow
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ],
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px",
          bottom: '-60px'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <Box position="relative" width="100%" height="20vh" padding="0px 0px 10px 0px">
      <Slider {...settings}>
        {brandOptions.map((brandOption, index) => {
          return (
            <Link key={index} to={`/produtos?brand=${encodeURIComponent(brandOption.name)}`}>
              <Box
                border="1px solid tranparent"
                borderRadius="5px"
                height="7rem"
                mx="7px"
                boxShadow="rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset"
                alignContent="center"
                textAlign="center"
                alignItems="middle"
                style={{
                  backgroundImage: "url('https://images.unsplash.com/photo-1598679253587-829c6cc6c6fc?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: 'white',
                  width: "97% !important"
                }}
              >
                <Image src={brandOption.imageUrl} borderRadius="5px" alt={`image ${index}`} w="100%" h="100%" objectFit="cover" backgroundPosition="center" />
              </Box>
            </Link>
          );
        })}
      </Slider>
    </Box>
  );
}

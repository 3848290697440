import { Box, Text, HStack} from "@chakra-ui/react";
import React, { useState, useEffect, useRef, useContext } from "react";
import { api } from "../../services/api";
import { ProductCard } from "../../components/ProductCard";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { AddToCartPopUp } from "../AddToCartPopUp";
import { CartContext } from "../../Context/CartContext";


const CustomPrevArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      display: "block",
      background: "#ff7e15", // Custom color for the previous arrow
      borderRadius: "50%",
      zIndex: 1,
    }}
    onClick={onClick}
  />
);

const CustomNextArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{
      ...style,
      display: "block",
      background: "#ff7e15", // Custom color for the next arrow
      borderRadius: "50%",
      zIndex: 10000,
    }}
    onClick={onClick}
  />
);

export function ProductsCarousel({ typeName }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [batches, setBatches] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productsPhotos, setProductsPhotos] = useState({});
  const [touchStartX, setTouchStartX] = useState(null);
  const [isTouching, setIsTouching] = useState(false);
  const carouselRef = useRef(null);
  const { showCartPopUp, setShowCartPopUp} = useContext(CartContext)
  const sliderRef = useRef();

  async function fetchProducts() {
    const response = await api.get(`/?type=${encodeURIComponent(typeName)}`);
    setProducts(response.data.products);
    setBatches(response.data.batches);
    setPrices(response.data.prices);
    setProductsPhotos(response.data.product_photos_url);
    setProductTypes(response.data.product_types);
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0); // Force the slider to go to the first slide
    }
  }, [products]);






  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          initialSlide: 0,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,

        }
      }
    ],
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px",
          bottom: '0px'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <Box w={{ base: '100%', md: '100%' }} h={{base: "auto", md: "auto" }} position="relative" overflow="hidden" margin={"0% auto 2% auto"} borderRadius={"40px"}>
      <Slider ref={sliderRef} {...settings}>
        {products.map((product, index) => (
          <Box
            key={product.id}
            margin="15px 0px"
            padding="30px 5px"
            width="100% !important"
            style={{
              width: "100% !important"
            }}
          >
            <Link to={`/produtos/${productTypes?.find(e => e.id === product.product_type_id).name.replace(/(%20|\s+)/g, "+")}/${product.name.replace(/(%20|\s+)/g, "+")}`}>
              <ProductCard
                product={product}
                id={product.id}
                name={product.name}
                prices={prices?.filter(element => element.batch_id === batches?.find(element => element.product_id === product.id)?.id)}
                brand={product.brand}
                size={product.size}
                description={product.description}
                quantityPerBox={product.quantity_per_box}
                marketPrice={product.market_price}
                productType={product.product_type}
                batch={batches.find(element => element.product_id === product.id)}
                productPhotoUrl={productsPhotos[product.id]}
                carouselType={typeName}
              />
            </Link>
          </Box>
        ))}
      </Slider>
      {showCartPopUp && <AddToCartPopUp />}
    </Box>
  );
}

import React, { useEffect, useState, useContext } from "react";
import Navbar from "../../components/Navbar";
import {Store} from "../Store";
import { Box, Text, Container, Image  } from "@chakra-ui/react";
import { useAuth } from "../../Hooks/useAuth";
import { theme } from "../../styles/theme";
import { Footer } from '../../components/Footer'
import { Carousel } from '../../components/Carousel'
import { ReviewsCarousel } from '../../components/ReviewsCarousel'
import { Link } from 'react-router-dom';
import { ProductsCarousel } from '../../components/ProductsCarousel'
import { api } from "../../services/api";
import delivery from '../../assets/images/caminhao-de-entrega.png';
import discount from '../../assets/images/percentage.png';
import arrow from '../../assets/images/flecha.png';
import cards from '../../assets/images/credit-card.png';
import { CartContext } from "../../Context/CartContext";
import { AddToCartPopUp } from "../../components/AddToCartPopUp";

export function Home() {
  const { user, isAuthenticated } = useAuth();
  const [products, setProducts] = useState([])
  const [batches, setBatches] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productsPhotos, setProductsPhotos] = useState({});
  const { showCartPopUp } = useContext(CartContext)

  let product = null
  let batch = null
  async function fetchProducts() {
    const response = await api.get(`/`)
    setProducts(response.data.products)
    setBatches(response.data.batches)
    setPrices(response.data.prices)
    setProductsPhotos(response.data.product_photos_url)
    product = response.data.products.find(e => e.id === 250)
    batch = response.data.batches.find(e => e.product_id === 250)
  }

  useEffect(() => {
    isAuthenticated();
    fetchProducts();
  }, [])

  const promoInfos = [
      {
        url: require("../../assets/images/promo1.webp"),
        bannerUrl: `/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`,
      },
      {
        url: require("../../assets/images/promo2.webp"),
        bannerUrl: `/produtos/Kit+Empresa/${encodeURIComponent("Caixa Misteriosa").replace(/%20/g, "+")}`,
      },
      {
        url: require("../../assets/images/promo3.webp"),
        bannerUrl: `/produtos/Kit+verão/${encodeURIComponent("BH Drink Experience").replace(/%20/g, "+")}`,
      }
    ];

  return (<>
    <Navbar/>
    <Carousel />
    <Box
      padding={{base: '5% 2%', md: '3% 15%'}}
      background="white"
      mb='30px'
      mt={{base: '10%', sm: '0'}}
      display={{base: 'flex', md: 'flex'}}
      flexDirection={{base: 'column', md: 'row'}}
      justifyContent="center"
      gap={{base: '30px', md: '10%'}}
      width='100%'

    >
      <Box
        display={{base: 'flex', md: 'flex'}}
        justifyContent="space-between"
        gap={{base: '2%', md: '0'}}
        width='100%'
      >

        <Box
          display="flex"
          justifyContent="center"
          gap="10px"
          alignItems="center"
        >
          <Box>
            <Image
              objectFit='cover'
              maxW={{ base: '100%', sm: '200px' }}
              width={{ base: '40px', sm: '60px' }}
              height={{ base: '40px', sm: '60px' }}
              src={delivery}
              alt='Produto'
              maxHeight='16.5rem'
              filter="brightness(0) saturate(100%) invert(58%) sepia(26%) saturate(6625%) hue-rotate(11deg) brightness(110%) contrast(94%)"
            />
          </Box>
          <Box>
            <Text fontWeight="bold" marginBottom="0px" fontSize={{ base: '15px', sm: '18px' }} color={theme.colors.secondaryGreen}>Frete grátis</Text>
            <Text fontWeight="light" marginBottom="0px" fontSize={{ base: '15px', sm: '16px' }}>Acima de R$120,00</Text>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          gap="10px"
          alignItems="center"
          me={{base: '5%', sm: '0'}}
        >
          <Box>
            <Image
              objectFit='cover'
              maxW={{ base: '100%', sm: '200px' }}
              width={{ base: '40px', sm: '60px' }}
              height={{ base: '40px', sm: '60px' }}
              src={discount}
              alt='Produto'
              maxHeight='16.5rem'
              filter="brightness(0) saturate(100%) invert(58%) sepia(26%) saturate(6625%) hue-rotate(11deg) brightness(110%) contrast(94%)"
            />
          </Box>
          <Box>
            <Text fontWeight="bold" marginBottom="0px" fontSize={{ base: '15px', sm: '18px' }} color={theme.colors.secondaryGreen}>5% de Desconto</Text>
            <Text fontWeight="light" marginBottom="0px" fontSize={{ base: '15px', sm: '16px' }}>Na primeira compra</Text>
          </Box>
        </Box>
      </Box>

      <Box
        display={{base: 'flex', md: 'flex'}}
        justifyContent="space-between"
        gap={{base: '2%', md: '0'}}
        width='100%'
      >
        <Box
          display="flex"
          justifyContent="center"
          gap="10px"
          alignItems="center"
        >
          <Box>
            <Image
              objectFit='cover'
              maxW={{ base: '100%', sm: '200px' }}
              width={{ base: '40px', sm: '60px' }}
              height={{ base: '40px', sm: '60px' }}
              src={arrow}
              alt='Produto'
              maxHeight='16.5rem'
              filter="brightness(0) saturate(100%) invert(58%) sepia(26%) saturate(6625%) hue-rotate(11deg) brightness(110%) contrast(94%)"
            />
          </Box>
          <Box>
            <Text fontWeight="bold" marginBottom="0px" fontSize={{ base: '15px', sm: '18px' }} color={theme.colors.secondaryGreen}>1ª Troca gratuita</Text>
            <Text fontWeight="light" marginBottom="0px" fontSize={{ base: '15px', sm: '16px' }}>E sem burocracia</Text>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          gap="10px"
          alignItems="center"
        >
          <Box>
            <Image
              objectFit='cover'
              maxW={{ base: '100%', sm: '200px' }}
              width={{ base: '40px', sm: '60px' }}
              height={{ base: '40px', sm: '60px' }}
              src={cards}
              alt='Produto'
              maxHeight='16.5rem'
              filter="brightness(0) saturate(100%) invert(58%) sepia(26%) saturate(6625%) hue-rotate(11deg) brightness(110%) contrast(94%)"
            />
          </Box>
          <Box>
            <Text fontWeight="bold" marginBottom="0px" fontSize={{ base: '15px', sm: '18px' }} color={theme.colors.secondaryGreen}>Parcele suas compras</Text>
            <Text fontWeight="light" marginBottom="0px" fontSize={{ base: '15px', sm: '16px' }}>Em até 4x sem juros</Text>
          </Box>
        </Box>
      </Box>
    </Box>

    <Box bg="white"  marginTop="0vh" id="storeSection">
      <Store />
      <Container
        maxWidth={"none"}
        padding={{base: '5% 2%', md: '3% 5%'}}
        background="white"
        mb='30px'
      >
        <Text
          fontSize="35px"
          fontWeight="bold"
          mb="5px"
          pading={{base: '5% 2% 0% 2%', md: '3% 0% 0% 0%'}}
          color={theme.colors.logoOrange}
          textAlign="center"
        >
          Mais Vendidos
        </Text>
        <Text
          fontSize={{ base: '18px', md: '15px' }}
          textAlign={"center"}
          padding={"0 0 1% 0 "}

        >
          As bebidas mais aprecidas pelos nossos cliente.
        </Text>

        <Box
          margin={"0"}
          justifyContent={"center"}
          gap={"50px"}
          maxWidth={"none"}
          mb="10px"
        >
          <ProductsCarousel typeName="Destaques"/>
        </Box>

        <Box
          display={{base: 'flex', md: 'flex'}}
          flexDirection={{base: 'column', md: 'row'}}
          justifyContent="space-between"
          gap={{base: '30px', md: '20px'}}
          my={{base: "10%", md: "10%"}}
        >
          {promoInfos.map((promo, index) => (<>
            <Link to={promo.bannerUrl} key={`promo-${index}`}>
              <Image src={promo.url} alt={`image ${index}`} w="100%" h="100%" objectFit="cover" backgroundPosition="center" borderRadius="15px"
                _hover={{
                  transform: "scale(1.02)"
                }}
              />
            </Link>
          </>))}
        </Box>

        <Text
          fontSize="35px"
          fontWeight="bold"
          mb="5px"
          pading={{base: '5% 2% 0% 2%', md: '3% 0% 0% 0%'}}
          color={theme.colors.logoOrange}
          textAlign="center"
        >
          Última Oportunidade
        </Text>
        <Text
          fontSize={{ base: '18px', md: '15px' }}
          textAlign={"center"}
          padding={"0 0 1% 0 "}

        >
          Produtos com até 33% de desconto
        </Text>
        <Box
          margin={"0"}
          justifyContent={"center"}
          gap={"50px"}
          maxWidth={"none"}
          mb="10px"
        >
          <ProductsCarousel typeName="Descontos"/>
        </Box>

        <Text
          fontSize="35px"
          fontWeight="bold"
          mb="5px"
          pading={{base: '5% 2% 0% 2%', md: '3% 0% 0% 0%'}}
          color={theme.colors.logoOrange}
          textAlign="center"
        >
          Acabaram de Chegar
        </Text>
        <Text
          fontSize={{ base: '18px', md: '15px' }}
          textAlign={"center"}
          padding={"0 0 1% 0 "}
        >
          Últimos lançamentos
        </Text>
        <Box
          margin={"0"}
          justifyContent={"center"}
          gap={"50px"}
          maxWidth={"none"}
          mb="10px"
        >
          <ProductsCarousel typeName="Lançamentos"/>
        </Box>

        <Text
          fontSize="35px"
          fontWeight="bold"
          mb="5px"
          pading={{base: '5% 2% 0% 2%', md: '3% 0% 0% 0%'}}
          color={theme.colors.logoOrange}
          textAlign="center"
        >
          Acessórios
        </Text>
        <Text
          fontSize={{ base: '18px', md: '15px' }}
          textAlign={"center"}
          padding={"0 0 1% 0 "}
        >
          Confira nossos últimos lançamentos
        </Text>
        <Box
          margin={"0"}
          justifyContent={"center"}
          gap={"50px"}
          maxWidth={"none"}
          mb="10px"
        >
          <ProductsCarousel typeName="Acessórios"/>
        </Box>

        <Text
          fontSize="35px"
          fontWeight="bold"
          mb="5px"
          pading={{base: '5% 2% 0% 2%', md: '3% 0% 0% 0%'}}
          color={theme.colors.logoOrange}
          textAlign="center"
        >
          Projetos Sociais
        </Text>
        <Text
          fontSize={{ base: '18px', md: '15px' }}
          textAlign={"center"}
          padding={"0 0 1% 0 "}
        >
          Ajude a mudar para melhor
        </Text>
        <Box
          margin={"0"}
          justifyContent={"center"}
          gap={"50px"}
          maxWidth={"none"}
        >
          <ProductsCarousel typeName="Projetos Sociais"/>
        </Box>
        <Text
          fontSize="35px"
          fontWeight="bold"
          mb="5px"
          pading={{base: '5% 2% 0% 2%', md: '3% 0% 0% 0%'}}
          color={theme.colors.logoOrange}
          textAlign="center"
        >
          Depoimentos
        </Text>
        <Text
          fontSize={{ base: '18px', md: '15px' }}
          textAlign={"center"}
          padding={"0 0 1% 0 "}
        >
          O que nossos clientes estão falando.
        </Text>
        <Box
          margin={{ base: '20px', md: '10px 10px 20px 10px' }}
        >
          <ReviewsCarousel></ReviewsCarousel>
        </Box>
      </Container>
    </Box>
    <Footer/>
    {showCartPopUp && <AddToCartPopUp />}
  </>)
}

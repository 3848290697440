import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    primary: "#D44120",
    secondary: "#F6B93D",
    primaryGreen: "#216a0f",
    secondaryGreen: "#49b82d",
    orange: "#ef9207",
    background: "#FEECA5",
    secondaryBackground: "#B7D6AA",
    whiteSmoke: "#f5f5f5",
    secondaryOrange: "#ef5f0e",
    primaryOrange: "#ff6600",
    bgFooter: "#feeca5",
    slogan: "#f6b93d",
    white: "#FFFFFF",
    white500: "#FFF6EF",
    logoOrange: "#ff7e15",
    blue500: "#334855",
    green500: "#3e8421",
    black: "#120e0b",
    transparent: "transparent",
    gray: "#ced4da",
    lightGray: "#D9D9D9",
    lightYellow: "#fff3cd",
    darkGray: "#444444",
    darkerGray: "#343537"

  }
});

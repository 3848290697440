import {
  Button,
  Text,
  Grid
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useContext, useState } from "react";
import { api } from "../../services/api";
import { CityContext } from '../../Context/CityContext';
import { useLocation } from 'react-router-dom';

export function OtherDrinksFilters(props) {
  const location = useLocation();
  const [readyDrinkTypes, setReadyDrinkTypes] = useState([]);
  const { cityParam } = useContext(CityContext);
  const [brands, setBrands] = useState([]);
  const readyDrinkTypeOptions = ["Bebida Mista", "Lata Ready-to-drink"];

  const brandOptions = [
    "Carimbó",
    "Equilibrista",
    "YB do Brasil",
    "StoneLight",
    "Xeque-mate",
    "Fifty",
    "Jambruna",
    "Gin Flower",
    "Drink Pronto",
    "20's",
    "Xa de Cana",
    "Hazel",
    "Like Wine",
    "Poente",
    "Hardz",
    "Whitte Rabbit",
    "Easy Booze",
    "Xeque Mate",
    "Xangolião",
    "Vanfall"
  ];

  async function handleGetProductTypes(e, productType) {
    e.preventDefault();
    const updatedReadyDrinkTypes = readyDrinkTypes.includes(productType)
      ? readyDrinkTypes.filter((type) => type !== productType)
      : [...readyDrinkTypes, productType];

    setReadyDrinkTypes(updatedReadyDrinkTypes);
    filterProducts(updatedReadyDrinkTypes, brands);
  }

  async function handleGetProductBrand(e, brand) {
    e.preventDefault();
    const updatedBrands = brands.includes(brand)
      ? brands.filter((b) => b !== brand)
      : [...brands, brand];

    setBrands(updatedBrands);
    filterProducts(readyDrinkTypes, updatedBrands);
  }

  async function filterProducts(types, brands) {
    let url = `/api/v1/products?city_id=${cityParam}`;

    if (brands.length > 0) {
      url = `${url}&ready_brands=${brands.join(',')}`;
    }
    if (types.length > 0) {
      url = `${url}${brands.length > 0 ? '&' : '&'}other_drink_types=${types.join(',')}`;
    }

    if (types.length === 0 && brands.length === 0) {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      url = `${url}&filter=${filter}`;
    }

    const response = await api.get(url);
    props.setProducts(response.data.products);
  }

  return (
    <>
      <Text fontWeight="bold" color={theme.colors.primaryGreen} mb="20px">Marcas:</Text>
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
        gap={4}
      >
        {brandOptions.map((brandOption, index) => (
          <Button
            key={index}
            width="100%"
            onClick={(e) => handleGetProductBrand(e, brandOption)}
            border={`1px solid ${theme.colors.secondaryGreen}`}
            color={theme.colors.secondaryGreen}
            borderRadius="20px"
            size="sm"
            _hover={{
              transform: "scale(1.02)",
              color: "white",
              bg: `${theme.colors.secondaryGreen}`,
            }}
            style={{
              backgroundColor: brands.includes(brandOption) ? `${theme.colors.secondaryGreen}` : "transparent",
              color: brands.includes(brandOption) ? "white" : `${theme.colors.secondaryGreen}`,
            }}
          >
            {brandOption}
          </Button>
        ))}
      </Grid>
    </>
  );
}

import {
  Box,
  Input,
  Button,
  List,
  ListItem,
  Text,
  SimpleGrid,
  Divider
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import { FaBeer, FaTimes } from 'react-icons/fa';
import { CityContext } from '../../Context/CityContext';
import { Link } from 'react-router-dom';
import { RiCloseCircleLine } from "react-icons/ri";

export function Search(props) {
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showResultsList, seShowResultsList] = useState(false);
  const { cityName } = useContext(CityContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('trackCustom', 'Search');
      }
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const mobileWidth = isScrolled ? '95%' : '95%';
  const mdWidth = isScrolled ? '50%' : '100%';


  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const fetchLiveResults = async (query) => {
    try {
      const url = `/search?city_name=${cityName}&query=${query}`;
      const response = await api.get(url);
      seShowResultsList(true)
      setSearchResults(response.data.products);
    } catch (error) {
      console.error("Error fetching live search results:", error);
    } finally {
      setSearching(false);
    }
  };


  const handleProductSearch = (query) => {
    const urlParams = new URLSearchParams();
    if (cityName) urlParams.append("city_name", cityName);
    if (query) urlParams.append("query", query);

    navigate(`/produtos?${urlParams.toString()}`, { replace: true });
    setSearchResults(false)
    handlefetchProductTypes();
  };

  async function handlefetchProductTypes()  {
    const url = `/api/v1/product_types`
    const response = await api.get(url)
    setProductTypes(response.data)
  }

  const handleClearSearch = (e) => {
    e.preventDefault();
    document.getElementById('search-input').value = '';
    setSearchResults([]);
    setSearching(false);
    const linkPath = e.currentTarget.getAttribute('href');
    if (linkPath) {
      window.location.href = linkPath;
    }
  }






  return (
    <>
    <Box
      pt={{ base: '0px', md: '0px' }}
      display='flex'
      px={{ base: '0%' , md: '0rem' }}
      justifyContent={{ base: 'center', md: 'center' }}
      gap={{ base: '5px', md: '0px' }}
      width="100%"
      alignItems='center'
    >
      <Box display="flex" alignItems="center" position="relative" width={{ base: mobileWidth, md: mdWidth }}>
        <Input
          placeholder="Pesquisar"
          background='white'
          size="md"
          id='search-input'
          borderRadius="20px"
          height="31px"
          onChange={(e) => fetchLiveResults(e.target.value.trim())}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleProductSearch(e.target.value.trim());
          }}
          color={theme.colors.primary}
          border='1px solid #343537'
          _hover={{ borderColor: theme.colors.lightGray }}
          _focus={{ outline: 'none', boxShadow: 'none' }}
          width="100%" // Make the input full width
        />
        {searching && (
          <Button
            position="absolute"
            right="0"
            height="31px" // Align with input height
            bg='transparent'
            onClick={(e) => handleClearSearch(e)}
            zIndex='1'
            fontWeight='light'
            color='gray'
            _hover={{ color: theme.colors.primary, bg: 'transparent' }}
            width={{ base: '15%', md: 'auto' }} // Maintain size without shifting input
          >
            <FaTimes />
          </Button>
        )}

        {/* Search Results */}
        {searchResults?.length > 0 && showResultsList && (
          <Box
            position="absolute"
            top="40px"
            left="0"
            width="100%"
            zIndex="100000"
            pb="1rem"
            borderRadius="15px"
            boxShadow="lg"
            padding="20px"
            background="white"
          >
            <List width="100%">
              {searchResults?.length > 0 && (
                searchResults.map((product, index) => (
                  <Link
                    to={`/produtos/${productTypes?.find(e => e.id === product.product_type_id)?.name.replace(/(%20|\s+)/g, "+")}/${product.name.replace(/(%20|\s+)/g, "+")}`}
                    key={index}
                    onClick={handleClearSearch}
                  >
                    <ListItem
                      border="1px solid #D44120"
                      borderRadius="5px"
                      p="1%"
                      my="5px"
                      color="#D44120"
                      _hover={{ bg: theme.colors.primary, color: 'white' }}
                    >
                      {product.name}
                    </ListItem>
                  </Link>
                ))
              )}
            </List>
          </Box>
        )}
      </Box>
    </Box>
    </>
  )
}

import { Box, Text, Grid, GridItem  } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../components/Navbar";
import { theme } from "../../styles/theme";
import { Link } from 'react-router-dom';
import { BrandsCarousel } from '../../components/BrandsCarousel'
import beerImage from '../../assets/images/CERVEJAS.webp';
import wineImage from '../../assets/images/VINHOS.webp';
import spiritImage from '../../assets/images/DESTILADOS.webp';
import readyToDrinkImage from '../../assets/images/DRINKS-PRONTOS.webp';
import bestSellingImage from '../../assets/images/OFERTAS.webp';
import nonAlcoholicImage from '../../assets/images/NALCOOLICOS.webp';
import biggerDiscountImage from '../../assets/images/DESCONTOS.webp';
import kitsImage from '../../assets/images/KITS.webp';
import newProductsImage from '../../assets/images/DESCOBERTAS.webp';
import buffetsImage from '../../assets/images/EVENTOS.webp';
import brewersImage from '../../assets/images/MICROCERVEJARIAS.webp';
import choppImage from '../../assets/images/CHOPP.webp';

// const MemoizedNavbar = React.memo(Navbar);
export function Store() {
  const typesOfCardToSelec = [
    {
      cardTitle: "Chopp",
      param: JSON.stringify(["chopp", "Chopp"]),
      cardImageUrl: choppImage,

    },
    {
      cardTitle: "Drinks Prontos",
      param: JSON.stringify(["OUTRAS BEBIDAS", "BEBIDAS MISTAS"]),
      cardImageUrl: readyToDrinkImage,

    },
    {
      cardTitle: "Microcervejarias",
      param: JSON.stringify(["Microcervejarias", "microcervejarias", "microcervejaria", "Microcervejaria"]),
      cardImageUrl: brewersImage,

    },
    {
      cardTitle: "Cervejas",
      param: JSON.stringify(["CERVEJA", "Cerveja"]),
      cardImageUrl: beerImage,

    },
    {
      cardTitle: "Vinhos e Espumantes",
      param: JSON.stringify(["Vinho"]),
      cardImageUrl: wineImage,

    },
    {
      cardTitle: "Destilados",
      param: JSON.stringify(["DESTILADOS"]),
      cardImageUrl: spiritImage,

    },
    {
      cardTitle: "Eventos, Buffets e Casamentos",
      param: JSON.stringify(["Eventos, Buffets e Casamentos"]),
      cardImageUrl: buffetsImage,

    },
    {
      cardTitle: "KITS",
      param: JSON.stringify(["Kits"]),
      cardImageUrl: kitsImage,

    },
    {
      cardTitle: "Não Alcoólicos",
      param: JSON.stringify(["NÃO ALCOOLICOS", "Não alcoólicos", "Não Alcoólicos", "NÃO ALCOOLICO", "Não alcóolico", "Não Alcóolico"]),
      cardImageUrl: nonAlcoholicImage,

    },
    {
      cardTitle: "Novidades",
      param: JSON.stringify(["Novidades"]),
      cardImageUrl: newProductsImage,

    },
    {
      cardTitle: "Mais Vendidos",
      param: JSON.stringify(["Mais Vendidos"]),
      cardImageUrl: bestSellingImage,

    },
    {
      cardTitle: "Maiores Descontos",
      param: JSON.stringify(["Maiores Descontos"]),
      cardImageUrl: biggerDiscountImage,

    }
  ];

  return (
    <Box
      margin={{ base: '20px', md: '20px 100px' }}
    >
      <Text
        fontSize="35px"
        fontWeight="bold"
        mb="10px"
        pading={{base: '5% 2% 0% 2%', md: '3% 0% 2% 0%'}}
        color={theme.colors.logoOrange}
        textAlign="center"
      >Produtos</Text>
      <Grid
        templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(6, 1fr)" }}
        gap={{ base: 2, md: 4 }}
      >
        {typesOfCardToSelec.map((card, index) => {
          return (
            <Link to={`/produtos?filter=${encodeURIComponent(card.param)}`} key={index}>
              <Box
                _hover={{
                  transform: "scale(1.05)"
                }}
              >
                <GridItem
                  backgroundImage={`url("${card.cardImageUrl}")`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  height={{ base: "7rem", md: "7rem" }}
                  borderRadius="5px"
                  display="flex"
                  alignItems="center"
                  opacity="0.80"
                  transition="transform 0.2s"
                  border="none"
                >
                </GridItem>
                <Text
                  textAlign="center"
                  color="black"
                  fontSize={{ base: "14px", md: "14px" }}
                  fontWeight="500"
                  // marginTop="10%"
                  // textshadow="1px 1px 2px black"
                  margin="1% auto 0% auto"
                  zIndex="10001"
                >
                  {card.cardTitle}
                </Text>
              </Box>
            </Link>
          )
        })}
      </Grid>

      <Text fontSize="35px" fontWeight="bold" mt="3%" mb="10px"
        padding={{base: '5% 2% 0% 2%', md: '3% 0% 1% 0%'}}
        color={theme.colors.logoOrange}
        textAlign="center"

      >
        Grandes Marcas
      </Text>
      <BrandsCarousel/>
    </Box>
  )
}

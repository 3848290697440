import {
  Box
} from "@chakra-ui/react";
import React from "react";
import { RiWhatsappLine } from "react-icons/ri";
export function WhatsAppButton() {

  return (
    <Box position="fixed" top={{base: "90%", md: "90%"}} marginLeft={{base: "85%", md: "95%"}} zIndex="10000">
      <a href="https://wa.me/5531971301228" target="_blank" rel="noopener noreferrer">
        <RiWhatsappLine fontSize={window.screen.width < 768 ? 50 : 50} color={"#26cc64"} weight="fill"/>
      </a>
    </Box>
  );
}

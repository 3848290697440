
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function OrdersByType({ typesSold }) {
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
  const colors = [
    'rgba(13,110,253, 0.2)',
    'rgba(0,123,255, 0.2)',
    'rgba(40,167,69, 0.2)',
    'rgba(220,53,69, 0.2)',
    'rgba(255,193,7, 0.2)',
    'rgba(23,162,184, 0.2)',
    'rgba(255,87,34, 0.2)',
    'rgba(156,39,176, 0.2)',
    'rgba(0,188,212, 0.2)',
    'rgba(3,169,244, 0.2)',
    'rgba(139,195,74, 0.2)',
    'rgba(233,30,99, 0.2)',
    'rgba(255,235,59, 0.2)',
    'rgba(156,204,101, 0.2)',
    'rgba(244,67,54, 0.2)',
    'rgba(96,125,139, 0.2)',
    'rgba(121,85,72, 0.2)',
    'rgba(158,158,158, 0.2)',
    'rgba(0,150,136, 0.2)',
  ];

  const datasets = typesSold.map((item, index) => {
    const monthlyData = labels.map(month => {
      const monthData = item.monthly_data.find(data => data.month === month);
      return monthData ? monthData.total_paid : 0;
    });

    return {
      label: item.product_type_name,
      data: monthlyData,
      backgroundColor: colors[index % colors.length],
      borderColor: colors[index % colors.length],
      borderWidth: 2,
      pointBackgroundColor: colors[index % colors.length],
      fill: true,
      tension: 0.4,
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const monthData = item.monthly_data[tooltipItem.dataIndex];
            return `${item.product_type_name} | Total pago: ${tooltipItem.raw.toFixed(2)} | Produtos: ${monthData ? monthData.products.join(", ") : 'N/A'}`;
          },
        },
      },
    };
  });

  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tipos de Bebida vendidas por mês',
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        max: 10000,
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div
      style={{
        width: '100%',
        height: '30rem',
        borderRadius: '15px',
        padding: '20px',
        margin: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'
      }}
    >
      <Line data={data} options={options} style={{ margin: 'auto', width: '100%', height: '100%' }} />
    </div>
  );
}

import {
  Box,
  Text,
  Button,
  useDisclosure,
  Divider,
  Avatar,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useMediaQuery,
  Spinner
} from "@chakra-ui/react";
import { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Footer } from "../../../components/Footer";
import { OrdersSlider } from "../../../components/OrdersSlider";
import { api } from "../../../services/api";
import { theme } from "../../../styles/theme";
import { useAuth } from "../../../Hooks/useAuth";
import Navbar from "../../../components/Navbar";
import { Form } from '../Form';
import swal  from 'sweetalert';
import { FaPen, FaAngleUp, FaAngleDown, FaCreditCard, FaTrashAlt, FaCcVisa, FaCcMastercard  } from "react-icons/fa";


export function Profile() {
  const { user } = useAuth();
  const [profile, setProfile] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      full_name: "",
      address_street: "",
      address_number: "",
      address_complement: "",
      address_neighborhood: "",
      address_postal_code: "",
      city_id: "",
      state_id: "",
      phone_number: "",
      email: "",
      password: ""
    }
  );
  const { id } = useParams()
  const [orders, setOrders] = useState([])
  const [pendingOrders, setPendingOrders] = useState([])
  const [paidOrders, setPaidOrders] = useState([])

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [userCredits, setUserCredits] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [mapedCitiesForState, setMapedCitiesForState] = useState([]);
  const [userPhoto, setUserPhoto] = useState(null);
  const [creditHistorySection, setCreditHistorySection] = useState(false);
  const [userDocument, setUserDocument] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [savedCards, setSavedCards] = useState([]);
  const [errors, setErrors] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const currentDate = new Date();
  const isMobile = useMediaQuery("(max-width: 767px)")[0];
  const gridTemplateColumns = isMobile
  ? "repeat(1, 1fr)"
  : "repeat(3, 1fr)";

  const handleLocality = (e) => {
    setMapedCitiesForState(cities.filter(element => element.state_id === Number(e.target.value)))
  }


  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile({ [name]: value });

    if (name === "photo") {
      const file = e.target.files[0];
      setProfile((prevState) => ({ ...prevState, photo: file }));
    } else {
      setProfile((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  async function fetchUser() {
    setLoadingPage(true)
    const response = await api.get(`/api/v1/users/${id}`)
    setProfile(response.data.user)
    setUserDocument(response.data.user.document_number)
    setUserPhoneNumber(response.data.user.phone_number)

    setStates(response.data.states)
    setCities(response.data.cities)
    setUserCredits(response.data.credits)
    setOrders(response.data.order_total_info)
    setPendingOrders(response.data.order_total_info.filter(e => e.payment.status === "pending").sort((a, b) => new Date(b.payment.created_at) - new Date(a.payment.created_at)))
    setPaidOrders(response.data.order_total_info.filter(e => e.payment.status === "paid").sort((a, b) => new Date(b.payment.created_at) - new Date(a.payment.created_at)))
    setLoadingPage(false)
  }

  const handlePayment = (e, paymentId) => {
    window.location = `/pagamentos/${paymentId}`
  }


  // useEffect(() => {
  //   // Fetch saved cards
  //   api.get('/api/v1/payments/saved_cards').then((response) => {
  //     setSavedCards(response.data.saved_cards);
  //   }).catch(() => {
  //     setSavedCards([]);
  //   });
  // }, []);

  useEffect(() => {
    fetchUser();
    const urlParams = new URLSearchParams(window.location.search);
    const missingData = urlParams.get("missing_data");
    if (missingData === "true") {
      swal({
        title: "Quase lá!",
        text: "Preencha seu Nome, Documento e Celular para efetuar a compra",
        icon: "info",
      });
    }
  }, [])

  useEffect(() => {
    fetchUser();
    const urlParams = new URLSearchParams(window.location.search);
    const paymentSuccess = urlParams.get("payment_success");

    if (paymentSuccess === "true") {
      swal({
        title: "Ótimo",
        text: "Seu pagamento foi confirmado, você ja pode ver os detalhes do seu pedido no histórico.",
        icon: "success",
      });
    }

  }, [])



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUserPhoto(file);
  };

  const toggleCreditsHistory = (e) => {
    e.preventDefault()
    setCreditHistorySection(!creditHistorySection)
  }

  const handleDocumentDisplay = (number) => {
    let cleanedString = number;
    if (cleanedString.length === 11) {
      var formattedNumber = cleanedString.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (cleanedString.length === 14) {
      var formattedNumber = cleanedString.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    } else if (cleanedString.length === 8) {
      var formattedNumber = cleanedString.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
    } else {
      var formattedNumber = "";
    }
    return formattedNumber
  }

  function formatPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 11) {
      return cleaned.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    } else {
      return phoneNumber;
    }
  }

  const handleEditUser = async (e) => {
    e.preventDefault();
    // let cleanedDocumentNumber =  userDocument.replace(/[.-\s]/g, '');
    // let cleanedPhoneNumber =  userPhoneNumber.replace(/\D/g, '');
    let cleanedDocumentNumber = userDocument ? userDocument.replace(/[.\-\s]/g, '') : '';
    let cleanedPhoneNumber = userPhoneNumber ? userPhoneNumber.replace(/\D/g, '') : '';
    const formData = new FormData();
    formData.append("user[email]", profile.email);
    formData.append("user[document_number]", cleanedDocumentNumber);
    formData.append("user[full_name]", profile.full_name);
    if (profile.password !== "") {
      formData.append("user[password]", profile.password);
    }
    formData.append("user[phone_number]", cleanedPhoneNumber);
    formData.append("user[address_street]", profile.address_street);
    formData.append("user[address_number]", profile.address_number);
    formData.append("user[address_complement]", profile.address_complement);
    formData.append("user[address_neighborhood]", profile.address_neighborhood);
    formData.append("user[address_postal_code]", profile.address_postal_code);
    if (profile.city_id !== null) {
      formData.append("user[city_id]", profile.city_id);
    }
    if (profile.state_id !== null) {
      formData.append("user[state_id]", profile.state_id);
    }
    if (userPhoto) {
      formData.append("user[photo]", userPhoto);
    }

    try {
      const response = await api.put(`/api/v1/users/${id}`, formData, {
        headers: {
          'Content-Type': "multipart/form-data",
        },
      });

      if (response.data.message === "Usuário atualizado com sucesso") {
        swal({
          title: "Ohhh Yeahhh",
          text: "Dados atualizados com sucesso!",
          icon: "success",
        });
        localStorage.setItem("userPhoto", response.data.user_photo);
        onClose();
        window.location.href = `/perfil/${user.id}`;
      } else {
        swal({
          title: "Ohhh Noo",
          text: "Algo deu errado!",
          icon: "error",
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors); // Set errors from the API response
      } else {
        swal({
          title: "Ohhh Noo",
          text: "Erro inesperado. Por favor, tente novamente.",
          icon: "error",
        });
      }
    }
  };

  const handleRemovePaymentMethod = async (cardId) => {
    try {
      const response = await api.delete(`/api/v1/payments/remove_saved_card`, {
        data: { card_id: cardId },
      });

      if (response.data.message === "Card removed successfully") {
        swal({
          title: "Card Removed",
          text: "Your card was removed successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Error",
          text: "Failed to remove card.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error('Error:', error);
      swal({
        title: "Error",
        text: "An unexpected error occurred.",
        icon: "error",
      });
    }
  };

  function formatDateDifference(createdDate) {
    const createdDateObject = new Date(createdDate);
    const currentDateObject = new Date();
    const monthNames = [
      "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
      "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];
    const monthCreated = monthNames[createdDateObject.getUTCMonth()];
    const yearCreated = createdDateObject.getUTCFullYear().toString().substring(2);
    const monthCurrent = monthNames[currentDateObject.getUTCMonth()];
    const yearCurrent = currentDateObject.getUTCFullYear().toString().substring(2);
    const formattedCreatedDate = `${monthCreated} ${yearCreated}`;
    const formattedCurrentDate = `${monthCurrent} ${yearCurrent}`;
    return (
      <Text>{formattedCreatedDate} - {formattedCurrentDate}</Text>
    )
  }


  async function removePayment(e, payment) {
    e.preventDefault()
    if (window.confirm("Tem certeza de que deseja remover pedido?")) {
      try {
        const response = await api.delete(`/api/v1/payments/${payment}`);
        if (response.data.success) {
          swal({
            title: "Ok",
            text: "Pedido removido",
            icon: "success",
          });
          fetchUser()
        } else {
          swal({
            title: "Opss",
            text: "Algo deu errado!!",
            icon: "error",
          });
        }
      } catch (error) {
        console.error('Error removing payment:', error);
        return false;
      }
    }
  }

  const getCardIcon = (brand) => {
    switch (brand) {
      case 'Visa':
        return <FaCcVisa size="1.3rem"/>;
      case 'Master Card':
        return <FaCcMastercard size="1.3rem"/>;
      default:
        return <FaCreditCard size="1.3rem"/>;
    }
  };



  return (<>
    <>
      <Navbar/>
      <Box
        background="transparent"
        margin={{base: "0px", md: ""}}
        height='auto'
        padding={{base: '0%', md: '1%'}}
        marginTop={{ base: "10rem", md: "6rem" }}
      >
        <Text
          fontSize={"30px"}
          color={theme.colors.logoOrange}
          fontWeight="bold"
          paddingLeft="30px"
        >Perfil</Text>
        <Form
          isOpen={isOpen}
          onClose={onClose}
          theme={theme}
          profile={profile}
          user={user}
          handleProfileChange={handleProfileChange}
          handleEditUser={handleEditUser}
          states={states}
          cities={cities}
          handleLocality={handleLocality}
          mapedCitiesForState={mapedCitiesForState}
          handleFileChange={handleFileChange}
          userPhoto={userPhoto}
          setUserDocument={setUserDocument}
          userDocument={userDocument}
          setUserPhoneNumber={setUserPhoneNumber}
          userPhoneNumber={userPhoneNumber}
          errors={errors}
        />
        <Box
          display={{base: 'block', md: 'flex'}}
          justifyContent={"space-between"}
          width={{base: '100%', md: '100%'}}
          px="2%"
          gap={{base: '10', md: ''}}
          margin={{base: 'auto', md: '0 auto'}}
        >
          <Box
            width={{base: '90%', md: '60%'}}
            margin={{base: 'auto', md: '0% 0 4% 0'}}
          >
            <Box border="" boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" padding="10px 30px 25px 30px" my="20px" borderRadius="10px">
              <Text
                fontWeight={"500"}
                fontSize={"22px"}
                marginTop={2}
                marginBottom={5}
                color="black"
              >
                Últimos Pedidos
              </Text>
              <Divider/>
              {paidOrders.length > 0 && (<>

                <Text mt="20px" fontWeight="500">Pedir novamente:</Text>
                <OrdersSlider orders={paidOrders.slice(0, 5)} cities={cities} states={states} type={"orderAgain"}/>
              </>)}
              {pendingOrders.length > 0 && (<>
                <Text mt="20px" mb="10px" fontWeight="500">Pendentes:</Text>
                <OrdersSlider orders={pendingOrders.slice(0, 20)} cities={cities} states={states} type={"pending"}/>
              </>)}
              {paidOrders.length > 0 && (<>
                <Text mt="20px" fontWeight="500">Concluídos:</Text>
                <OrdersSlider orders={paidOrders.slice(0, 20)} cities={cities} states={states} type={"paid"} />
              </>)}
              {orders.length <= 0 && !loadingPage && (
                <Text
                  textAlign={"center"}
                  fontSize={"25px"}
                  fontWeight={"bolder"}
                >
                  Nenhum pedido.
                </Text>
              )}
              {(orders.length <= 0 && loadingPage) && (
                <Box display='flex' justifyContent='center' padding='50px'>
                  <Spinner color={theme.colors.primary} margin='auto'/>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            width={{base: '90%', md: '40%'}}
            margin={{base: '30px auto 0px auto', md: '0% 0 4% 0'}}
          >
            <Box
              border="" boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" padding="10px 30px 25px 30px" my="15px" borderRadius="10px"
            >
              <Text
                fontWeight={"500"}
                fontSize={"22px"}
                marginTop={2}
                marginBottom={5}
                color="black"
              >
                Carteira
              </Text>
              <Divider/>
              <Box display='flex' justifyContent='space-between' pt="5%">
                <Stat>
                  <StatLabel>Créditos disponíveis</StatLabel>
                  <StatNumber>{userCredits.length > 0 && <span>{(userCredits.reduce((n, { amount }) => n + amount, 0)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span> || <span>R$0,00</span>}</StatNumber>
                  <StatHelpText>{formatDateDifference(profile.created_at)}</StatHelpText>
                </Stat>
                <Button
                  onClick={(e) => toggleCreditsHistory(e)}
                  background="transparent"
                  color={theme.colors.logoOrange}
                  borderColor={theme.colors.primary}
                  padding='0px'
                  alignItems='start'
                  display='flex'
                  gap={2}
                  _hover={{
                    color: `${theme.colors.secondaryGreen}`,
                    bg: "transparent",
                  }}
                >
                  Extrato de Créditos
                  {!creditHistorySection ?
                    <span><FaAngleDown></FaAngleDown></span>
                  :
                    <span><FaAngleUp></FaAngleUp></span>
                  }
                </Button>
              </Box>
              {creditHistorySection && (<>
                <TableContainer>
                  <Table variant='simple' color='black'>
                    <Thead>
                      <Tr width='100%'>
                        <Th textAlign='center' borderColor={theme.colors.primary}>Pedido</Th>
                        <Th textAlign='center' borderColor={theme.colors.primary}>Data</Th>
                        <Th textAlign='center' borderColor={theme.colors.primary}>Valor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userCredits.length > 0 && (
                        <>
                          {userCredits.map((credit) => (
                            <Tr width='100%' key={credit.id}>
                              <Td display="flex" justifyContent='center'  borderColor={theme.colors.primary}>
                                <Stat flex='0 1 1%'>
                                  <StatHelpText>
                                    {credit.amount > 0 ?  <StatArrow type='increase' /> : <StatArrow type='decrease' />}
                                  </StatHelpText>
                                </Stat>
                                #{credit.payment_id}
                              </Td>
                              <Td textAlign='center' marginBottom='-1px' borderColor={theme.colors.primary}>
                                {new Date(credit.created_at).toLocaleDateString('pt-BR')}
                              </Td>
                              <Td textAlign='center' borderColor={theme.colors.primary}>
                                {credit.amount.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}
                              </Td>
                            </Tr>
                          ))}
                        </>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Box display='flex' justifyContent='space-between' margin='10px 40px 0px 40px'>
                  <Text fontWeight='bolder' color={theme.colors.secondaryGreen}>Total</Text>
                  <Text fontWeight='bolder' color={theme.colors.secondaryGreen}>{(userCredits.reduce((n, { amount }) => n + amount, 0)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Text>
                </Box>
              </>)}
              {savedCards.length > 0 && (
                <>
                  <Divider orientation='horizontal' my="15px" display={{base: "block", md: "block"}} borderColor={theme.colors.primary}/>
                  <Text fontWeight="bold">
                    Meus Cartões
                  </Text>
                  <Box>
                  {savedCards.map((card) => (
                    <Box key={card.id} display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap="5px">
                        {getCardIcon(card.data.brand)}
                        <Text fontSize="14px">{card.data.display_number}</Text>
                        <Text fontSize="14px">{card.data.holder_name}</Text>
                        <Text fontSize="14px">{card.data.month}/{card.data.year}</Text>
                      </Box>
                      <Button
                        background="transparent"
                        border="none"
                        color="red"
                        onClick={() => handleRemovePaymentMethod(card.id)}
                        _hover={{
                          color: "orange"
                        }}
                      >
                        <FaTrashAlt />
                      </Button>
                    </Box>
                  ))}
                  </Box>
                </>
              )}
            </Box>
            <Box
              border="" boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" padding="10px 30px 25px 30px" my="15px" borderRadius="10px"
            >
              <Box display="flex" justifyContent="space-between">

                <Text
                  fontWeight={"500"}
                  fontSize={"22px"}
                  marginTop={2}
                  marginBottom={5}
                  color="black"
                >
                  Dados Pessoais
                </Text>
                <Box position='relative' display='inline-block'>
                  <Avatar
                    size={{ base: 'md', md: 'md' }}
                    display='block'
                    max-width='100%'
                    border={`2px solid ${theme.colors.logoOrange}`}

                    src={localStorage.getItem('userPhoto') !== "" ? localStorage.getItem('userPhoto') : 'https://img.freepik.com/free-icon/user_318-159711.jpg'}
                  />
                  <Button position='absolute' width='20px' bottom='0px' right='-5px' padding='0px' borderRadius='50%' size='xs'
                    onClick={onOpen}
                    background="rgba(245,245,245, .6)"
                    color={theme.colors.logoOrange}
                    border={`1px solid ${theme.colors.secondaryGreen}`}
                    _hover={{
                      color: `${theme.colors.secondaryGreen}`,
                    }}
                  >
                    <FaPen></FaPen>
                  </Button>
                </Box>
              </Box>
              <Divider pt="10px"/>
              <Box
                height={{ base: 'auto', md: "auto" }}
                borderRadius="0 0 15px 15px"
                marginBottom={{ base: '0px', md: '0px' }}
                width='100%'
              >
                <Flex alignItems='center' justifyContent="center" flexDirection={{ base: 'column', md: 'column' }}>
                  <Box width='100%' padding='20px 10px'>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Nome:</Text>
                      <Text>{profile.full_name ? <span>{profile.full_name}</span> : <span></span>}</Text>
                    </Flex>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Celular:</Text>
                      <Text>{profile.phone_number ? <span>{formatPhoneNumber(profile.phone_number)}</span> : <span></span>}</Text>
                    </Flex>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Documento:</Text>
                      <Text>{profile.document_number ? <span>{handleDocumentDisplay(profile.document_number)}</span> : <span></span>}</Text>
                    </Flex>
                    <Flex alignItems='center' gap={1} justifyContent='space-between'>
                      <Text fontSize='18px' fontWeight='500'>Email:</Text>
                      <Text>{profile.email ? <span>{profile.email}</span> : <span></span>}</Text>
                    </Flex>
                    {(profile.address_street || profile.address_number || profile.address_complement  || profile.address_neighborhood || profile.city_id || profile.state_id || profile.address_postal_code) && (<>
                      <Text fontSize='18px' fontWeight='500'>Endereço:</Text>
                      <Text>{profile.address_street ? <span>{profile.address_street}</span> : <span></span>}, {profile.address_number ? <span>{profile.address_number}</span> : <span></span>}{profile.address_complement ? <span>, {profile.address_complement}</span> : <span></span>}, {profile.address_neighborhood ? <span>{profile.address_neighborhood}</span> : <span></span>}</Text>
                      <Text>{profile.city_id ? <span>{cities.find(city => city.id === profile.city_id)?.name}</span> : <span></span>} - {profile.state_id ? <span>{states.find(state => state.id === profile.state_id)?.acronym}</span> : <span></span>}{profile.address_postal_code ? <span> | {profile.address_postal_code}</span> : <span></span>}</Text>
                    </>)}
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
    <Footer />
    </>
  )
}

import {
  Button,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Stat,
  StatHelpText,
  StatArrow,
  Divider
} from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { theme } from "../../styles/theme";
import { FaShareAlt, FaEye, FaTimes } from 'react-icons/fa';
import swal  from 'sweetalert';

export function ProductPageCard({
  id,
  name,
  brand,
  marketPrice,
  prices,
  batch,
  productPhotoUrl,
  productFlyerUrl,
  quantityPerBox,
  size,
  productType,
  suggestedPriceForSale,
  description
}) {

  const { id: productId } = useParams()
  const { min, max } = (prices || []).reduce((acc, cur) => {
    if (cur.price_per_unity < acc.min) {
      acc.min = cur.price_per_unity;
    }
    if (cur.price_per_unity > acc.max) {
      acc.max = cur.price_per_unity;
    }
    return acc;
  }, { min: Infinity, max: -Infinity }) || {};

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleImageExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const shareProductLink = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(e.target.dataset.copyText).then((error) => {
      swal({
        title: "👍",
        text: "Link copiado!",
        icon: "success",
      });
    })
  }

  const productTypeName = productType?.name ? productType.name.replace(/(%20|\s+)/g, "+") : "";
  const productName = name ? name.replace(/(%20|\s+)/g, "+") : "";
  const minUnity = prices.reduce((min, current) => (current.unity < min ? current.unity : min), prices[0]?.unity);
  return (<>
    <Box
      direction={{ base: 'column', sm: 'column' }}
      // background={theme.colors.white500}
      borderRadius={"15px"}
      border={"none"}
      height='auto'
      width={{ base: '100%', md: '100%' }}
      marginBottom={{ base: '20px', md: '0' }}
      display='flex'
      justifyContent='start'
      flexDirection='column'
      paddingBottom='30px'
      position='relative'
      boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
    >
      <Image
        objectFit='cover'
        width={{ base: 'auto', md: '' }}
        src={productPhotoUrl || require('../../assets/images/placeholder.webp')}
        alt='Boozen'
        onClick={toggleImageExpansion}
        borderRadius='15px 15px 0 0'
        border={"none"}
        height='15rem'
        loading="lazy"
        // boxShadow={"rgba(0, 0, 0, 0.25) 0px 5px 15px;"}
      />
      <Box
        padding="0px 15px 5px 15px"
      >
        <Button
          background={theme.colors.whiteSmoke}
          id="copy-paste"
          padding='0'
          margin='0'
          border='1px solid'
          borderRadius='50%'
          fontSize='12px'
          data-copy-text={`Confira essa oferta na Boozen - ${name} - ${brand}: http://boozen.com.br/produtos/${productTypeName}/${productName}`}
          onClick={(e) => shareProductLink(e)}
          _hover={{ bg: `${theme.colors.primary}`, color: `${theme.colors.whiteSmoke}`, borderColor: `${theme.colors.primary}` }}
          position='absolute'
          right='1rem'
          top='14rem'
          color={theme.colors.primary}
          width='1.8rem'
          height='1.8rem'
          minWidth='none'
        >
          <FaShareAlt></FaShareAlt>
        </Button>
        <Text fontWeight='bold' fontSize="18px" marginTop='10px'>{name}</Text>
        <Text fontWeight='350' fontSize="18px" marginBottom='0px'>{brand}</Text>
        <Box>
          {productFlyerUrl && (
            <Button onClick={onOpen} background="transparent" padding="0" textDecoration="none" fontWeight="400" _hover={{ bg: `transparent`, color: '#D44120' }}>Preço no mercado: <Text textDecoration='line-through'>{marketPrice?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Text> <Text marginLeft='5px'><FaEye></FaEye></Text></Button>
          )}
          <Box display="flex" justifyContent='space-between' alignItems='center' marginTop='10px'>
            <Stat marginBottom='0px'>
              <StatHelpText  marginBottom='0px' display="flex" alignItems='center' fontSize='18px'>
                <StatArrow color="green" type='decrease' />
                <span>{batch && (batch.discount_per_centage)}% desconto na</span> <Text marginLeft="5px" color={theme.colors.primary} fontWeight='bold' translate="no">BOO</Text> <Text color={theme.colors.primaryGreen} fontWeight='bold' translate="no">ZEN</Text>
              </StatHelpText>
            </Stat>
          </Box>
          <Text color={theme.colors.secondaryGreen} fontSize='20px' fontWeight='bolder' marginBottom='10px'>
            {(min).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} {min !== max ? `a ${max.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}` : ""}
          </Text>
          <Text
            fontWeight='350'
            fontSize="18px"
          >
            Mínimo: {quantityPerBox * minUnity} {size ? <span>{size}</span> : <span></span>}{quantityPerBox > 1 && size ? <span>s</span> : <span></span>}
          </Text>
          {suggestedPriceForSale && (
            <Box display="flex" gap="5px">
              <Text textAlign={{base: 'start', md: 'start'}} fontWeight='350' fontSize="14px">Sugestão de preço de revenda: R${suggestedPriceForSale.toFixed(2)}</Text>
            </Box>
          )}

          {description && (<>
            <Divider my="10px"/>
            <Box display="" gap="5px">
              <Text fontWeight='500'>Descrição:</Text>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Box>
          </>)}
        </Box>
      </Box>
    </Box>
    {isExpanded && (
      <Box position='fixed' top='0' left='0' width='100%' height='100vh' bg='rgba(0, 0, 0, .5)' display='flex' justifyContent='center' alignItems='center' zIndex='100000' className="expanded-image-overlay" onClick={toggleImageExpansion}>
        <Box position='relative'>
          <Image
            src={productPhotoUrl}
            alt="Boozen"
            borderRadius='15px'
            border='none'
            width='auto'
            height='auto'
            maxHeight='97vh'
            loading="lazy"
            boxShadow='rgba(0, 0, 0, 0.25) 0px 5px 15px;'
          />
          <Text
            position='absolute'
            top='10px'
            right='10px'
            color='orange'
            onClick={(e) => {
              e.stopPropagation();
              toggleImageExpansion();
            }}
          >
            <FaTimes />
          </Text>
        </Box>
      </Box>
    )}
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Pesquisa {name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image
            src={productFlyerUrl}
            alt='flyer'
          />
        </ModalBody>
      </ModalContent>
    </Modal>

  </>)
}

import { Box, Input, Text, Image, Button, Spinner, Divider, Progress, Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
 } from "@chakra-ui/react";
import { useEffect, useState, useRef, useContext } from "react";
import { api } from "../../services/api";
import { setFinalPriceForCartBatch } from "../../services/setFinalPriceForCartBatch";
import { theme } from "../../styles/theme";
import { useAuth } from "../../Hooks/useAuth";
import { BsDash, BsPlus } from 'react-icons/bs';
import { debounce } from 'lodash'
import { FaRegTimesCircle} from 'react-icons/fa';
import swal  from 'sweetalert';
import { CartContext } from "../../Context/CartContext";
import { FaCreditCard, FaMoneyBill, FaTrashAlt  } from "react-icons/fa";


export function CartComponent() {
  const [cartBatches, setCartBatches] = useState([]);
  const { user, signInUser, getUserInfo, signUpUser } = useAuth();
  const [batches, setBatches] = useState([]);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [userCredits, setUserCredits] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);

  const [amountAlreadyPurchased, setAmountAlreadyPurchased] = useState([]);
  const [useCredit, setUseCredit] = useState(false);

  const [loadingPage, setLoadingPage] = useState(true);


  const { decrementCartCounter } = useContext(CartContext);
  const [userAddress, setUserAddress] = useState({
    city: "",
    complement: "",
    neighborhood: "",
    number: "",
    postal_code: "",
    state: "",
    street: ""
  });

  const allowedCities = [
    'Belo Horizonte',
    'Nova Lima',
    'Contagem',
    'Betim',
    'Lagoa Santa'
  ];

  async function fetchOpenOrders() {
    if (user) {
      const response = await api.get(`/api/v1/users/${user.id}`)
      setOpenOrders(response.data.order_total_info.filter(e => e.payment.status === "pending").sort((a, b) => new Date(b.payment.created_at) - new Date(a.payment.created_at)))
    }
  }

  useEffect(() => {
    fetchOpenOrders();
  }, [])



  async function fetchCartBatches() {
    setLoadingPage(true);
    if (user) {
      try {
        const response = await api.get(`/api/v1/cart_batches?user_id=${user.id}`);
        setCartBatches(response.data.cart_batches)
        setBatches(response.data.batches)
        setProducts(response.data.products)
        setPrices(response.data.prices)
        setPhotos(response.data.photos)
        setAmountAlreadyPurchased(response.data.amount_already_purchased)
        setUserCredits(response.data.user_credits)
      } catch (error) {
        console.error("Error fetching user cart:", error);
      }
    } else {
      // const cartData = localStorage.getItem('guestCart');
      // const guestCart = cartData ? JSON.parse(cartData) : [];
      // const guestBatches = guestCart.map(item => ({
      //   product_id: item.product_id,
      //   batch_id: item.batch_id,
      //   quantity: item.quantity,
      //   amount: parseFloat(item.amount),
      // }));

      // setCartBatches(guestBatches);

      // // const batchIds = guestBatches.map(item => item.batch_id).filter(batchId => batchId && batchId.toString().trim() !== "");
      // // const batchIds = guestBatches
      // //   .map(item => item.batch_id)
      // //   .filter(batchId => batchId && batchId.toString().trim() !== "");


      // const batchIds = ["866", "916", "", "883", "1081"];
      // console.log(batchIds);

      // if (batchIds.length > 0) {
      //   try {
      //     const response = await api.get(`/api/v1/cart_batches/get_guest_cart?batch_ids=${batchIds}`);
      //     setBatches(response.data.batches);
      //     setProducts(response.data.products);
      //     setPrices(response.data.prices);
      //     setPhotos(response.data.photos);
      //     setAmountAlreadyPurchased(response.data.amount_already_purchased);
      //     setUserCredits([]);
      //   } catch (error) {
      //     console.error("Error fetching batch/product details:", error);
      //   }
      // }
      const cartData = localStorage.getItem("guestCart");
      const guestCart = cartData ? JSON.parse(cartData) : [];
      const guestBatches = guestCart.map((item) => ({
        product_id: item.product_id,
        batch_id: item.batch_id,
        quantity: item.quantity,
        amount: parseFloat(item.amount),
      }));

      setCartBatches(guestBatches);

      const batchIds = guestBatches
        .map((item) => item.batch_id)
        .filter((batchId) => batchId && batchId.toString().trim() !== "");

      if (batchIds.length > 0) {
        try {
          const response = await api.get(
            `/api/v1/cart_batches/get_guest_cart?batch_ids=${batchIds}`
          );

          // Check for missing batches
          const { missing_batch_ids, batches, products, prices, photos } =
            response.data;

          if (missing_batch_ids && missing_batch_ids.length > 0) {
            // Filter out missing batches from the local guestCart
            const updatedGuestCart = guestCart.filter(
              (item) => !missing_batch_ids.includes(item.batch_id.toString())
            );
            alert("Some items in your cart are no longer available and have been removed.");
            localStorage.setItem("guestCart", JSON.stringify(updatedGuestCart));
            setCartBatches(updatedGuestCart);
          }

          setBatches(batches);
          setProducts(products);
          setPrices(prices);
          setPhotos(photos);
          setAmountAlreadyPurchased(response.data.amount_already_purchased);
          setUserCredits([]);
        } catch (error) {
          console.error("Error fetching batch/product details:", error);
        }
      }
    }
    setLoadingPage(false);
  }

  useEffect(() => {
    fetchCartBatches();
  }, [user])



  const delayedQuery = useRef(
    debounce(async cartBatch => {
      const dataObject = new FormData();
      dataObject.append("cart_batch[amount]", cartBatch.amount);
      dataObject.append("cart_batch[quantity]", cartBatch.quantity);
      const response = await api.put(`/api/v1/cart_batches/${cartBatch.id}`, dataObject);
    }, 1000)
  ).current;

  function handleIncrement(cartBatch) {
    let updatedCartBatch;
    setAmountAlreadyPurchased(amountAlreadyPurchased + 1)
    const currentCartBatches = [...cartBatches]
    updatedCartBatch = currentCartBatches.find(e => e.batch_id === cartBatch.batch_id)
    ++updatedCartBatch.quantity
    const productId = batches.find(element => element.id === updatedCartBatch.batch_id).product_id
    const quantityPerBox = products.find(e => e.id === productId).quantity_per_box
    const sortedPrices = prices.filter(e => e.batch_id === updatedCartBatch.batch_id).sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (updatedCartBatch.quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    updatedCartBatch.amount = quantityPerBox * updatedCartBatch.quantity * selectedPrice.price_per_unity
    setCartBatches(currentCartBatches);
    if (user) {
      delayedQuery(cartBatch)
    } else {
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      const updatedGuestCart = guestCart.map(item =>
        item.batch_id === cartBatch.batch_id
          ? {
              ...item,
              quantity: updatedCartBatch.quantity,
              amount: updatedCartBatch.amount
          }
          : item
      );
      localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
    }
  }



  function handleDecrement(cartBatch) {
    if (cartBatch.quantity <= 1 ) {
      document.getElementById("button-decrement").disabled = true
      return
    }
    let updatedCartBatch;
    setAmountAlreadyPurchased(amountAlreadyPurchased - 1)
    const currentCartBatches = [...cartBatches]
    updatedCartBatch = currentCartBatches.find(e => e.batch_id === cartBatch.batch_id)
    --updatedCartBatch.quantity
    const productId = batches.find(element => element.id === updatedCartBatch.batch_id).product_id
    const quantityPerBox = products.find(e => e.id === productId).quantity_per_box
    const sortedPrices = prices.filter(e => e.batch_id === updatedCartBatch.batch_id).sort((a, b) => a.unity - b.unity);
    let selectedPrice = sortedPrices[0];
    for (let i = 0; i < sortedPrices.length; i++) {
      if (updatedCartBatch.quantity >= sortedPrices[i].unity) {
        selectedPrice = sortedPrices[i];
      } else {
        break;
      }
    }
    updatedCartBatch.amount = quantityPerBox * updatedCartBatch.quantity * selectedPrice.price_per_unity
    setCartBatches(currentCartBatches)
    if (user) {
      delayedQuery(cartBatch)
    } else {
      const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
      const updatedGuestCart = guestCart.map(item =>
        item.batch_id === cartBatch.batch_id
          ? {
              ...item,
              quantity: updatedCartBatch.quantity,
              amount: updatedCartBatch.amount
          }
          : item
      );
      localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
    }
  }


  async function hendleRemoveCartBatch(e, cartBatch) {
    e.preventDefault()
    const userId = localStorage.getItem('userId');
    if (window.confirm("Tem certeza de que deseja continuar?")) {
      const matchingBatch = batches.find(batch => batch.id === cartBatch.batch_id)
      decrementCartCounter();
      if (matchingBatch) {
        const matchingProduct = products.find(product => product.id === matchingBatch.product_id);
        if (matchingProduct) {
          window.gtag('event', 'remove_from_cart', {
            currency: "BRL",
            value: cartBatch.amount,
            'items': [{
              'item_name': matchingProduct.name,
              'item_id': matchingProduct.id,
              'price': cartBatch.amount,
              'quantity': cartBatch.quantity
            }]
          });
        } else {
          console.error('Matching product not found.');
        }
      } else {
        console.error('Matching batch not found.');
      }
      if (user) {
        try {
          const response = await api.delete(`/api/v1/cart_batches/${cartBatch.id}`);
          if (response.status === 204) {
            const updatedBatches = cartBatches.filter(batch => batch.id !== cartBatch.id);
            setCartBatches(updatedBatches);
            swal({
              title: "Ok",
              text: "Produto removido",
              icon: "success",
            });
          } else {
            swal({
              title: "Opss",
              text: "Algo deu errado!!",
              icon: "error",
            });
          }
        } catch (error) {
          console.error('Error removing cart batch:', error);
          return false;
        }
      } else {
        try {
          const guestCart = JSON.parse(localStorage.getItem('guestCart')) || [];
          const updatedGuestCart = guestCart.filter(item => item.batch_id !== cartBatch.batch_id);
          localStorage.setItem('guestCart', JSON.stringify(updatedGuestCart));
          updateCartState(cartBatch);
          swal({
            title: "Ok",
            text: "Produto removido do carrinho",
            icon: "success",
          });
        } catch (error) {
          console.error('Error removing cart batch:', error);
          return false;
        }
      }
    }
  }

  function updateCartState(cartBatch) {
    const updatedCartBatches = cartBatches.filter(item => item.batch_id !== cartBatch.batch_id);
    console.log(updatedCartBatches)
    setCartBatches(updatedCartBatches);
    const removedProductBatch = batches.find(batch => batch.id === cartBatch.batch_id);
    if (removedProductBatch) {
      const updatedProducts = products.filter(product => product.id !== removedProductBatch.product_id);
      setProducts(updatedProducts);
      const updatedBatches = batches.filter(batch => batch.id !== cartBatch.batch_id);
      setBatches(updatedBatches);
    }
    swal({
      title: "Ok",
      text: "Produto removido do carrinho",
      icon: "success",
    });
  }


  const totalCartAmount = cartBatches.reduce((acc, batch) => acc + batch.amount, 0);
  const percentageOf120 = (totalCartAmount * 100) / 120;
  const amountLeft = 120 - totalCartAmount;
  let colorScheme;

  if (percentageOf120 < 25) {
    colorScheme = 'red';
  } else if (percentageOf120 < 75) {
    colorScheme = 'yellow';
  } else {
    colorScheme = 'green';
  }


  async function removePayment(e, payment) {
    e.preventDefault()
    if (window.confirm("Tem certeza de que deseja remover pedido?")) {
      try {
        const response = await api.delete(`/api/v1/payments/${payment}`);
        if (response.data.success) {
          // swal({
          //   title: "Ok",
          //   text: "Pedido removido",
          //   icon: "success",
          // });
          fetchOpenOrders()
        } else {
          // swal({
          //   title: "Opss",
          //   text: "Algo deu errado!!",
          //   icon: "error",
          // });
        }
      } catch (error) {
        console.error('Error removing payment:', error);
        return false;
      }
    }
  }

  const handlePayment = (e, paymentId) => {
    window.location = `/pagamentos/${paymentId}`
  }

  return (
    <Box
      px="10px"
    >
      <Box gap='10px' width='100%'>
        {(cartBatches.length <= 0 && loadingPage) && (
          <Box display='flex' justifyContent='center' padding='50px'>
            <Spinner color={theme.colors.primary} margin='auto'/>
          </Box>
        )}
        {(cartBatches.length === 0 && !loadingPage && openOrders.length === 0) && (
          <Text
            textAlign={"center"}
            fontSize={"24px"}
            color={theme.colors.primaryGreen}
            fontWeight={"bold"}
            py='30px'
          >
            Nenhum item adicionado ao carrinho.
          </Text>
        )}
        {(cartBatches.length === 0 && !loadingPage && openOrders.length > 0) && (
          <>
            {/* Text to display before the iteration */}
            <Text fontSize="md" fontWeight="500" mb={4}>
              Últimos Pedidos em aberto:
            </Text>

            {openOrders.slice(-2).map((openBatchOrder, index) => {
              return (
                <Accordion allowToggle key={openBatchOrder.payment.id}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton display="flex" justifyContent="space-between" alignItems="center">
                        <Box as="span" display="flex" gap={2} alignItems="center">
                          {openBatchOrder.payment.payment_method === "credit_card" && (
                            <Box display='flex' gap={2} alignItems='center'>
                              <FaCreditCard />
                              <Text>Cartão de crédito</Text>
                            </Box>
                          )}
                          {openBatchOrder.payment.payment_method === "pix" && (
                            <Box display='flex' gap={2} alignItems='center'>
                              <FaMoneyBill />
                              <Text>Pix</Text>
                            </Box>
                          )}
                        </Box>

                        <Button
                          onClick={(e) => removePayment(e, openBatchOrder.payment.id)}
                          colorScheme="red"
                          size="sm"
                          background="transparent"
                          color="red"
                          fontSize="md"
                          _hover={{
                            background: 'transparent',
                            color: 'red'
                          }}
                        >
                          <FaTrashAlt />
                        </Button>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {openBatchOrder.batch_details.map((batch_order, index) => (
                        <Box key={index}>
                          <Box
                            display={"flex"}
                            flexDirection="column"
                            justifyContent='space-between'
                            margin={"10px 0"}
                            gap={"20%"}
                          >
                            <Box display={"flex"} justifyContent='space-between'>
                              <Text fontWeight="500">Quantidade:</Text>
                              <Text>
                                {batch_order.item.quantity} {batch_order.batch.unity_of_measurement}{batch_order.item.quantity > 1 ? <span>s</span> : <span> </span>}
                              </Text>
                            </Box>
                            <Box display={"flex"} justifyContent='space-between'>
                              <Text fontWeight="500">Produto:</Text>
                              <Text>
                                {batch_order.product.name.length > 25
                                  ? <span>{batch_order.product.name.slice(0, 25)}...</span>
                                  : <span>{batch_order.product.name}</span>}
                              </Text>
                            </Box>
                            <Box display={"flex"} justifyContent='space-between'>
                              <Text fontWeight="500">Subtotal:</Text>
                              <Text>
                                {batch_order.item.amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                      <Divider />
                      <Box display={"flex"} justifyContent='space-between'>
                        <Text fontWeight="500">Total:</Text>
                        <Text>
                          {openBatchOrder.batch_orders.reduce((n, { amount }) => n + amount, 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                        </Text>
                      </Box>

                      <Button
                        background={theme.colors.secondaryGreen}
                        color='white'
                        borderColor={theme.colors.secondaryGreen}
                        size="sm"
                        borderRadius="20px"
                        fontSize='sm'
                        mt="20px"
                        mb="10px"
                        width="100%"
                        _hover={{
                          background: "transparent",
                          borderColor: theme.colors.green500,
                          color: theme.colors.secondaryGreen
                        }}
                        onClick={(e) => handlePayment(e, openBatchOrder.payment.id)}
                      >
                        PAGAR
                      </Button>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </>
        )}

        {cartBatches.length > 0 && cartBatches.map((cartBatch, index) => {
          return (<>
            <Box display="flex" justifyContent="space-between" gap="5px" alignItems="center">
              <Image
                objectFit='cover'
                maxW={{ base: '100%', sm: '200px' }}
                width="50px"
                height="50px"
                src={photos.find(element => element.product === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.photo || require('../../assets/images/home-cover.webp')}
                alt='Produto'
                maxHeight='16.5rem'
                borderRadius="50%"
              />
              <Text pb='2'>{products.find(element => element.id === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.name}</Text>

              <Button
                onClick={(e) => hendleRemoveCartBatch(e, cartBatch)}
                colorScheme="red"
                size="sm"
                background='transparent'
                color='red'
                fontSize='lg'
                _hover={{
                  background: 'transparent',
                  color: 'red'
                }}
              >
                <FaRegTimesCircle></FaRegTimesCircle>
              </Button>
            </Box>

            <Box my={2} display={{base: 'flex', md: 'flex'}} width='100%' justifyContent='space-between' alignItems='center'>
              <Box display='flex' marginTop={{base: '10px', md: '0'}}  width='50%' marginBottom='0' alignItems='center' padding='5px' border={{base: '1px solid rgba(212,65,32, .3)', md: '1px solid rgba(212,65,32, .3)'}} borderRadius='50px' height="30px">
                <BsDash
                  size={24}
                  weight="fill"
                  cursor="pointer"
                  id="button-decrement"
                  onClick={(e) => handleDecrement(cartBatch)}
                />
                <Input
                  id={`cart${cartBatch.id}-quantity`}
                  margin={"0px"}
                  type="number"
                  width={{base: '', md: ''}}
                  padding='0%'
                  background={"transparent"}
                  fontWeight={"bold"}
                  textAlign="center"
                  border="none"
                  value={cartBatch.quantity}
                  readOnly={true}
                />
                <BsPlus
                  size={24}
                  weight="fill"
                  cursor="pointer"
                  margin={"auto"}
                  id="button-increment"
                  onClick={(e) => handleIncrement(cartBatch)}
                />
              </Box>
              {setFinalPriceForCartBatch(cartBatch)}
            </Box>
            <Text my={2} textAlign={{base: 'start', md: 'start'}} fontSize="12px">{cartBatch.quantity} {batches?.find(element => element.id === cartBatch.batch_id)?.unity_of_measurement}{cartBatch.quantity > 1 ? 's' : ''} / {cartBatch.quantity * products?.find(element => element.id === (batches?.find(element => element.id === cartBatch.batch_id).product_id))?.quantity_per_box} {products.find(element => element.id === (batches.find(element => element.id === cartBatch.batch_id).product_id))?.size}</Text>
            <Divider orientation='horizontal' borderColor="gray" borderBottomWidth='2px' margin='10px auto'></Divider>

          </>);
        })}

        {totalCartAmount < 120 && (<>
          <Progress
            margin="20px auto"
            borderRadius="3px"
            colorScheme={colorScheme}
            width="100%"
            size="sm"
            value={percentageOf120}
          />
          <Text textAlign="center">+ {amountLeft.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} para Frete grátis</Text>
        </>)}
        {totalCartAmount >= 120 && (
          <Text textAlign="center" color="green">Frete grátis</Text>
        )}
        <Box display='flex' justifyContent='space-between' my='30px' mx="20px" alignItems="center">
          <Text fontSize="14px" fontWeight="light">Subtotal(itens):</Text>
          <Text fontWeight="bold">{totalCartAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
        </Box>
        {cartBatches.length > 0 && (
          <Button
            as="a"
            href='/carrinho'
            rel="noopener noreferrer"
            display='flex'
            alignItems='center'
            justifyContent='center'
            gap='10px'
            width='100%'
            bg={theme.colors.secondaryGreen}
            color="white"
            fontWeight="bold"
            borderRadius="20px"
            border={`1px solid ${theme.colors.secondaryGreen}`}
            marginTop="20%"
            _hover={{
              background: "transparent",
              color: `${theme.colors.secondaryGreen}`,
              borderCorlor: "green"
            }}
          >
            Finalizar compra
          </Button>
        )}
      </Box>
    </Box>
  )
}

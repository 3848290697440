import { Box, Button, Input, Text} from "@chakra-ui/react";
import { useState } from "react";
import { useAuth } from "../../../Hooks/useAuth";
import { api } from "../../../services/api";
import { EditSubcategory } from "../EditSubcategory";
import { theme } from "../../../styles/theme";
import { FaPlus } from "react-icons/fa";
import swal  from 'sweetalert';

export function SubCategoryCard({allSubcategories}) {
  const { user } = useAuth();
  const [subcategoryName, setSubcategoryName] = useState("")
  const [subcategories, setSubcategories] = useState(allSubcategories)
  const [toggleSubcategoryCreation, setToggleSubcategoryCreation] = useState(false);

  async function handleCreateSubCategory(e) {
    e.preventDefault();
    if (subcategoryName === "") {
      swal({
        title: "Ops",
        text: "Nome não pode ficar em branco.",
        icon: "info",
      });
      return
    }
    const data = {
      name: subcategoryName,
      user_id: user.id
    }
    const response = await api.post("api/v1/subcategories", data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
      },
    })
    if (response.status === 200)  {
      subcategories.push(response.data)
      setSubcategoryName("")
      swal({
        title: "Ok",
        text: "Subcategoria criada com sucesso!.",
        icon: "success",
      });
    } else {
      swal({
        title: "Opa",
        text: "Algo deu errado.",
        icon: "error",
      });
    }
  }

  const updateSubcategoryName = (subcategoryId, newName) => {
    const updatedSubcategories = subcategories.map((subcategory) =>
      subcategory.id === subcategoryId
        ? { ...subcategory, name: newName }
        : subcategory
    );
    setSubcategories(updatedSubcategories);
  };

  const handleToggleShowCreationOfSubcategory = () => {
    setToggleSubcategoryCreation(!toggleSubcategoryCreation);
  }

  return (
    <Box >
      <Box>
        <Button
          type="button"
          onClick={handleToggleShowCreationOfSubcategory}
          background="transprent"
          _hover={{ bg: "transparent", color: `${theme.colors.primary}` }}

        >
          <FaPlus></FaPlus> Cadastrar nova subcategoria
        </Button>
        {toggleSubcategoryCreation && (<>
          <Input
            placeholder="Nome da Subcategoria"
            onChange={(e) => setSubcategoryName(e.target.value)}
            value={subcategoryName}
          />
          <Button
            margin="20px 0"
            onClick={(e) => handleCreateSubCategory(e)}
          >
            Cadastrar
          </Button>
        </>)}
      </Box>
      <Box>
        <Text textAlign="center" marginBottom="20px" fontSize="28px" fontWeight="bolder">Subcategorias</Text>
        {subcategories?.map((subcategory, index) => {
          return (
            <>
              <Box
                key={subcategory.id}
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
                padding="10px 0"
              >
                <Text>{subcategory.name}</Text>
                {/* <Button>Editar</Button> */}
                <EditSubcategory
                  subcategoryId={subcategory.id}
                  onUpdateSubcategory={updateSubcategoryName}
                />
              </Box>
              <hr />
            </>
          )
        })}
      </Box>
    </Box>

  )
}

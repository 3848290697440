import { Box, Image  } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageUrls = [
    {
      url: require("../../assets/images/cover-1.webp"),
      bannerUrl: `/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`,
      bannerTitle: "Cupom",
      titleColor: "White",
      bannerSubTitle: "FAÇAFESTA",
    },
    {
      url: require("../../assets/images/cover-2.webp"),
      bannerUrl: `/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`,
      bannerTitle: "FRETE GRÁTIS",
      titleColor: theme.colors.logoOrange,
      bannerSubTitle: "Nas compras acima de R$120,00",
    },
    {
      url: require("../../assets/images/cover-3.webp"),
      bannerUrl: `/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`,
      bannerTitle: "DESCONTO NA PRIMEIRA COMPRA",
      titleColor: "white",
      bannerSubTitle: "Ganhe agora 5% de desconto",
    }
  ];

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % imageUrls.length;
    setCurrentIndex(newIndex);
  };

  const handleDotClick = (index) => {
    console.log("Dot clicked with index:", index);
    setCurrentIndex(index);
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3500, // Set the interval to 3 seconds
    appendDots: dots => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "10px",
          bottom: '-5%'
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <Box width="100%" height={{base: "28vh", md: "93vh"}}>
      <Slider {...settings}>
        {imageUrls.map((imageUrl, index) => (<>
          <Box
            key={index}
            height={{base: "28vh", md: "93vh"}}
            width={{ base: '100%', md: '100%' }}
            position="relative"
          >
            <Link to={imageUrl.bannerUrl}>
              <Image src={imageUrl.url} alt={`image ${index}`} w="100%" h="100%" objectFit="cover" backgroundPosition="center" />
              {/* <Box position="absolute" bottom={{ base: '10%', md: '10%' }} left={{ base: '3%', md: '5%' }} zIdex="10000" w={{base: "100%", md: "50%"}}>
                <Text marginBottom="0px"  fontSize={{ base: '75px', md: '100px' }} fontWeight="900" color={imageUrl.titleColor} textAlign="start"
                  textshadow="1px 1px 2px black"
                  lineHeight="5.5rem"
                >{imageUrl.bannerTitle}</Text>
                <Text marginBottom="0px" marginTop="0px" fontSize={{ base: '40px', md: '50px' }} fontWeight="700" color="white" textAlign="start"
                  textshadow="1px 1px 2px black"
                >{imageUrl.bannerSubTitle}</Text>
                <Box
                  textAlign="start"
                  margin="10px 0px 0px 50px"
                >
                  <Button
                    style={{ textDecoration: 'none', fontWeight: 'bold', color: 'white' }}
                    background="green"
                    _hover={{ bg: 'orange', color: 'white' }}
                    textAlign="center"
                    borderRadius="50px"
                    width="20rem"
                    height="6rem"

                  >
                    <Link to={imageUrl.bannerUrl}>
                      GARANTA JÁ
                    </Link>
                  </Button>
                </Box>
              </Box> */}
            </Link>
          </Box>
        </>))}
      </Slider>
    </Box>
  );
}

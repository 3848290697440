import React, { useEffect, useContext } from 'react';
import {
  Box,
  Text,
  Button,
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { Link } from 'react-router-dom';
import { CartContext } from '../../Context/CartContext';

export function AddToCartPopUp() {
  const { cartItemsCounter } = useContext(CartContext);

  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, []);


  return (
    <>
      <Box
        position="fixed"
        bottom="0"
        left="0"
        width="100%"
        bg="rgba(255, 255, 255, 0.95)" // Slightly transparent white background
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        padding="1rem"
        zIndex="1000"
        // height="10rem"
        boxShadow="0 -2px 5px rgba(0, 0, 0, 0.1)" // Subtle shadow at the top
        textAlign="center"
      >
        <Text color={theme.colors.primaryGreen} fontSize="22px" mb="0">
          Produto Adicionado ao carrinho!!!
        </Text>
        <Text mb="0">O que deseja fazer?</Text>
        <Box display="flex" gap="1rem" justifyContent="center" alignItems="center">
          <Link to={`/produtos?filter=${encodeURIComponent(JSON.stringify(["Bebidas"]))}`}>
            <Button
              color={theme.colors.secondaryGreen}
              bg="transparent"
              border={`1px solid ${theme.colors.secondaryGreen}`}
              borderRadius="20px"
              _hover={{
                background: `${theme.colors.secondaryGreen}`,
                color: "white",
                border: `1px solid ${theme.colors.secondaryGreen}`,
              }}
            >
              Continuar Comprando
            </Button>
          </Link>
          <Link to="/carrinho" style={{ textDecoration: "none" }}>
            <Button
              bg={theme.colors.secondaryGreen}
              color="white"
              borderRadius="20px"
              border={`1px solid ${theme.colors.secondaryGreen}`}
              _hover={{
                background: "transparent",
                color: `${theme.colors.secondaryGreen}`,
                border: `1px solid ${theme.colors.secondaryGreen}`,
              }}
            >
              Ver Carrinho ({cartItemsCounter})
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}

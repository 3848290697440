import React, { useState,useEffect } from 'react';
import {
  Box, Button, Input, Text, Select, Spinner, FormLabel, Checkbox
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { api } from "../../services/api";
import $ from 'jquery';
import { CardNumberInputMask } from '../CardNumberInputMask'
import { CardVerificationInputMask } from '../CardVerificationInputMask'
import { CardExpirationInputMask } from '../CardExpirationInputMask'




export function CardFormPayment({
    payment,
    batchOrders,
  }) {
  const [showPaymentLoading, setShowPaymentLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [saveCard, setSaveCard] = useState("off");
  const [savedCards, setSavedCards] = useState([]);
  const [cardUsed, setCardUsed] = useState("new");
  const [selectedSavedCard, setSelectedSavedCard] = useState(null);
  const [cardData, setCardData] = useState({
    number: '',
    verificationValue: '',
    fullName: '',
    expirationMonth: '',
    expirationYear: '',
    numberOfInstallments: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCardData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    setSaveCard(event.target.checked ? "on" : "off");
  };

  // useEffect(() => {
  //   api.get('/api/v1/payments/saved_cards').then((response) => {
  //     if (!response.data.mesage) {
  //       setSavedCards(response.data.saved_cards);
  //     } else {
  //       setSavedCards([]);
  //     }
  //   }).catch(() => {
  //     setSavedCards([]);
  //   });
  // }, []);

  useEffect(() => {
    const loadIuguScript = () => {
      if (document.querySelector("#payment-form")) {
        const script = document.createElement("script");
        script.src = "https://js.iugu.com/v2";
        script.onload = () => {
          const iugu = window.Iugu;
          iugu.setAccountID("FA1E8C4E4554443EAE03F5C94A781AAB");
          iugu.setTestMode(process.env.NODE_ENV === 'development');

          $("#payment-form").on("submit", function (event) {
            event.preventDefault();
            setShowPaymentLoading(true);

            const form = $(this);
            const tokenResponseHandler = (data) => {
              if (data.errors) {
                alert("Erro salvando cartão: " + JSON.stringify(data.errors));
                setShowPaymentLoading(false);
              } else {
                $("#token").val(data.id);
                const regex = /\/(\d+)$/;
                const match = window.location.pathname.match(regex);
                const paymentId = match[1];
                const selectElement = document.getElementsByName('numberOfInstallments')[0];
                const installments = selectElement ? selectElement.value : 1;
                api.post(`/api/v1/payments/${paymentId}/pay_with_card`, {
                  token: data.id,
                  number_of_installments: installments
                }).then(response => {
                  console.log("OK");
                }).catch(error => {

                  console.error("Error submitting payment:", error);
                });

                if (window && window.gtag) {
                  const payment = {};
                  window.gtag("event", "purchase", {
                    transaction_id: payment.id,
                    value: (payment.price_in_cents / 100).toFixed(2),
                    currency: "BRL",
                    items: batchOrders.map((item) => ({
                      item_id: item.id,
                      price: item.amount,
                      quantity: item.quantity
                    }))
                  });
                }
              }
            };

            iugu.createPaymentToken(this, tokenResponseHandler);
            return false;
          });
        };
        document.body.appendChild(script);
      }
    };

    loadIuguScript();

    return () => {
      const script = document.querySelector("script[src='https://js.iugu.com/v2']");
      if (script) script.remove();
    };
  }, [saveCard, batchOrders]);


  const handleCardUsedChange = (type) => setCardUsed(type);
  const handleSavedCardSelection = (e) => setSelectedSavedCard(e.target.value)


  const handlePayment = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setShowPaymentLoading(true);

    try {
      const response = await api.post(`/api/v1/payments/${payment.id}/pay_with_saved_card`, {
        savedCard: selectedSavedCard,
        numberOfInstallments: cardData.numberOfInstallments
      });

      if (response.data.message === "Pagamento aprovado") {
        console.log('Payment successful');
        const items = batchOrders.map(item => ({
          item_id: item.id,
          price: item.amount,
          quantity: item.quantity
        }));
        if (window && window.gtag) {
          window.gtag("event", "purchase", {
            transaction_id: payment.id,
            value: (payment.price_in_cents / 100).toFixed(2),
            currency: "BRL",
            items: items
          });
        }
      } else {
        console.error('Payment failed:', response.data.message);
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert('Ocorreu um erro ao processar o pagamento. Tente novamente mais tarde.');
    } finally {
      setShowPaymentLoading(false);
    }
  };


  return (
    <>
      <Box
        margin="15px 0"
        // background={theme.colors.white500}
        width={{base: '100%', md: '30%'}}
        height="min-content"
        padding="10px 30px 25px 30px"
        my="15px" borderRadius="10px"
        boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;"
      >
        <Box
          background='transparent'
          borderRadius="15px 15px 0px 0px"
        >
          <Text
            fontWeight={"500"}
            fontSize={"22px"}
            marginTop={2}
            marginBottom={5}
            color="black"
          >
            Cartão de crédito
          </Text>
        </Box>
        <Box
        >
          {savedCards.length > 0 && (
            <Box display="flex" justifyContent="space-between">
              <Checkbox
                isChecked={cardUsed === "new"}
                onChange={() => handleCardUsedChange("new")}
                marginTop="20px"
              >
                Usar novo cartão
              </Checkbox>
              <Checkbox
                isChecked={cardUsed === "saved"}
                onChange={() => handleCardUsedChange("saved")}
                marginTop="20px"
              >
                Usar cartão salvo
              </Checkbox>
            </Box>
          )}
          {cardUsed === "new" ?
            <form id="payment-form" action={`${process.env.REACT_APP_API_URL}payments/${payment.id}/pay_with_card`} method="post">
              <FormLabel
                marginTop={"20px"}
                htmlFor="fullName"
              >
                NOME NO CARTÃO
              </FormLabel>
              <Input
                id='fullName'
                borderColor={theme.colors.lightGray}
                name="fullName"
                value={cardData.fullName}
                onChange={handleInputChange}
                data-iugu="full_name"
                _hover={{
                  borderColor: theme.colors.lightGray
                }}
              />
              <FormLabel
                marginTop={"10px"}
                marginBottom={"0"}
                htmlFor="number"
              >
                NÚMERO DO CARTÃO
              </FormLabel>
              <CardNumberInputMask maskType="cardNumber" placeholder="1234 5678 9012 3456" />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                gap={"20px"}
              >
                <Box>
                  <FormLabel
                    marginTop={"10px"}
                    marginBottom={"0"}
                    htmlFor="varificationValue"
                  >
                    CSV
                  </FormLabel>
                  <CardVerificationInputMask maskType="number" placeholder="123"/>
                </Box>
                <Box>
                  <FormLabel
                    marginTop={"10px"}
                    marginBottom={"0"}
                    htmlFor="expiration"
                  >
                    VALIDADE
                  </FormLabel>
                  <Box
                    display={"flex"}
                    gap={"10px"}
                  >
                    <CardExpirationInputMask maskType="date" placeholder="MM/AA"/>
                  </Box>
                </Box>
              </Box>
              <FormLabel
                marginTop={"10px"}
                marginBottom={"0"}
                htmlFor="expiration"
              >
                PARCELAS
              </FormLabel>
              <Select
                id='numberOfInstallments'
                borderColor={theme.colors.lightGray}
                name="numberOfInstallments"
                value={cardData.numberOfInstallments}
                data-iugu='installments'
                onChange={handleInputChange}
                _hover={{
                  borderColor: theme.colors.lightGray
                }}
              >
                <option value=''></option>
                <option value='1'>1 parcela de {(payment.price_in_cents / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros</option>
                <option value='2'>2 parcelas de {((payment.price_in_cents / 100) / 2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros</option>
                <option value='3'>3 parcelas de {((payment.price_in_cents / 100) / 3).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros </option>
                <option value='4'>4 parcelas de {((payment.price_in_cents / 100) / 4).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros </option>
              </Select>
              <Box display={"none"}>
                <FormLabel
                  marginTop={"10px"}
                  marginBottom={"0"}
                  htmlFor="token"
                >
                  Token do Cartão de Crédito - Enviar para seu Servidor
                </FormLabel>
                <Input
                  type="text"
                  name="token"
                  id="token"
                  value=""
                  readOnly={true}
                />
              </Box>
              {/* <Checkbox
                isChecked={saveCard === "on"}
                onChange={handleCheckboxChange}
                marginTop="20px"
              >
                Salvar cartão para próximas compras
              </Checkbox> */}
              {!showPaymentLoading && (
                <Button
                  id="submit"
                  width="25%"
                  margin="20px 37.5%"
                  type="submit"
                  disabled={isLoading}
                  bg={theme.colors.secondaryGreen}
                  color="white"
                  borderRadius="20px"
                  border={`1px solid ${theme.colors.secondaryGreen}`}
                  _hover={{
                    background: "transparent",
                    color: `${theme.colors.secondaryGreen}`,
                    borderCorlor: `${theme.colors.secondaryGreen}`
                  }}
                >
                  Pagar
                </Button>
              )}
              {showPaymentLoading && (
                <Box margin="20px 37.5%" display='flex' justifyContent='center' alignItems='center'>
                  <Spinner id="spinner" color={theme.colors.primary} />
                </Box>
              )}
            </form>
          :
            <form
              action={`${process.env.REACT_APP_API_URL}payments/${payment.id}/pay_with_saved_card`}
              method="post"
            >
              <FormLabel marginTop="20px" htmlFor="savedCards">
                ESCOLHA O CARTÃO
              </FormLabel>
              <Box>
                {savedCards.map((card) => (
                  <Box key={card.id} my="10px">
                    <input
                      type="radio"
                      id={`card-${card.id}`}
                      name="savedCard"
                      value={card.id}
                      onChange={handleSavedCardSelection}
                    />
                    <label htmlFor={`card-${card.id}`}>
                      {`${card.data.display_number} - ${card.data.brand} (Expira em ${card.data.month}/${card.data.year})`}
                    </label>
                    <hr />
                  </Box>
                ))}
              </Box>

              <FormLabel marginTop="10px" marginBottom="0" htmlFor="expiration">
                PARCELAS
              </FormLabel>
              <Select
                id="numberOfInstallments"
                borderColor={theme.colors.primary}
                name="numberOfInstallments"
                value={cardData.numberOfInstallments}
                data-iugu="installments"
                onChange={handleInputChange}
                _hover={{ borderColor: theme.colors.primary }}
              >
                <option value=''></option>
                <option value='1'>1 parcela de {(payment.price_in_cents / 100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros</option>
                <option value='2'>2 parcelas de {((payment.price_in_cents / 100) / 2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros</option>
                <option value='3'>3 parcelas de {((payment.price_in_cents / 100) / 3).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros </option>
                <option value='4'>4 parcelas de {((payment.price_in_cents / 100) / 4).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} sem juros </option>
              </Select>
              {!showPaymentLoading && (
                <Button
                  id="button"
                  width="25%"
                  margin="20px 37.5%"
                  background={"transparent"}
                  type="submit"
                  disabled={isLoading}
                  onClick={handlePayment}
                  border={`1px solid ${theme.colors.primary}`}
                  _hover={{ background: theme.colors.primary }}
                >
                  Pagar
                </Button>
              )}
              {showPaymentLoading && (
                <Box margin="20px 37.5%" display='flex' justifyContent='center' alignItems='center'>
                  <Spinner id="spinner" color={theme.colors.primary} />
                </Box>
              )}
            </form>
          }


        </Box>
      </Box>

    </>
  );
}

import { Box, Text, Spinner, Grid, GridItem, Flex} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Footer } from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { ProductCard } from "../../components/ProductCard";
import { CityContext } from '../../Context/CityContext';
import { api } from "../../services/api";
import { theme } from "../../styles/theme";
import { Filters } from "../../components/Filters";
import { useLocation, Link } from 'react-router-dom';
import { CartContext } from "../../Context/CartContext";
import { AddToCartPopUp } from "../../components/AddToCartPopUp";

export function Products() {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [batches, setBatches] = useState([]);
  const [prices, setPrices] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productsPhotos, setProductsPhotos] = useState({});
  const { cityName } = useContext(CityContext);
  const [loadingPage, setLoadingPage] = useState(true);
  const urlParams = new URLSearchParams(location.search);
  const { showCartPopUp } = useContext(CartContext)

  useEffect(() => {
    const fetchProducts = async () => {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      const brand = urlParams.get("brand");
      const query = urlParams.get("query");
      const params = new URLSearchParams();
      if (cityName) params.append("city", cityName);
      if (filter) params.append("filter", filter);
      if (brand) params.append("brand", brand);
      if (query) params.append("query", query);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const apiUrl = `/api/v1/products?${params.toString()}`;
      try {
        const response = await api.get(apiUrl);
        setProducts(response.data.products);
        setBatches(response.data.batches);
        setPrices(response.data.prices);
        setProductsPhotos(response.data.product_photos_url);
        setProductTypes(response.data.product_types);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoadingPage(false);
      }
    };

    fetchProducts();
  }, [location.search]);

  const handleTypeToDisplay = () => {
    const filterParam = urlParams.get('filter');
    if (filterParam) {
      try {
        const filterArray = JSON.parse(filterParam);
        if (Array.isArray(filterArray)) {
          if (filterArray.includes("CERVEJA")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Cervejas</Text>
            );
          } else if (filterArray.includes("Vinho")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Vinhos e Espumantes</Text>
            );
          } else if (filterArray.includes("DESTILADOS")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Destilados</Text>
            );
          } else if (filterArray.includes("OUTRAS BEBIDAS")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Bebidas Prontas</Text>
            );
          } else if (filterArray.includes("Maiores Descontos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Maiores Descontos</Text>
            );
          } else if ( filterArray.includes("Mais Vendidos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Mais Vendidos</Text>
            );
          } else if (filterArray.includes("Kits")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Kits</Text>
            );
          } else if (filterArray.includes("Acessórios")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Acessórios</Text>
            );
          } else if (filterArray.includes("Não alcoólicos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Não Alcoólicos</Text>
            );
          } else if (filterArray.includes("Collab")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Collab</Text>
            );
          } else if (filterArray.includes("Eventos, Buffets e Casamentos")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Eventos, Buffets e Casamentos</Text>
            );
          } else if (filterArray.includes("Microcervejaria")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Microcervejarias</Text>
            );
          } else if (filterArray.includes("Chopp")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Chopp</Text>
            );
          } else if (filterArray.includes("Bebidas")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}

              >Bebidas</Text>
            );
          }  else if (filterArray.includes("Novidades")) {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}
              >Novidades</Text>
            );
          } else {
            return (
              <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}
              >Destaques</Text>
            );
          }
        }
      } catch (error) {
        console.log('Not an array');
      }
    } else {
      return (
        <Text margin={{base: '0% 0% 5% 0%', md: '0% 0% 2% 0%'}} textAlign='start' fontSize={{base: '22px', md: '32px'}} fontWeight='bolder' color={theme.colors.logoOrange}
        >Produtos</Text>
      );
    }
  };

  return (
    <>
      <Navbar/>
      <Box
        padding={{ base: "0%", sm: "1%", md: "2%" }}
        marginTop={{ base: "10rem", sm: "8rem", md: "6rem" }}
        flex="1"
        minHeight="110vh"
        maxWidth="100vw"
        overflowX="hidden"
      >
        <Box
          width="100%"
          background="white"
          padding={{ base: "5px", sm: "8px", md: "10px" }}
          margin="0 0"
          maxWidth="100vw"
        >
          <Flex direction={{ base: "column", sm: "column", md: "row" }} gap={4}>
            {/* Filters Section */}
            <Box
              flex={{ base: "0 0 100%", md: "0 0 20%" }}
              background="white"
              padding="10px"
            >
              <Text
                color={theme.colors.logoOrange}
                display="block"
                marginBottom="2%"
                textAlign="start"
                fontSize={{ base: "22px", sm: "26px", md: "32px" }}
                fontWeight="bolder"
              >
                Filtros
              </Text>
              <Filters urlParams={urlParams} setProducts={setProducts} />
            </Box>

            {/* Products Section */}
            <Box flex={{ base: "0 0 100%", md: "0 0 80%" }} padding="10px">
              {products?.length > 0 && prices.length > 0 && handleTypeToDisplay()}
              <Grid
                templateColumns={{
                  base: "repeat(auto-fit, minmax(150px, 1fr))", // Small phones
                  sm: "repeat(auto-fit, minmax(200px, 1fr))", // Small tablets
                  md: "repeat(auto-fit, minmax(230px, 1fr))", // Tablets and larger
                  lg: "repeat(auto-fit, minmax(260px, 1fr))", // Desktops
                }}
                gap={4}
                width="100%"
              >
                {/* Loading Spinner */}
                {products?.length <= 0 && loadingPage && (
                  <GridItem
                    colSpan={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="25rem"
                  >
                    <Spinner color={theme.colors.logoOrange} />
                  </GridItem>
                )}
                {/* No Products Found */}
                {products?.length <= 0 && !loadingPage && (
                  <GridItem
                    colSpan={4}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="25rem"
                  >
                    <Text
                      textAlign="center"
                      fontSize="24px"
                      color={theme.colors.logoOrange}
                      fontWeight="bold"
                    >
                      Nenhum produto encontrado.
                    </Text>
                  </GridItem>
                )}
                {/* Product Cards */}
                {products?.length > 0 &&
                  prices?.length > 0 &&
                  products.map((product) => (
                    <GridItem key={product.id} width="100%">
                      <Box
                        bg="white"
                        borderRadius="10px"
                        boxShadow="rgba(0, 0, 0, 0.25) 0px 3px 9px"
                        height="100%"
                        maxWidth="260px"
                        transition="transform 0.2s"
                        _hover={{
                          transform: "scale(1.02)",
                        }}
                      >
                        <Link
                          to={`/produtos/${productTypes
                            ?.find((e) => e.id === product.product_type_id)
                            ?.name.replace(/(%20|\s+)/g, "+")}/${product.name.replace(
                            /(%20|\s+)/g,
                            "+"
                          )}`}
                        >
                          <ProductCard
                            key={product.id}
                            product={product}
                            id={product.id}
                            name={product.name}
                            prices={prices?.filter(
                              (element) =>
                                element.batch_id ===
                                batches?.find(
                                  (element) => element.product_id === product.id
                                )?.id
                            )}
                            brand={product.brand}
                            description={product.description}
                            marketPrice={product.market_price}
                            quantityPerBox={product.quantity_per_box}
                            size={product.size}
                            productTypeId={product.product_type_id}
                            productType={product.product_type}
                            suggestedPriceForSale={
                              product.suggested_price_for_sale
                            }
                            batch={batches?.find(
                              (element) => element.product_id === product.id
                            )}
                            productPhotoUrl={productsPhotos[product.id]}
                            productTypes={productTypes}
                          />
                        </Link>
                      </Box>
                    </GridItem>
                  ))}
              </Grid>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Footer />
      {showCartPopUp && <AddToCartPopUp />}
    </>
  )
}

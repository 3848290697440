import {
  Button,
  Text,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useContext, useState } from "react";
import { api } from "../../services/api";
import { CityContext } from '../../Context/CityContext';
import { useLocation } from 'react-router-dom';

export function HardDrinkFilters(props) {
  const location = useLocation();
  const [drinkTypes, setDrinkTypes] = useState([]);
  const [drinkBrands, setDrinkBrands] = useState([]);
  const { cityParam } = useContext(CityContext);
  const brandOptions = [
    "Johnnie Walker",
    "Vanfall",
    "Gordon´s",
    "Orloff",
    "Jameson",
    "Seagram’s",
    "Jimmy",
    "Malibu",
    "ABSOLUT",
    "Verace",
    "Havana",
    "Wyborowa ",
    "BALLANTINES",
    "YB do Brasil",
    "Smirnoff",
    "Bacco",
    "ORLOFF",
    "Sátira",
    "O'Gin",
    "FLOS",
    "Zuur",
    "Beefeater",
    "Jeceaba",
    "Bandarra",
    "Sunflower",
    "Golden Massala "
  ]
  const drinkTypeOptions = [
    {param: "VODKA GARRAFA", title: "Vodka"},
    {param: "RUM GARRAFA", title: "Rum"},
    {param: "GIN GARRAFA", title: "Gin"},
    {param: "GARRAFA WHISKY", title: "Whisky"},
    {param: "cachaça garrafa", title: "Cachaça"},
    {param: "Licor Garrafa", title: "Licor"},
  ]

  async function handleGetProductBrand(e, brand) {
    e.preventDefault();
    const updatedDrinkBrands = drinkBrands.includes(brand)
      ? drinkBrands.filter((b) => b !== brand)
      : [...drinkBrands, brand];
    setDrinkBrands(updatedDrinkBrands);
    filterProducts(updatedDrinkBrands, drinkTypes);
  }

  async function handleGetProductTypes(e, productType) {
    e.preventDefault();
    const updatedDrinkTypes = drinkTypes.includes(productType)
      ? drinkTypes.filter((type) => type !== productType)
      : [...drinkTypes, productType];

    setDrinkTypes(updatedDrinkTypes);
    filterProducts(drinkBrands, updatedDrinkTypes);
  }

  async function filterProducts(brands, types) {
    let url = `/api/v1/products?city_id=${cityParam}`;
    if (brands.length > 0) {
      url = `${url}&drink_brands=${brands.join(',')}`;
    }
    if (types.length > 0) {
      url = `${url}${brands.length > 0 ? '&' : '&'}drink_types=${types.join(',')}`;
    }
    if (types.length === 0 && brands.length === 0) {
      const urlParams = new URLSearchParams(location.search);
      const filter = urlParams.get("filter");
      url = `${url}&filter=${filter}`;
    }
    const response = await api.get(url);
    props.setProducts(response.data.products);
  }

  return (
    <>
    <Text fontWeight="bold" color={theme.colors.primaryGreen} mb="20px">Tipos:</Text>
    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
      gap={4}
    >
      {drinkTypeOptions.map((drinkType, index) => {
        return(
          <GridItem key={index}>
            <Button
              width={"100%"}
              onClick={(e) => handleGetProductTypes(e, drinkType.param)}
              border={`1px solid ${theme.colors.secondaryGreen}`}
              color={theme.colors.secondaryGreen}
              borderRadius="20px"
              size="sm"
              _hover={{
                transform: "scale(1.02)",
                color: "white",
                bg: `${theme.colors.secondaryGreen}`,
              }}
              style={{
                backgroundColor: drinkTypes.includes(drinkType.param) ? `${theme.colors.secondaryGreen}` : "transparent",
                color: drinkTypes.includes(drinkType.param) ? "white" : `${theme.colors.secondaryGreen}`,
              }}
            >
              {drinkType.title}
            </Button>
          </GridItem>
        )
      })}
    </Grid>
    <Text pt={4} fontWeight="bold" color={theme.colors.primaryGreen} mb="20px">Marcas:</Text>
    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
      gap={4}
    >
      {brandOptions.map((brandOption, index) => {
        return(
          <Button
            key={index}
            width={"100%"}
            onClick={(e) => handleGetProductBrand(e, brandOption)}
            border={`1px solid ${theme.colors.secondaryGreen}`}
            color={theme.colors.secondaryGreen}
            borderRadius="20px"
            size="sm"

            _hover={{
              transform: "scale(1.02)",
              color: "white",
              bg: `${theme.colors.secondaryGreen}`,
            }}
            style={{
              backgroundColor: drinkBrands.includes(brandOption) ? `${theme.colors.secondaryGreen}` : "transparent",
              color: drinkBrands.includes(brandOption) ? "white" : `${theme.colors.secondaryGreen}`,
            }}
          >
            {brandOption}
          </Button>
        )
      })}
    </Grid>
    </>
  );
}

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function OrdersChart({ orders }) {
  const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  const currentYear = new Date().getFullYear();
  const ordersByMonth = {};
  console.log(orders)
  labels.forEach(month => {
    ordersByMonth[month] = {
      paid: [],
      notPaid: [],
    };
  });

  orders.forEach(order => {
    const createdDate = new Date(order.created_at);
    const orderYear = createdDate.getFullYear();
    const monthName = createdDate.toLocaleString('pt-BR', { month: 'long' });
    const monthCapitalize = monthName.charAt(0).toUpperCase() + monthName.slice(1);
    const monthIndex = labels.findIndex(month => month === monthCapitalize);
    if (orderYear === currentYear && monthIndex !== -1) {
      const amount = parseFloat(order.amount) || 0; // Ensure numeric value
      if (order.paid) {
        ordersByMonth[labels[monthIndex]].paid.push(amount);
      } else {
        ordersByMonth[labels[monthIndex]].notPaid.push(amount);
      }
    }
  });

  const paidOrdersByMonth = labels.map(month => ordersByMonth[month].paid.reduce((sum, amount) => sum + amount, 0).toFixed(2));
  const notPaidOrdersByMonth = labels.map(month => ordersByMonth[month].notPaid.reduce((sum, amount) => sum + amount, 0));

  console.log(ordersByMonth);
  console.log(paidOrdersByMonth)
  console.log(notPaidOrdersByMonth)


  const optionsOrders = {
    responsive: true,
    maintainAspectRatio: true, // Ensures aspect ratio is maintained
    aspectRatio: 2, // Adjusts the width-to-height ratio (2 means width is twice the height)
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Pedidos Mensais',
      },
    },
  };

  const dataOrders = {
    labels,
    datasets: [
      {
        label: 'Pedidos Pagos R$',
        data: paidOrdersByMonth,
        borderColor: 'rgb(44,211,72)',
        backgroundColor: 'rgba(44,211,72, 0.2)', // Adjusted for better visibility
      },
      {
        label: 'Pedidos em Aberto R$',
        data: notPaidOrdersByMonth,
        borderColor: 'rgb(255,0,0)',
        backgroundColor: 'rgba(255,0,0, 0.2)', // Adjusted for better visibility
      },
    ],
  };

  return (
    <div
      style={{
        width: '100%',
        height: '30rem',
        borderRadius: '15px',
        padding: '20px',
        margin: 'auto',
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
      }}
    >
      <Line
        options={optionsOrders}
        data={dataOrders}
        style={{ margin: 'auto', width: "100%", height: "100%" }}
      />
    </div>
  );
}

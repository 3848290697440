import {
  Box,
  Text,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CategoryCard } from "../../../components/Admin/CategoryCard";
import { ProductCard } from "../../../components/Admin/ProductCard";
import { SubCategoryCard } from "../../../components/Admin/SubcategoryCard";
import { TypeCard } from "../../../components/Admin/TypeCard";
import { Sales } from "../../../components/Admin/Sales";
import { OrdersChart } from "../../../components/Admin/OrdersChart";
import { DeliveryChart } from "../../../components/Admin/DeliveryChart";
import { CouponCard } from "../../../components/Admin/CouponCard";
import { DeliveryStatusChart } from "../../../components/Admin/DeliveryStatusChart";
import { OrdersByType } from "../../../components/Admin/OrdersByType";
import { PaymentMethodChart } from "../../../components/Admin/PaymentMethodChart";
import { UsersChart } from "../../../components/Admin/UsersChart";
import { api } from "../../../services/api";
import { useAuth } from "../../../Hooks/useAuth";
import { theme } from "../../../styles/theme";
import { FaPlus, FaMinus } from "react-icons/fa";

export function Dashboard() {
  const { user, isAuthenticated } = useAuth();
  const [selectedCategoryOnMenu, setSelectedCategoryOnMenu] = useState("")
  const [batchOrders, setBatchOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [batches, setBatches] = useState([]);
  const [products, setProducts] = useState([]);
  const [typesSold, setTypesSold] = useState([]);
  const [payments, setPayments] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [allOpenBatches, setAllOpenBatches] = useState([]);
  const [prices, setPrices] = useState([]);
  const [ordersByMonth, setOrdersByMonth] = useState([]);

  const [showMenuOptions, setShowMenuOptions] = useState(window.innerWidth < 768 ? false : true );

  async function handleFetchAdminInfosForDashboard() {
    const apiUrl = `/api/v1/admin/panels/get_infos_for_dashboard`;
    try {
      const response = await api.get(apiUrl, {
        headers: {
          "access-token": user?.token,
          client: user?.client,
          uid: user?.uid,
          accept: "application/json",
          contentType: "multipart/form-data"
        },
      });
      console.log(response)
      setProductTypes(response.data.product_types);
      setUsers(response.data.users);
      setBatchOrders(response.data.batch_orders);
      setBatches(response.data.batches);
      setPrices(response.data.prices);
      setProducts(response.data.products);
      setPayments(response.data.payments);
      setCategories(response.data.categories);
      setSubcategories(response.data.subcategories);
      setTypesSold(response.data.product_types_sold);
      setOrdersByMonth(response.data.monthly_data)
    } catch (error) {
      console.error("Error fetching batch orders:", error);
    }
  }


  useEffect(() => {
    handleFetchAdminInfosForDashboard()
  }, [])

  function handleSelectedCategoryOnMenu() {
    if (selectedCategoryOnMenu === "Subcategorias") {
      return (
        <SubCategoryCard allSubcategories={subcategories}/>
      )
    } else if (selectedCategoryOnMenu === "Categorias") {
      return (
        <CategoryCard allCategories={categories} allSubcategories={subcategories}/>
      )
    } else if (selectedCategoryOnMenu === "Tipos") {
      return (
        <TypeCard allCategories={categories} allProductTypes={productTypes}/>
      )
    } else if (selectedCategoryOnMenu === "Produtos") {
      return (
        <ProductCard allProducts={products} allProductTypes={productTypes} allBatches={batches} allPrices={prices.reduce((acc, curr) => acc.concat(curr), [])}/>
      )
    }  else if (selectedCategoryOnMenu === "Relatório") {
      return (
        <Sales />
      )
    } else if (selectedCategoryOnMenu === "Cupons") {
      return (
        <CouponCard />
      )
    }
    else {
      return (
        <Text
          color={theme.colors.white500}
        >
          Selecione uma categoria no menu
        </Text>
      )
    }
  }

  function handleHomePage() {
    window.location = "/"
  }

  function handleHomePageAdmin() {
    window.location.reload()
  }

  function handleToggleMenuOptions(e) {
    e.preventDefault()
    setShowMenuOptions(!showMenuOptions)
  }

  function formatDateDifference() {
    const createdDateObject = new Date(2023, 8, 15);
    const currentDateObject = new Date();
    const monthNames = [
      "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
      "Jul", "Ago", "Set", "Out", "Nov", "Dez"
    ];
    const monthCreated = monthNames[createdDateObject.getUTCMonth()];
    const yearCreated = createdDateObject.getUTCFullYear().toString().substring(2);
    const monthCurrent = monthNames[currentDateObject.getUTCMonth()];
    const yearCurrent = currentDateObject.getUTCFullYear().toString().substring(2);
    const formattedCreatedDate = `${monthCreated} ${yearCreated}`;
    const formattedCurrentDate = `${monthCurrent} ${yearCurrent}`;
    return (
      <Text>{formattedCreatedDate} - {formattedCurrentDate}</Text>
    )
  }

  useEffect(() => {
    if (user && user.access !== "admin") {
      window.location.href = '/'
    }
  }, [user])

  useEffect(() => {
    if (!isAuthenticated()) {
      window.location.href = '/sign_in'
    }
  }, [])

  useEffect(() => {
    if (selectedCategoryOnMenu !== "" && window.innerWidth < 768) {
      setShowMenuOptions(false)
    }
  }, [selectedCategoryOnMenu])

  return (
    <>
    {user && user.access === "admin" && (
      <Box
        minHeight="100vh"
      >
        <Box
          display={{base: "block", md: "block"}}
          gap={8}
        >
          {window.innerWidth < 768 && (
            <Button
              bg='transparent'
              width='100%'
              color={theme.colors.primary}
              fontSize='22px'
              onClick={(e) => handleToggleMenuOptions(e)}
              _hover={{background: 'transparent'}}
              display='flex'
              justifyContent='space-between'
              borderBottom='1px solid red'
              borderRadius='0px'
              height='50px'
            >
              Menu
              {showMenuOptions ? (
                <FaMinus></FaMinus>
              ) : (
                <FaPlus></FaPlus>
              )}
            </Button>
          )}
          <Box
            width={{base: "100%", md: "95%"}}
            padding={{base: "0px", md: "0px 20px"}}
            margin={{base: "0px", md: ""}}
            display={{base: "grid", md: "flex"}}
            justifyContent={{base: "strech", md: "space-between"}}
          >
            {showMenuOptions && (<>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} textAlign='left' background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={handleHomePage}>
                Início
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={handleHomePageAdmin}>
                Gráficos
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={(e) => setSelectedCategoryOnMenu(e.target.innerText)}>
                Subcategorias
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={(e) => setSelectedCategoryOnMenu(e.target.innerText)}>
                Categorias
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={(e) => setSelectedCategoryOnMenu(e.target.innerText)}>
                Tipos
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={(e) => setSelectedCategoryOnMenu(e.target.innerText)}>
                Produtos
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={(e) => setSelectedCategoryOnMenu(e.target.innerText)}>
                Cupons
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
              <Button fontSize='22px' padding={{base: "0px 15px", md: "center"}} justifyContent={{base: "start", md: "center"}} background='transparent' _hover={{ bg: 'transparent', color: `${theme.colors.logoOrange}` }} onClick={(e) => setSelectedCategoryOnMenu(e.target.innerText)}>
                Relatório
              </Button>
              <Divider orientation='horizontal' display={{base: "block", md: "none"}} borderColor={theme.colors.logoOrange}/>
            </>)}
          </Box>
          <Box
            width={{base: "100%", md: "100%"}}
            opacity='0.85'
            padding="20px 60px"
            margin={{base: "20px auto 0px auto", md: ""}}
            height='auto'
          >
            {selectedCategoryOnMenu ? (
              <Box borderRadius='15px' padding='10px 50px' boxShadow='rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;'>
                {handleSelectedCategoryOnMenu()}
              </Box>
            ) : (<>
              <Box display={{base: 'block', md: 'flex'}} justifyContent='start' gap='1rem'>
                <Text fontWeight='800' color={theme.colors.logoOrange} fontSize='26px'>DASHBOARD ADMIN</Text>
              </Box>
              <Text fontWeight='500' color={theme.colors.logoOrange} fontSize='20px'>Bem Vindo, {user?.full_name ? user.full_name : user.email}</Text>
              <Box display={{base: 'block' , md: 'flex'}} justifyContent='space-around' gap='1rem' margin='50px auto 50px auto'>
                <Box flex='1' marginBottom={{base: '20px', md: '0px'}} borderRadius='15px' padding='10px 50px' boxShadow='rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;'>
                  <Stat>
                    <StatLabel>Total pedidos pagos ({batchOrders.filter(e => e.paid).length })</StatLabel>
                    <StatNumber>{batchOrders.length > 0 && (batchOrders.filter(e => e.paid).reduce((n, { amount }) => n + amount, 0)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})  || `R$0,00`}</StatNumber>
                    <StatHelpText>{formatDateDifference()}</StatHelpText>
                  </Stat>
                </Box>
                <Box flex='1' marginBottom={{base: '20px', md: '0px'}} borderRadius='15px' padding='10px 50px' boxShadow='rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;'>
                  <Stat>
                    <StatLabel>Total usuáros</StatLabel>
                    <StatNumber>{users?.length > 0 && users.length}</StatNumber>
                    <StatHelpText>{formatDateDifference()}</StatHelpText>
                  </Stat>
                </Box>
                <Box flex='1' marginBottom={{base: '20px', md: '0px'}} borderRadius='15px' padding='10px 50px' boxShadow='rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;'>
                  <Stat>
                    <StatLabel>Total produtos</StatLabel>
                    <StatNumber>{products.length > 0 && products.length}</StatNumber>
                    <StatHelpText>{formatDateDifference()}</StatHelpText>
                  </Stat>
                </Box>
              </Box>
              <Box my="40px">
                <OrdersChart orders={batchOrders}></OrdersChart>
              </Box>
              <Box my="40px">
                <PaymentMethodChart payments={payments}></PaymentMethodChart>
              </Box>
              <Box my="40px">
                <DeliveryStatusChart orders={batchOrders}></DeliveryStatusChart>
              </Box>
              <Box my="40px">
                <UsersChart users={users}></UsersChart>
              </Box>
              <Box my="40px">
                <DeliveryChart orders={batchOrders}></DeliveryChart>
              </Box>
              <Box my="40px">
                <OrdersByType typesSold={typesSold}></OrdersByType>
              </Box>
            </>)}
          </Box>
        </Box>
      </Box>
    )}
    </>
  )
}

import React, { useState, useEffect } from 'react';

export function InputMask({ maskType, placeholder, setUserDocument, setUserPhoneNumber, value }) {
  const [maskedValue, setMaskedValue] = useState(value || '');
  const [isFocused, setIsFocused] = useState(false);


  useEffect(() => {
    setMaskedValue(value || '');
  }, [value]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    let updatedValue = '';

    switch (maskType) {
      case 'cpf':
        updatedValue = inputValue
          .replace(/\D/g, '')
          .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
          .substr(0, 14);
        if (setUserDocument) {
          setUserDocument(updatedValue);
        }
        break;
      case 'phone':
        updatedValue = inputValue
          .replace(/\D/g, '')
          .replace(/^(\d{2})(\d)/g, '($1) $2')
          .replace(/(\d{5})(\d)/, '$1-$2')
          .substr(0, 15);
        if (setUserPhoneNumber) {
          setUserPhoneNumber(updatedValue);
        }
        break;
      default:
        updatedValue = inputValue;
        break;
    }

    setMaskedValue(updatedValue);
  };

  return (
    <input
      type="text"
      value={maskedValue}
      onChange={handleChange}
      placeholder={placeholder}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{
        border: `1px solid ${isFocused ? '#D9D9D9' : '#D9D9D9'}`,
        borderRadius: '5px',
        padding: '5px',
        fontSize: '16px',
        background: 'transparent',
        width: '100%',
        height: '40px',
        margin: '0px 0px',
        outline: 'none',
        boxShadow: 'none',
      }}
      _hover={{borderColor: "#D9D9D9"}}
    />
  );
};

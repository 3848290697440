import {
  Button,
  Grid,
  Text,
  GridItem
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { api } from "../../services/api";
import { CityContext } from '../../Context/CityContext';
import { theme } from "../../styles/theme";
import { useLocation } from 'react-router-dom';

export function SubcategoryFilters(props) {
  const location = useLocation();
  const [subcategories, setSubcategories] = useState([]);
  const { cityParam } = useContext(CityContext);
  const subcategoriesOptions = [
    {
      name: "Cervejas",
      param: "CERVEJA, Cerveja",
    },
    {
      name: "Microcervejarias",
      param: "Microcervejarias, microcervejarias, microcervejaria, Microcervejaria",
    },
    {
      name: "Vinhos",
      param: "Vinho",
    },
    {
      name: "Destilados",
      param: "DESTILADOS",
    },
    {
      name: "Outras",
      param: "OUTRAS BEBIDAS",
    },
    {
      name: "Destilados",
      param: "DESTILADOS, VODKA, Gin",
    },
    {
      name: "Bebidas Mistas",
      param: "BEBIDAS MISTAS",
    },
  ];



  async function handleSubcategories(e, subcategory) {
    e.preventDefault();
    const updatedSubcategories = subcategories.includes(subcategory)
      ? subcategories.filter((type) => type !== subcategory)
      : [...subcategories, subcategory];

    setSubcategories(updatedSubcategories);
    const flattenedSubcategories = updatedSubcategories.flat();
    filterProducts(flattenedSubcategories);
  }

  async function filterProducts(subcategories) {
    let url = `/api/v1/products?city_id=${cityParam}`;
    const urlParams = new URLSearchParams(location.search);

    if (subcategories.length > 0) {
      url = `${url}&subcategories=${subcategories.join(',')}`;
    }

    const filter = urlParams.get("filter");
    if (filter) {
      url = `${url}&filter=${filter}`;
    }

    const response = await api.get(url);
    props.setProducts(response.data.products);
  }

  return (
    <>
    <Text fontWeight="bold" color={theme.colors.primaryGreen} mb="20px">Categorias:</Text>

    <Grid
      templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(2, 1fr)" }}
      gap={4}
    >
      {subcategoriesOptions.map((subcategory, index) => {
        return(
          <GridItem key={index}>
            <Button
              width={"100%"}
              onClick={(e) => handleSubcategories(e, subcategory.param)}
              border={`1px solid ${theme.colors.secondaryGreen}`}
              color={theme.colors.secondaryGreen}
              borderRadius="20px"
              size="sm"
              _hover={{
                transform: "scale(1.02)",
                color: "white",
                bg: `${theme.colors.secondaryGreen}`,
              }}
              style={{
                backgroundColor: subcategories.includes(subcategory.param) ? `${theme.colors.secondaryGreen}` : "transparent",
                color: subcategories.includes(subcategory.param) ? "white" : `${theme.colors.secondaryGreen}`,
              }}
            >
              {subcategory.name}
            </Button>
          </GridItem>
        )
      })}
    </Grid>
    </>
  );
}

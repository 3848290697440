import {
  Box,
  Text,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { useLocation } from 'react-router-dom';
import { BeerFilters } from "../BeerFilters";
import { WineFilters } from "../WineFilters";
import { KitFilters } from "../KitFilters";
import { HardDrinkFilters } from "../HardDrinkFilters";
import { OtherDrinksFilters } from "../OtherDrinksFilters";
import { SubcategoryFilters } from "../SubcategoryFilters";
import { ChoppFilters } from "../ChoppFilters";


export function Filters({urlParams, setProducts }) {
  const location = useLocation();
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const fetchProducts = async (params) => {
    let apiUrl = '/api/v1/products';
    const { minPrice, maxPrice, brands, types } = params;

    const queryParams = new URLSearchParams();

    if (minPrice) queryParams.append("minPrice", minPrice);
    if (maxPrice) queryParams.append("maxPrice", maxPrice);
    if (brands.length) queryParams.append("beer_brands", brands.join(','));
    if (types.length) queryParams.append("beer_types", types.join(','));

    // Adding any other filters based on URL params
    const filterParam = urlParams.get("filter");
    if (filterParam) queryParams.append("filter", filterParam);
    const brandParam = urlParams.get("brand");
    if (brandParam) queryParams.append("brand", filterParam);
    const query = urlParams.get("query");
    if (query) params.append("query", query);

    try {
      const response = await api.get(`${apiUrl}?${queryParams.toString()}`);
      setProducts(response.data.products);
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling animation
      });
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts({ minPrice, maxPrice, brands: [], types: [] });
  }, [minPrice, maxPrice]);



  const handleFilterToDisplay = () => {
    const filterParam = urlParams.get('filter');
    if (filterParam) {
      try {
        const filterArray = JSON.parse(filterParam);
        if (Array.isArray(filterArray)) {
          if  (filterArray.includes("CERVEJA") || filterArray.includes("Microcervejarias")) {
            return <BeerFilters setProducts={setProducts} />;
          } else if (filterArray.includes("Vinho")) {
            return <WineFilters setProducts={setProducts} />;
          } else if (filterArray.includes("DESTILADOS")) {
            return <HardDrinkFilters setProducts={setProducts} />;
          } else if (filterArray.includes("OUTRAS BEBIDAS")) {
            return <OtherDrinksFilters setProducts={setProducts} />;
          } else if (filterArray.includes("Maiores Descontos") || filterArray.includes("Mais Vendidos") || filterArray.includes("Bebidas") || filterArray.includes("Novidades")) {
            return <SubcategoryFilters setProducts={setProducts} />;
          } else if (filterArray.includes("Kits")) {
            return <KitFilters setProducts={setProducts} />;
          } else if (filterArray.includes("Chopp")) {
            return <ChoppFilters setProducts={setProducts} />;
          }
        }
      } catch (error) {
        console.log('Not an array');
      }
    } else {
      return <SubcategoryFilters setProducts={setProducts} />;
    }
  };

  return (
    <>
    <Box
      border="1px solid transparent"
      pb="30px"
      marginTop="7%"
      borderRadius="10px"
      height="auto"
      display="flex"
      flexDirection="column"
      boxShadow="rgba(0, 0, 0, 0.25) 0px 5px 15px;"
    >
      <Accordion allowToggle defaultIndex={window.screen.width < 762 ? [] : [0]} flex="1" overflowY="auto">
        <AccordionItem borderColor="transparent">
          <AccordionButton
            fontWeight="bold"
            display={{ base: "block", md: "none" }}
          >
            FILTRAR
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={2} overflowY="auto">
            <Text mt="20px" fontWeight="bold">Preço:</Text>
            <Box display="flex" mb="20px" gap="10px" alignContent="center" justifyContent="space-between">
              <Input
                placeholder="R$ MIN"
                borderColor={theme.colors.lightGray}
                onChange={handleMinPriceChange}
                marginBottom="10px"
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: theme.colors.lightGray,
                }}
              />
              <Input
                placeholder="R$ MAX"
                borderColor={theme.colors.lightGray}
                onChange={handleMaxPriceChange}
                marginBottom="10px"
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: theme.colors.lightGray,
                }}
              />
            </Box>
            {handleFilterToDisplay()}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>

    </>
  );
}

import { Routes, Route } from 'react-router-dom'
import { Dashboard } from './pages/Admin/Dashboard'
import { Home } from './pages/Home'
import { Store } from './pages/Store'
import { Products } from './pages/Products'
import { Product } from './pages/Product'
import { SignIn } from './pages/Sign_in'
import { SignUp } from './pages/Sign_up'
import { Password } from './pages/Password'
import { EditPassword } from './pages/EditPassword'
import { WhoWeAre } from './pages/WhoWeAre'
import { Cart } from './pages/Cart'
import { Profile } from './pages/User/Profile'
import { Payment } from './pages/Payment'
import { LinkBoozen } from './pages/LinkBoozen'

import { Error } from './pages/Error'
const selectedProductType = undefined;

export default function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/sign_in" element={<SignIn />} />
      <Route path="/sign_up" element={<SignUp />} />
      <Route path="/recuperar_senha" element={<Password />} />
      <Route path="/nova_senha" element={<EditPassword />} />
      <Route path="admin/dashboard" element={<Dashboard />} />
      {/* <Route path="/loja" element={<Store />} /> */}
      <Route path="/produtos" element={<Products selectedProductType={selectedProductType}/>} />
      {/* <Route path="/produtos/:id" element={<Product />} /> */}
      <Route path="/produtos/:product_type/:product_name" element={<Product />} />
      <Route path="/carrinho" element={<Cart />} />
      <Route path="/perfil/:id" element={<Profile />} />
      <Route path="pagamentos/:id" element={<Payment />} />
      <Route path="quem_somos" element={<WhoWeAre />} />
      <Route path="link_boozen" element={<LinkBoozen />} />

      <Route path="*" element={<h1>404 - Not Found</h1>} />
    </Routes>
  )
}
